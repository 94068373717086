import { WHITE_COLOR, TERTIARY_COLOR, FONT_FAMILY } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    buttonContainer: {
        position: "absolute",
        zIndex: "1500 !important", // Added px where applicable and !important
        marginTop: "10px !important",
        left: "43% !important"
    },
    button: {
        margin: "0px !important",
        textTransform: "none !important",
        fontSize: "12px !important",
        top: "10px !important",
        fontFamily: `${FONT_FAMILY} !important`,
        backgroundColor: `${TERTIARY_COLOR} !important`,
        '&:hover': {
            background: `${lighten(TERTIARY_COLOR, 0.2)} !important`
        },
        padding: "2px 10px !important",
    },
    progress: {
        width: "18px !important",
        height: "18px !important",
        float: "right !important",
        marginLeft: `${theme.spacing(1)} !important`,
        color: `${WHITE_COLOR} !important`
    }
}));
