import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router';
import styles from './AcceptStep.css';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import Grid from '@mui/material/Grid';

import withWidthHook from 'utils/useWidth';
import withStylesHook from 'utils/withStylesHook';
import ContactDetails from "../../common/summary-panels/ContactDetails";
import TransactionSummary from "../../common/summary-panels/TransactionSummary";
import DeliveryDetailsSummary from "../../common/summary-panels/DeliveryDetailsSummary";
import Typography from '@mui/material/Typography';
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";
import { Formik } from 'formik';
import hasFinancePermission from 'wumdrophubsreactshared/_utils/hasFinancePermission';
import { TRANSACTION_ITEM_TYPE_ID_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';

class AcceptStep extends Component {
    handleNext;

    constructor(props) {
        super(props);
        this.state = {
            deliveryId: this.props.quoteResponse.deliveryId
        };
    }

    handleSubmit(values) {
        this.props.clearErrors();
        this.props.handleStepSubmit(values);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.quoteResponse, nextProps.quoteResponse)) {
            this.setState({ deliveryId: nextProps.quoteResponse.deliveryId });
        }
    }

    render() {
        const {
            auth,
            classes,
            quoteResponse,
            wizardRef,
            portal
        } = this.props;
        const { deliveryId } = this.state;

        if (isEmpty(quoteResponse.deliveryQuotes))
            return (<div></div>);

        return (
            <Formik
                onSubmit={() => this.handleSubmit({ deliveryId: deliveryId })}
                initialValues={{}}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} id={`${wizardRef}5`} noValidate>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography className={classes.deliveryStepHeader}>
                                        Please confirm your delivery details
                                </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ContactDetails quoteResponse={quoteResponse} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={hasFinancePermission(auth) ? 6 : 12}>
                                    <DeliveryDetailsSummary
                                        quoteResponse={quoteResponse}
                                        portal={portal}
                                    />

                                </Grid>
                                {hasFinancePermission(auth) &&
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TransactionSummary
                                            vatRate={quoteResponse.vatRate}
                                            isRoundTrip={quoteResponse.isRoundTrip}
                                            transactionItems={quoteResponse.transactionItems}
                                            transactionItemTypeId={TRANSACTION_ITEM_TYPE_ID_ENUMS.DELIVERY}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    serverErrors: state.errors,
    portal: state.portal
})

const combinedStyles = combineStyles(styles, appStyles);

export default compose(
    withStylesHook(combinedStyles),
    withRouter,
    connect(mapStateToProps, { clearErrors }),
    withWidthHook
)(AcceptStep);