import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
      margin: "0 0 15px 0",
      padding: 0,
      display:"flex"
    },
    inputIcon: {
      height: "33px",
      position: "absolute",
      zIndex: "1",
      marginTop: 5
    }
  }));

  export default useStyles;