import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme, useMediaQuery, lighten } from '@mui/material';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles } from '@mui/styles'; 
import CircularProgress from '@mui/material/CircularProgress';
import { CALL_TO_ACTION_GREEN_COLOR, PRIMARY_COLOR, WHITE_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

function SimpleDialog({ open, onCloseClick, onSubmitClick, title, children, fullWidth, maxWidth, loading, disabled, cancelText, submitText, formId }) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let cancel = !isEmpty(cancelText) ? cancelText : "Cancel";
    let submit = !isEmpty(submitText) ? submitText : "Save";
    let hasForm = !isEmpty(formId);
    let styleFullWidthTrue = {width:"calc(100% - 64px)",maxWidth: maxWidth==='md' ? "960px" : maxWidth==='sm' ? "600px" : "calc(100% - 64px)"}
    let styleFullWidthFalse = { width: "350px",maxWidth: "calc(100% - 64px)"}
    return (
        <div>
            <Dialog
                sx={{ '& .MuiDialog-paper':  fullWidth ? styleFullWidthTrue : styleFullWidthFalse }}
                open={open}
                onClose={onCloseClick}
                fullScreen={fullScreen}
                fullWidth={!isEmpty(fullWidth) ? fullWidth : false}
                maxWidth={!isEmpty(maxWidth) ? maxWidth : false}
                aria-labelledby="simple-dialog-title"
                aria-describedby="simple-dialog-description"
            >
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <DialogContent className={classes.content}>
                    {children}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button fullWidth onClick={onCloseClick} color="primary" variant="contained">
                        {cancel}
                    </Button>

                    {
                        hasForm ?
                            <Button fullWidth disabled={loading || disabled} className={classes.btnSubmit} type="submit" form={formId} variant="contained">
                                {loading ? <CircularProgress size={25} /> : submit}
                            </Button>
                            :
                            <Button fullWidth disabled={loading || disabled} onClick={onSubmitClick} className={classes.btnSubmit} variant="contained">
                                {loading ? <CircularProgress size={25} /> : submit}
                            </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles({
    title: {
        color: `${WHITE_COLOR} !important`,
        background: `${PRIMARY_COLOR} !important`,
        textAlign: "center !important",
        padding: "20px !important"
    },
    content: {
        marginTop: "25px !important"
    },
    subtitle: {

    },
    actions: {
        justifyContent: "center !important",
        textAlign: "center !important",
        padding: "25px !important"
    },
    btnSubmit: {
        background: `${CALL_TO_ACTION_GREEN_COLOR} !important`,
        color: `${WHITE_COLOR} !important`,
        '&:hover': {
            background: `${lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2)} !important`
        }
    }
});

export default SimpleDialog;