/* eslint-disable no-unused-vars */
import {
    UPDATE_DRIVER_LOCATIONS
} from '../_actions/types';
const initialState = {
    data: new Map()
}

export default function (state = initialState, action) {

    switch (action.type) {
        case UPDATE_DRIVER_LOCATIONS:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}