import {
  PRIMARY_COLOR,
  FONT_FAMILY,
  COMPLEMENTARY_2_COLOR, SECONDARY_COLOR, TINY_FONT
} from 'wumdrophubsreactshared/_constants/styleConstants';

export default theme => ({
  splashScreen: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: COMPLEMENTARY_2_COLOR
    },
    logo: {
      display: "inline"
    },
    flag: {
      position:'relative',
      top: '-15px',
      textDecoration: 'none',
      fontSize: '0.7em',
      color: PRIMARY_COLOR,
      fontWeight: 100
    },
    loadingText: {
      color: PRIMARY_COLOR,
        fontSize: `${TINY_FONT}px !important`,
      marginTop: 15,
      marginBottom: 15,
      fontFamily: FONT_FAMILY + "!important",
        textAlign: "center !important",
    },
    loadingCircle: {
      marginTop: 18,
      marginBottom: '10px !important',
      marginLeft: 2,
      color: SECONDARY_COLOR+"!important"
    },
  loadingBox: {
      textAlign: "center",
      width: "50%"
  }
});