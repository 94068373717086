import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import isEmpty from "../../../wumdrophubsreactshared/_utils/isEmpty";
import styles from './DeliveryDetailsSummary.css';
import convertUtcToLocalDate from "../../../wumdrophubsreactshared/_utils/convertUtcToLocalDate";
import convertUtcToLocalTime from "../../../wumdrophubsreactshared/_utils/convertUtcToLocalTime";
import { DEFAULT_TIMEZONE, LOGISTIC_STATUS_ENUMS } from "../../../wumdrophubsreactshared/_constants/apiConstants";
import { AccessTime } from '@mui/icons-material';
import formatDisplayAddress from 'wumdrophubsreactshared/_utils/formatDisplayAddress';
import convertMetersToKm from 'wumdrophubsreactshared/_utils/convertMetersToKm';
import localiseCurrency from 'wumdrophubsreactshared/_utils/localiseCurrency';

const useStyles = makeStyles(styles)

function DeliveryDetailsSummary(props) {
    const { quoteResponse, portal, isForDetailsPanel, isTwoColumn } = props;
    const classes = useStyles();

    let quote = quoteResponse.deliveryQuotes.find(quote => quote.id === quoteResponse.selectedDeliveryQuoteId);

    let timeZone = portal.data.timeZone ? portal.data.timeZone : DEFAULT_TIMEZONE;

    let hubDisplayAddress = "";
    let originDisplayAddress = "";
    let destinationDisplayAddress = "";
    let deliveryDate = !isEmpty(quoteResponse.tasks[0]) ? convertUtcToLocalDate(quoteResponse.tasks[0].windowStartUtc, timeZone, false) : convertUtcToLocalDate(quoteResponse.readyForCollectionDateOnlyUtc, timeZone, false);
    let pickUpStartTime = !isEmpty(quote) ? convertUtcToLocalTime(quote.pickupWindowStartUtc, timeZone) : "n/a";
    let pickUpEndTime = !isEmpty(quote) ? convertUtcToLocalTime(quote.pickupWindowEndUtc, timeZone) : "n/a";
    let dropOffStartTime = !isEmpty(quote) ? convertUtcToLocalTime(quote.dropOffWindowStartUtc, timeZone) : "n/a";
    let dropOffEndTime = !isEmpty(quote) ? convertUtcToLocalTime(quote.dropOffWindowEndUtc, timeZone) : "n/a";
    let waybill = quoteResponse.waybill;
    let orderReference = quoteResponse.orderReference;
    let customerIdentifier = quoteResponse.customerIdentifier;
    let pickUpCoords = "";
    let dropOffCoords = "";

    if (isForDetailsPanel) {
        // we show address here in details panel
        hubDisplayAddress = formatDisplayAddress(quoteResponse.hubAddress);
        originDisplayAddress = formatDisplayAddress(quoteResponse.originAddress, quoteResponse.originBuildingName, quoteResponse.originBuildingUnit);
        destinationDisplayAddress = formatDisplayAddress(quoteResponse.destinationAddress, quoteResponse.destinationBuildingName, quoteResponse.destinationBuildingUnit);

        pickUpCoords = quoteResponse.originAddress.coordinates ? `${quoteResponse.originAddress.coordinates.lat}, ${quoteResponse.originAddress.coordinates.lon}` : "";
        dropOffCoords = quoteResponse.destinationAddress.coordinates ? `${quoteResponse.destinationAddress.coordinates.lat}, ${quoteResponse.destinationAddress.coordinates.lon}` : "";
    }

    const checkActiveAddresses = () => {
        return [
            quoteResponse.logisticStatusId < LOGISTIC_STATUS_ENUMS.COLLECTED,
            quoteResponse.logisticStatusId === LOGISTIC_STATUS_ENUMS.READY && !isEmpty(hubDisplayAddress),
            quoteResponse.logisticStatusId >= LOGISTIC_STATUS_ENUMS.COLLECTED
        ]
    }
    let activate = checkActiveAddresses();

    return (
        <div className={isForDetailsPanel === true ? classes.detailContainer : classes.deliveryContainer}>
            {isForDetailsPanel &&
                <Stepper orientation="vertical" className={classes.detailsSummaryStepper}>
                    <Step active={activate[0]}>
                        <StepLabel><Typography className={classes.displayDetailsAddressWrap}><span className={classes.deliverySubTitle}>Pick-up:</span> {originDisplayAddress}</Typography></StepLabel>
                    </Step>
                    {
                        !isEmpty(hubDisplayAddress) &&
                        <Step active={activate[1]}>
                            <StepLabel><Typography className={classes.displayDetailsAddressWrap}><span className={classes.deliverySubTitle}>hub:</span> {hubDisplayAddress}</Typography></StepLabel>
                        </Step>
                    }

                    <Step active={activate[2]}>
                        <StepLabel><Typography className={classes.displayDetailsAddressWrap}><span className={classes.deliverySubTitle}>Drop-off:</span> {destinationDisplayAddress}</Typography></StepLabel>
                    </Step>

                </Stepper>
            }

            <div className={isTwoColumn && classes.twoColumn1}>
                <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        ID #: <span className={classes.deliveryDay}>{quoteResponse.deliveryId}</span>
                    </Typography>
                </div>
                <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        Waybill: <span className={classes.deliveryDay}>{waybill}</span>
                    </Typography>
                </div>
                <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        Date: <span className={classes.deliveryDay}>{deliveryDate}</span>
                    </Typography>
                </div>
                <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        Pick-up <AccessTime className={classes.timeIcon} /> <span className={classes.deliveryDay}>{pickUpStartTime} - {pickUpEndTime}</span>
                    </Typography>
                </div>
                <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        Drop-off <AccessTime className={classes.timeIcon} /> <span className={classes.deliveryDay}>{dropOffStartTime} - {dropOffEndTime}</span>
                    </Typography>
                </div>
                {!isEmpty(orderReference) && <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        Order reference: <span className={classes.deliveryDay}>{orderReference}</span>
                    </Typography>
                </div>}
                {!isEmpty(customerIdentifier) && <div className={classes.deliveryInfo}>
                    <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                        Customer identifier: <span className={classes.deliveryDay}>{customerIdentifier}</span>
                    </Typography>
                </div>}
                {quote && quote.slaType &&
                    <div className={classes.deliveryInfo}>
                        <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                            SLA: <span className={classes.deliveryDay}>{quote.slaType}</span>
                        </Typography>
                    </div>
                }
            </div>
            {isForDetailsPanel &&
                <div className={isTwoColumn && classes.twoColumn2}>
                    {quoteResponse.placedByUser &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Placed by: <span className={classes.deliveryDay}>{quoteResponse.placedByUser.email}</span>
                            </Typography>
                        </div>
                    }
                    {quoteResponse.company &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Company: <span className={classes.deliveryDay}>{quoteResponse.company.companyName}</span>
                            </Typography>
                        </div>
                    }

                    {quoteResponse.originStore &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Origin store: <span className={classes.deliveryDay}>{quoteResponse.originStore.storeName}</span>
                            </Typography>
                        </div>
                    }

                    {quoteResponse.accountId &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Billed account #: <span className={classes.deliveryDay}>{quoteResponse.accountId}</span>
                            </Typography>
                        </div>
                    }

                    {quoteResponse.destinationStore &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Destination store: <span className={classes.deliveryDay}>{quoteResponse.destinationStore.storeName}</span>
                            </Typography>
                        </div>
                    }
                    {quote && quote.pricingTier &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Pricing tier: <span className={classes.deliveryDay}>{quote.pricingTier}</span>
                            </Typography>
                        </div>
                    }
                    <div className={classes.deliveryInfo}>
                        <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                            Status: <span className={classes.deliveryDay}>{`${quoteResponse.logisticStatus} ${quoteResponse.isReturnToSender ? "(Return to sender)" : ""}`}</span>
                        </Typography>
                    </div>
                    <div className={classes.deliveryInfo}>
                        <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                            Distance: <span className={classes.deliveryDay}>{convertMetersToKm(quoteResponse.distanceEstimate)}</span>
                        </Typography>
                    </div>
                    <div className={classes.deliveryInfo}>
                        <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                            Optimum vehicle: <span className={classes.deliveryDay}>{quoteResponse.optimumVehicleType}</span>
                        </Typography>
                    </div>

                    {pickUpCoords &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Pick-up coords: <span className={classes.deliveryDay}>{pickUpCoords}</span>
                            </Typography>
                        </div>
                    }

                    {dropOffCoords &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Drop-off coords: <span className={classes.deliveryDay}>{dropOffCoords}</span>
                            </Typography>
                        </div>
                    }

                    {!isEmpty(quoteResponse.invoiceTotal) &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Invoice total: <span className={classes.deliveryDay}>{`${localiseCurrency(quoteResponse.invoiceTotal)}`}</span>
                            </Typography>
                        </div>
                    }
                    {quoteResponse.invoiceType &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Invoice type: <span className={classes.deliveryDay}>{`${quoteResponse.invoiceType} ${quoteResponse.invoiceTypeId === 3 ? `(${quoteResponse.selectedInvoiceType})` : ""}`}</span>
                            </Typography>
                        </div>
                    }
                    {quoteResponse.pickupBarcode &&
                        <div className={classes.deliveryInfo}>
                            <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                                Pickup barcode: <span className={classes.deliveryDay}>{quoteResponse.pickupBarcode}</span>
                            </Typography>
                        </div>
                    }
                    <div className={classes.deliveryInfo}>
                        <Typography className={`${classes.textPadding} ${classes.textTotal}`}>
                            Lot number: <span className={classes.deliveryDay}>{quoteResponse.lotNumber > 0 ? quoteResponse.lotNumber : "N/A"}</span>
                        </Typography>
                    </div>
                </div>
            }
        </div>
    );
}

export default DeliveryDetailsSummary;