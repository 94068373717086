import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './ContactDetails.css';
import { Grid } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {
    CommentOutlined,
    PersonOutlineOutlined,
    PhoneOutlined,
    Business,
    Store
} from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import formatDisplayAddress from 'wumdrophubsreactshared/_utils/formatDisplayAddress';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';

const useStyles = makeStyles(styles)

const ContactDetails = (props) => {
    const { quoteResponse, isForDetailsPanel } = props;
    const classes = useStyles();

    let hubDisplayAddress = formatDisplayAddress(quoteResponse.hubAddress);
    let originDisplayAddress = formatDisplayAddress(quoteResponse.originAddress, quoteResponse.originBuildingName, quoteResponse.originBuildingUnit);
    let destinationDisplayAddress = formatDisplayAddress(quoteResponse.destinationAddress, quoteResponse.destinationBuildingName, quoteResponse.destinationBuildingUnit);

    return (
        <Stepper orientation="vertical">
            <Step active={true}>
                <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Pick-up {isForDetailsPanel && "contact"}: </span> {!isForDetailsPanel && originDisplayAddress}</Typography></StepLabel>
                <StepContent>
                    <div className={classes.gridRoot}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} md={3}>
                                <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                    elevation={0}>
                                    <PersonOutlineOutlined className={classes.inputIconsColor} />
                                    {quoteResponse.originContactName} <br />
                                    <PhoneOutlined className={classes.inputIconsColor} />
                                    {quoteResponse.originPhoneNumber}
                                </Paper>
                            </Grid>
                            {quoteResponse.originRemarks &&
                                <Grid item sm={12} md={9} className={isForDetailsPanel === true ? classes.maxGridWidth : classes.setGridWidthAsIs}>
                                    <Paper className={`${classes.paper} ${classes.deliveryRemarks}`}
                                        elevation={0}>
                                        <CommentOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.originRemarks}
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </StepContent>
            </Step>

            {
                !isEmpty(hubDisplayAddress) && !isEmpty(quoteResponse.hub) &&
                <Step active={true}>
                    <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Hub {isForDetailsPanel && "contact"}: </span> {!isForDetailsPanel && hubDisplayAddress}</Typography></StepLabel>
                    <StepContent>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                        elevation={0}>
                                        <PersonOutlineOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.hub.contactName} <br />
                                        <PhoneOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.hub.phoneNumber}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </StepContent>
                </Step>
            }

            <Step active={true}>
                <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Drop-off {isForDetailsPanel && "contact"}:</span> {!isForDetailsPanel && destinationDisplayAddress}</Typography></StepLabel>
                <StepContent>
                    <div className={classes.gridRoot}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} md={3}>
                                <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                    elevation={0}>
                                    <PersonOutlineOutlined className={classes.inputIconsColor} />
                                    {quoteResponse.destinationContactName} <br />
                                    <PhoneOutlined className={classes.inputIconsColor} />
                                    {quoteResponse.destinationPhoneNumber}
                                </Paper>
                            </Grid>
                            {quoteResponse.destinationRemarks &&
                                <Grid item sm={12} md={9} className={isForDetailsPanel === true ? classes.maxGridWidth : classes.setGridWidthAsIs}>
                                    <Paper className={`${classes.paper} ${classes.deliveryRemarks}`}
                                        elevation={0}>
                                        <CommentOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.destinationRemarks}
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </StepContent>
            </Step>
            {(isForDetailsPanel && quoteResponse.placedByUser) &&
                <Step active={true}>
                    <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Placed by:</span></Typography></StepLabel>
                    <StepContent>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                        elevation={0}>
                                        <PersonOutlineOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.placedByUser.firstName}  {quoteResponse.placedByUser.lastName} <br />
                                        <PhoneOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.placedByUser.phoneNumber} <br />
                                        <CommentOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.placedByUser.email}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </StepContent>
                </Step>
            }
            {(isForDetailsPanel && quoteResponse.originStore) &&
                <Step active={true}>
                    <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Origin store contact:</span></Typography></StepLabel>
                    <StepContent>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                        elevation={0}>
                                        <Store className={classes.inputIconsColor} />
                                        {quoteResponse.originStore.storeName} <br />
                                        <PersonOutlineOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.originStore.contactName} <br />
                                        <PhoneOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.originStore.phoneNumber}
                                    </Paper>
                                </Grid>
                                {quoteResponse.originStore.email &&
                                    <Grid item sm={12} md={9} className={isForDetailsPanel === true ? classes.maxGridWidth : classes.setGridWidthAsIs}>
                                        <Paper className={`${classes.paper} ${classes.deliveryRemarks}`}
                                            elevation={0}>
                                            <CommentOutlined className={classes.inputIconsColor} />
                                            {quoteResponse.originStore.email}
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </StepContent>
                </Step>
            }
            {(isForDetailsPanel && quoteResponse.destinationStore) &&
                <Step active={true}>
                    <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Destination store contact:</span></Typography></StepLabel>
                    <StepContent>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                        elevation={0}>
                                        <Store className={classes.inputIconsColor} />
                                        {quoteResponse.destinationStore.storeName} <br />
                                        <PersonOutlineOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.destinationStore.contactName} <br />
                                        <PhoneOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.destinationStore.phoneNumber}
                                    </Paper>
                                </Grid>
                                {quoteResponse.destinationStore.email &&
                                    <Grid item sm={12} md={9} className={isForDetailsPanel === true ? classes.maxGridWidth : classes.setGridWidthAsIs}>
                                        <Paper className={`${classes.paper} ${classes.deliveryRemarks}`}
                                            elevation={0}>
                                            <CommentOutlined className={classes.inputIconsColor} />
                                            {quoteResponse.destinationStore.email}
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </StepContent>
                </Step>
            }
            {(isForDetailsPanel && quoteResponse.company) &&
                <Step active={true}>
                    <StepLabel><Typography className={isForDetailsPanel === true ? classes.displayDetailsAddressWrap : classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Company contact:</span></Typography></StepLabel>
                    <StepContent>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                        elevation={0}>
                                        <Business className={classes.inputIconsColor} />
                                        {quoteResponse.company.companyName} <br />
                                        <PersonOutlineOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.company.contactName} <br />
                                        <PhoneOutlined className={classes.inputIconsColor} />
                                        {quoteResponse.company.phoneNumber}
                                    </Paper>
                                </Grid>
                                {quoteResponse.company.email &&
                                    <Grid item sm={12} md={9} className={isForDetailsPanel === true ? classes.maxGridWidth : classes.setGridWidthAsIs}>
                                        <Paper className={`${classes.paper} ${classes.deliveryRemarks}`}
                                            elevation={0}>
                                            <CommentOutlined className={classes.inputIconsColor} />
                                            {quoteResponse.company.email}
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </StepContent>
                </Step>
            }
            {(isForDetailsPanel && (!isEmpty(quoteResponse.orderReference) || !isEmpty(quoteResponse.customerIdentifier))) &&
                <Step active={true}>
                    <StepLabel><Typography className={classes.displayDetailsAddressWrap}>Additional details</Typography></StepLabel>
                    <StepContent>
                        <div className={classes.gridRoot}>
                            <Grid container spacing={0}>
                                {quoteResponse.orderReference &&
                                    <Grid item xs={12} sm={3} md={3}>
                                        <Paper className={isForDetailsPanel === true ? classes.timeDetailsHours : `${classes.paper} ${classes.timeHours}`}
                                            elevation={0}>
                                            Order reference: <span className={classes.subheading}>{quoteResponse.orderReference}</span>
                                        </Paper>
                                    </Grid>
                                }
                                {quoteResponse.customerIdentifier &&
                                    <Grid item sm={12} md={9} className={isForDetailsPanel === true ? classes.maxGridWidth : classes.setGridWidthAsIs}>
                                        <Paper className={`${classes.paper} ${classes.deliveryRemarks}`}
                                            elevation={0}>
                                            Customer identifier: <span className={classes.subheading}>{quoteResponse.customerIdentifier}</span>
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </StepContent>
                </Step>
            }
        </Stepper>
    );
}

export default ContactDetails;