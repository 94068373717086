import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'react-recompose';
import {
    Button,
    Grid, Typography
} from '@mui/material';
import withStylesHook from 'utils/withStylesHook';
import combineStyles from "../../../wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "../../../App.css";
import { Link } from 'react-router-dom';
import styles from './DeliveryStepperPaidSummary.css';
import TransactionSummary from "../../common/summary-panels/TransactionSummary";
import DeliveryDetailsSummary from "../../common/summary-panels/DeliveryDetailsSummary";

import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import { clearQuotes } from 'wumdrophubsreactshared/_actions/quotes';
import { setDeliveryWizardProps } from 'wumdrophubsreactshared/_actions/navigation';
import hasFinancePermission from 'wumdrophubsreactshared/_utils/hasFinancePermission';
import { TRANSACTION_ITEM_TYPE_ID_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';

class DeliveryStepperPaidSummary extends Component {

    handleWizardOpen = () => {
        //clear errors and quotes, we are creating a new order from scratch
        this.props.clearErrors();
        this.props.clearQuotes();
        this.props.setDeliveryWizardProps(true, false, 0);
    };

    handleWizardClose = () => {
        //clear errors and quotes, we are creating a new order from scratch
        this.props.clearErrors();
        this.props.clearQuotes();
        this.props.setDeliveryWizardProps(false, false, 0);
    };

    render() {
        const { classes, quoteResponse, portal, auth } = this.props;

        return (
            <div>
                <Grid container >
                    <Grid item xs={12}>
                        <div><img src={require("assets/img/thumbsup.svg")} width={"80"} className={classes.confirmationImg} alt="Payment success" /></div>
                        <Typography className={classes.thankyouHeader}>
                            Payment successful
                            </Typography>
                        <Typography className={classes.confirmationCopy}>
                            <strong>Woohoo!</strong> Your order has been placed successfully.
                            </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={hasFinancePermission(auth) ? 6 : 12}>
                        <DeliveryDetailsSummary
                            isMobile={false}
                            quoteResponse={quoteResponse}
                            portal={portal}
                        />
                    </Grid>
                    {
                        hasFinancePermission(auth) &&
                        <Grid item xs={12} sm={12} md={6}>
                            <TransactionSummary
                                isMobile={false}
                                isRoundTrip={quoteResponse.isRoundTrip}
                                vatRate={quoteResponse.vatRate}
                                tipAmount={quoteResponse.tipAmount}
                                transactionItems={quoteResponse.transactionItems}
                                transactionItemTypeId={TRANSACTION_ITEM_TYPE_ID_ENUMS.DELIVERY}
                            />
                        </Grid>
                    }

                    <Grid item xs={12} sm={12} md={12} className={classes.center}>
                        <Button
                            className={classes.actionButton}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/dashboard"
                            onClick={this.handleWizardClose}>
                            Go to dashboard
                            </Button>
                        <Button
                            className={classes.callToActionButton}
                            variant="contained"
                            color="primary"
                            onClick={this.handleWizardOpen}>
                            Place another order
                            </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    deliveries: state.deliveries,
    quotes: state.quotes,
    portal: state.portal
});

const combinedStyles = combineStyles(styles, appStyles);

export default compose(
    withStylesHook(combinedStyles),
    withRouter,
    connect(mapStateToProps, { clearErrors, clearQuotes, setDeliveryWizardProps })
)(DeliveryStepperPaidSummary);
