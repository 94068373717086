/* eslint-disable no-unused-vars */
import { 
    UPDATE_BULK_INDEXER_TERMINAL,
    CLEAR_BULK_INDEXER_TERMINAL
} from '../_actions/types';

const initialState = {
    data: {}
}

export default function(state = initialState, action ) {

    switch(action.type) {
        case UPDATE_BULK_INDEXER_TERMINAL:
            let values = Array.from(action.payload); 
            return {
                ...state,
                data: values
            }
        case CLEAR_BULK_INDEXER_TERMINAL:
            return {
                ...state,
                data: {}
            }
        default: 
            return state;
    }
}