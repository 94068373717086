import {
    DARK_GREEN_COLOR,
    SLATE_COLOR, SMALL_FONT, WHITE_COLOR
} from 'wumdrophubsreactshared/_constants/styleConstants';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        padding: "0px !important",
    },
    thankyouHeader: {
        fontSize: "30px !important",
        lineHeight: "36px !important",
        marginBottom: "24px !important",
        marginTop: "22px !important",
        color: SLATE_COLOR,
        textAlign: "center !important"
    },
    confirmationCopy: {
        textAlign: "center !important",
        marginBottom: "26px !important",
    },
    confirmationImg: {
        paddingTop: "45px !important",
        textAlign: "center !important",
        margin: "0 auto !important",
        display: "block !important"
    },
    componentContainer: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: "25px !important",
        }
    },
    reattemptButton: {
        textAlign: "center !important",
        backgroundColor: DARK_GREEN_COLOR,
        color: WHITE_COLOR,    
        padding: "8px 16px !important", 
        fontSize: `${SMALL_FONT}px !important`,
        marginTop: "12px !important", 
        marginBottom: "80px !important",
        textTransform: "none !important",
        '&:hover': {
            backgroundColor: DARK_GREEN_COLOR,
        }
    },
    centerAlign: {
        textAlign: "center !important"
    },
    boxContainer: {
        
    }
}));
