import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './ParcelModal.css';

import Icon from "@mui/material/Icon";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { Formik } from 'formik';
import * as yup from 'yup';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';

const useStyles = makeStyles(styles);

const handleParcelTypeClick = (parcelType, values, setValues) => {
    let newValues = { ...values };
    newValues.length = parcelType.length;
    newValues.width = parcelType.width;
    newValues.height = parcelType.height;
    newValues.weight = parcelType.weight;
    newValues.name = parcelType.name;
    newValues.deliveryItemName = parcelType.name;
    setValues(newValues);
}

function ParcelModal(props) {
    const { isCustom,
        customModalOpen,
        initialValues,
        customModalRef,
        parcelModalClose,
        parcelModalSubmit,
        parcelTypesData,
        disableDimensionFields } = props;
    const classes = useStyles();

    let disableDimensions = false;
    if (!isEmpty(disableDimensionFields) && disableDimensionFields) {
        disableDimensions = true;
    }

    return (
        <Modal open={customModalOpen} className={classes.customModal}>
            <Card className={classes.customCard}>
                <Typography>{isCustom ? "Add Custom Package" : "Edit Package"}</Typography>
                <Formik
                    onSubmit={parcelModalSubmit}
                    initialValues={initialValues}
                    validationSchema={
                        yup.object().shape({
                            deliveryItemName: yup.string().required(),
                            height: yup.number().required().min(0.01),
                            width: yup.number().required().min(0.01),
                            length: yup.number().required().min(0.01),
                            weight: yup.number().required().min(0.01)
                        })
                    }>
                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setValues }) => (
                        <div>
                            <div className={classes.sizesRoot}>
                                {
                                    !isEmpty(parcelTypesData) &&
                                    <ButtonGroup color="primary" aria-label="predefined sizes">
                                        {
                                            parcelTypesData.map((parcelType, index) => {
                                                if (parcelType.name && parcelType.length && parcelType.width && parcelType.height && parcelType.weight) {
                                                    return <Button key={index} onClick={() => handleParcelTypeClick(parcelType, values, setValues)}>{parcelType.name}</Button>
                                                } else {
                                                    return <div />
                                                }
                                            })
                                        }

                                    </ButtonGroup>
                                }
                            </div>
                            <form id={customModalRef}>
                                <TextField
                                variant="standard"
                                    name="deliveryItemName"
                                    label="Parcel Name"
                                    disabled={disableDimensions}
                                    fullWidth
                                    error={errors.deliveryItemName && touched.deliveryItemName}
                                    value={values.deliveryItemName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.deliveryItemName) ? true : false,
                                    }}
                                />
                                <TextField
                                variant="standard"
                                    name="length"
                                    label="Length"
                                    type="number"
                                    disabled={disableDimensions}
                                    fullWidth
                                    error={errors.length && touched.length}
                                    value={values.length}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                    }}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.length) ? true : false,
                                    }}
                                />

                                <TextField
                                variant="standard"
                                    name="width"
                                    label="Width"
                                    type="number"
                                    disabled={disableDimensions}
                                    fullWidth
                                    error={errors.width && touched.width}
                                    value={values.width}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                    }}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.width) ? true : false,
                                    }}
                                />

                                <TextField
                                variant="standard"
                                    name="height"
                                    label="Height"
                                    type="number"
                                    disabled={disableDimensions}
                                    fullWidth
                                    error={errors.height && touched.height}
                                    value={values.height}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                    }}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.height) ? true : false,
                                    }}
                                />



                                <TextField
                                variant="standard"
                                    name="weight"
                                    label="Weight"
                                    disabled={disableDimensions}
                                    type="number"
                                    fullWidth
                                    error={errors.weight && touched.weight}
                                    value={values.weight}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Kg</InputAdornment>
                                    }}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.weight) ? true : false,
                                    }}
                                />


                                <TextField
                                variant="standard"
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    error={errors.description && touched.description}
                                    value={values.description}
                                    onChange={handleChange}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.description) ? true : false,
                                    }}
                                />

                                <TextField
                                variant="standard"
                                    name="barCode"
                                    label="Barcode"
                                    fullWidth
                                    error={errors.barCode && touched.barCode}
                                    value={values.barCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"> <span className={"fas fa-barcode "}></span></InputAdornment>
                                    }}
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: !isEmpty(values.barCode) ? true : false,
                                    }}
                                />
                                <span className={classes.feedback}>{errors.barCodeExist}</span>
                                <div />
                                <FormControlLabel
                                    className={classes.checkbox}
                                    control={
                                        <Checkbox
                                            name="isFragile"
                                            checked={values.isFragile}
                                            onChange={handleChange}
                                            value={values.isFragile}
                                            color="primary"
                                        />
                                    }
                                    label="Is it fragile?"
                                    labelPlacement="start"
                                />

                                <div />

                                <IconButton color="secondary" className={classes.closeCustomDialog} onClick={parcelModalClose}>
                                    <Icon fontSize="large">close</Icon>
                                </IconButton>
                                <IconButton color="primary" className={classes.completeCustomDialog} style={{ float: "right" }} form={customModalRef} onClick={handleSubmit}>
                                    <Icon fontSize="large">check</Icon>
                                </IconButton>
                            </form>
                        </div>
                    )}
                </Formik>
            </Card>
        </Modal>
    );
}

export default ParcelModal;