import { getConfig } from '../_utils/config';
const config = getConfig();

//endpoints
export const API_BASE_URL = `${config.REACT_APP_HOST_PROTOCOL}://${config.REACT_APP_API_HOST}/${config.REACT_APP_API_PREFIX}`;
export const API_ACCOUNTS = `/accounts`;
export const API_USERS = `/users`;
export const API_USER_SESSIONS = `/userSessions`;
export const API_TERRITORIES = `/territories`;
export const API_COMPANIES = `/companies`;
export const API_COMPANY_COMMUNICATIONS = `/companycommunications`;
export const API_COUNTRIES = `/countries`;
export const API_CONTACT_TYPES = `/contacttypes`;
export const API_DELIVERIES = `/deliveries`;
export const API_DELIVERY_ITEMS = '/deliveryitems';
export const API_LOGISTIC_STATUSES = `/logisticstatuses`;
export const API_JOBS = `/jobs`;
export const API_JOB_WORKFLOWS = `/jobworkflows`;
export const API_JOB_STATUSES = `/jobstatuses`;
export const API_PAYMENT_CARDS = `/paymentCards`;
export const API_PAYMENT_STATUSES = `/paymentstatuses`;
export const API_OPERATION_STATUSES = `/operationstatuses`;
export const API_AUTO_ASSIGN_STATUSES = `/autoassignstatuses`;
export const API_TASKS = `/tasks`;
export const API_TASK_STATUSES = `/taskstatuses`;
export const API_TASK_TYPES = `/tasktypes`;
export const API_TRAINING_STATUSES = '/trainingstatuses';
export const API_VEHICLES = `/vehicles`;
export const API_ROLES = `/roles`;
export const API_APPLICATION_STATUSES = `/applicationStatuses`;
export const API_DOCUMENT_STATUSES = `/documentStatuses`;
export const API_ACCOUNT_TYPES = `/accountTypes`;
export const API_COMPANY_TYPES = `/companyTypes`;
export const API_COMPANY_PRICING_TIERS = `/companyPricingTiers`;
export const API_VEHICLE_TYPES = `/vehicleTypes`;
export const API_HUBS = `/hubs`;
export const API_HUBS_WORKFLOW = `/hubWorkflows`;
export const API_ADDRESSES = `/addresses`;
export const API_RATES = `/rates`;
export const API_REPORTS = `/reports`;
export const API_ARCHIVES = `/archives`;
export const API_RATE_TYPES = `/rateTypes`;
export const API_TIME_ZONES = `/TimeZones`;
export const API_SLA_TYPES = `/slaTypes`;
export const API_HANDLING_TYPE = `/handlingType`;
export const API_PARCEL_TYPES = `/parcelTypes`;
export const API_DAY_OF_WEEK_TYPES = `/dayOfWeekTypes`;
export const API_AREAS = `/areas`; //this is for join table on territories/x/areas or hubs/x/areas
export const API_EXCEPTIONS = `/exceptions`; //this is for join table on territories/x/exceptions or hubs/x/exceptions
export const API_STORES = `/stores`;
export const API_CONTACTS = `/contacts`;
export const API_FUNDS_RECEIVED = `/fundsreceived`;
export const API_FUNDS_RECEIVED_TYPES = `/fundsreceivedtypes`;
export const API_FUNDS_RECEIVED_STATUSES = `/fundsreceivedstatuses`;
export const API_TRANSACTIONS = `/transactions`;
export const API_TRANSACTIONS_REVERSE = `/transactions/reverse`;
export const API_TRANSACTION_TYPES = `/transactiontypes`;
export const API_TRANSACTION_STATUSES = `/transactionstatuses`;
export const API_BANKS = `/banks`;
export const API_BANK_ACCOUNTS = `/bankaccounts`;
export const API_BANK_ACCOUNT_TYPES = `/bankaccounttypes`;
export const API_NOTES = `/notes`;
export const API_UPLOAD_BATCHES = `/uploadbatches`;
export const API_UPLOAD_DISTANCE_BATCHES = `/uploaddistancebatches`;
export const API_AUTH_TYPES = `/authtypes`;
export const API_WEBHOOKS = `/webhooks`;
export const API_WEBHOOK_TYPES = `/webhooktypes`;
export const API_WEBHOOK_STATUSES = `/webhookstatuses`;
export const API_RATINGS = `/ratings`;
export const API_ANNOUNCEMENTS = `/announcements`;
export const API_NOTIFICATION_DEVICES = `/notificationDevices`;
export const API_MOBILE_DEVICES = `/mobileDevices`;
export const API_QUESTIONNAIRES = `/questionnaires`;
export const API_QSR_DRIVER_TYPES = '/qsrDriverTypes';
export const API_DRIVER_ROUTES = '/DriverRoutes';
export const API_DRIVER_SPECIAL_FLAG_TYPES = `/SpecialDriverFlag`;


export const API_SCHEDULED_REPORT = `/scheduledreports`;
export const API_SCHEDULED_REPORT_TYPES = `/scheduledreporttypes`;
export const API_SCHEDULED_REPORT_PERIODS = `/scheduledreportperiods`;

export const API_DASHBOARD_DELIVERY_STATUS_GROUPS = `/dashboarddeliverystatusgroups`;
export const API_DASHBOARD_DELIVERY_SORTS = `/dashboarddeliverysorts`;
export const API_DASHBOARD_JOB_SORTS = `/dashboardjobsorts`;
export const API_DASHBOARD_DRIVER_SORTS = `/dashboarddriversorts`;
export const API_DASHBOARD_DRIVER_STATUS_GROUPS = `/dashboarddriverstatusgroups`;

//swagger endpoint
export const REACT_APP_SWAGGER_API_URL = `${config.REACT_APP_HOST_PROTOCOL}://${config.REACT_APP_PUBLIC_HOST}/swagger/index.html`

//superset endpoints
export const API_SUPERSET_BASE_URL = `${config.REACT_APP_HOST_PROTOCOL}://${config.REACT_APP_SUPERSET_HOST}`
export const API_SUPERSET_GUEST_TOKEN = "/api/WumdropSuperset/getSupersetToken";
export const API_SUPERSET_DASHBOARD_LIST = "/api/WumdropSuperset/getDashboard";
export const API_SUPERSET_EMBEDDED = "/api/WumdropSuperset/getEmbedded"
export const API_SUPERSET_PROXY = `${config.RREACT_APP_SUPERTSET_PROXY}`

//asssistant endpoints
export const API_ASSISTANT_GET_AVAILABLE_ASSISTANTS_FOR_COMPANY = `/getavailableassistantsforcompany`;
export const API_STATISTICS = `/Statistics`;

//elastic endpoints
export const API_ELASTIC_DELIVERIES = `/elasticdeliveries`;
export const API_ELASTIC_JOBS = `/elasticjobs`;
export const API_ELASTIC_USERS = `/elasticusers`;
export const API_ELASTIC_CONTACTS = `/elasticcontacts`;
export const API_ELASTIC_TASKS = '/elastictasks';
export const API_CONFIG = '/config';


//partner / driver / assistant / vehicle application workflow endpoints
export const API_CREATE_PARTNER_COMPANY_APPLICATION = '/applicationworkflows/createpartnercompanyapplication';
export const API_UPDATE_PARTNER_COMPANY_APPLICATION = '/applicationworkflows/updatepartnercompanyapplication';
export const API_ADD_PARTNER_DRIVER_APPLICATION = '/applicationworkflows/addpartnerdriverapplication';
export const API_UPDATE_PARTNER_DRIVER_APPLICATION = '/applicationworkflows/updatepartnerdriverapplication';
export const API_UPDATE_PARTNER_DRIVER_APPLICATION_EXPIRED_FIELDS = '/applicationworkflows/updatepartnerdriverapplicationexpiredfields';
export const API_SKIP_PARTNER_DRIVER_APPLICATION = '/applicationworkflows/skippartnerdriverapplication';
export const API_ADD_DRIVER_APPLICATION_VIA_PARTNER = '/applicationworkflows/adddriverapplicationviapartner';
export const API_UPDATE_DRIVER_APPLICATION_VIA_PARTNER = '/applicationworkflows/updatedriverapplicationviapartner';
export const API_UPDATE_DRIVER_APPLICATION_EXPIRED_FIELDS_VIA_PARTNER = '/applicationworkflows/updatedriverapplicationexpiredfieldsviapartner';
export const API_REMOVE_DRIVER_APPLICATION_VIA_PARTNER = '/applicationworkflows/removedriverapplicationviapartner';
export const API_REMOVE_ASSISTANT_APPLICATION_VIA_PARTNER = '/applicationworkflows/removeassistantapplicationviapartner';
export const API_CONFIRM_PARTNER_DRIVER_APPLICATIONS = '/applicationworkflows/confirmpartnerdriverapplications';
export const API_ADD_PARTNER_VEHICLE_APPLICATION = '/applicationworkflows/addpartnervehicleapplication';
export const API_UPDATE_PARTNER_VEHICLE_APPLICATION = '/applicationworkflows/updatepartnervehicleapplication';
export const API_UPDATE_PARTNER_VEHICLE_APPLICATION_EXPIRED_FIELDS = '/applicationworkflows/updatepartnervehicleapplicationexpiredfields';
export const API_REMOVE_PARTNER_VEHICLE_APPLICATION = '/applicationworkflows/removepartnervehicleapplication';
export const API_TRACK_PARTNER_DRIVER_APPLICATION = '/applicationworkflows/trackpartnerdriverapplication';
export const API_GET_PARTNER_APPLICATION = '/applicationworkflows/getpartnerapplication';
export const API_GET_PARTNER_COMPANY_APPLICATION = '/applicationworkflows/getpartnercompanyapplication';
export const API_GET_DRIVER_APPLICATION = '/applicationworkflows/getdriverapplication';
export const API_GET_ASSISTANT_APPLICATION = '/applicationworkflows/getassistantapplication';
export const API_UPDATE_DRIVER_APPLICATION_BY_ID = '/applicationworkflows/updatedriverapplication';
export const API_UPDATE_ASSISTANT_APPLICATION_BY_ID = '/applicationworkflows/updateassistantapplication';
export const API_GET_VEHICLE_APPLICATION = '/applicationworkflows/getvehicleapplication';
export const API_UPDATE_VEHICLE_APPLICATION = '/applicationworkflows/updatevehicleapplication';
export const API_ACCEPT_PARTNER_TERMS = '/applicationworkflows/acceptpartnercompanyterms';

//business application workflow endpoints
export const API_CREATE_BUSINESS_COMPANY_APPLICATION = '/applicationworkflows/createbusinesscompanyapplication';
export const API_UPDATE_BUSINESS_COMPANY_APPLICATION = '/applicationworkflows/updatebusinesscompanyapplication';
export const API_GET_BUSINESS_COMPANY_APPLICATION = '/applicationworkflows/getbusinesscompanyapplication';
export const API_GET_BUSINESS_APPLICATION = '/applicationworkflows/getbusinessapplication';
export const API_ACCEPT_BUSINESS_RATES = '/applicationworkflows/acceptbusinessrates';
export const API_ACCEPT_BUSINESS_TERMS = '/applicationworkflows/acceptbusinessterms';
export const API_TRACK_BUSINESS_APPLICATION = '/applicationworkflows/trackbusinessapplication';

// application vetting endpoints
export const API_APPROVE_APPLICATION = '/applicationworkflows/approveapplication';
export const API_REJECT_APPLICATION = '/applicationworkflows/rejectapplication';
export const API_CANCEL_APPLICATION = '/applicationworkflows/cancelapplication';
export const API_APPROVE_APPLICATION_DOCUMENTS = '/applicationworkflows/approveapplicationdocuments';
export const API_REJECT_APPLICATION_DOCUMENTS = '/applicationworkflows/rejectapplicationdocuments';

//application invite endpoints
export const API_APPLICATION_INVITES = '/applicationinvites'
export const API_CREATE_DRIVER_APPLICATION = '/applicationinvites/createdriverapplication';
export const API_UPDATE_DRIVER_APPLICATION = '/applicationinvites/updatedriverapplication';
export const API_CANCEL_DRIVER_APPLICATION = '/applicationinvites/canceldriverapplication';

//delivery and quote workflow endpoints
export const API_CREATE_QUOTES = `/deliveryworkflows/createquotes`;
export const API_UPDATE_QUOTES = `/deliveryworkflows/updatequotes`;
export const API_ADD_PARCEL = `/deliveryworkflows/addparcel`;
export const API_REMOVE_PARCEL = `/deliveryworkflows/removeparcel`;
export const API_UPDATE_PARCEL = `/deliveryworkflows/updateparcel`;
export const API_UPDATE_PARCELS = `/deliveryworkflows/updateparcels`;
export const API_SELECT_QUOTE = `/deliveryworkflows/selectquote`;
export const API_SET_TIMESLOT_QUOTE = `/deliveryworkflows/settimeslot`;
export const API_UPDATE_ORIGIN_QUOTE_DETAILS = `/deliveryworkflows/updateorigindetails`;
export const API_UPDATE_DESTINATIONS_QUOTE_DETAILS = `/deliveryworkflows/updatedestinationdetails`;
export const API_UPDATE_ADDITIONAL_QUOTE_DETAILS = `/deliveryworkflows/updateadditionaldetails`;
export const API_ACCEPT_QUOTE = `/deliveryworkflows/acceptquote`;
export const API_SELECT_ACCOUNT = `/deliveryworkflows/selectaccount`;

export const API_PAY_BY_BALANCE = `/deliveryworkflows/payByBalance`;
export const API_PAY_BY_SAVED_CARD = `/deliveryworkflows/payBySavedCard`;
export const API_PAYMENT_CHECKOUT = `/deliveryworkflows/paymentCheckout`;
export const API_PROCESS_PAYMENT = `/deliveryworkflows/processCardPayment`;
export const API_PAYMENT_FAILED = `/deliveryworkflows/paymentfailed`;

export const API_CANCEL_QUOTE = `/deliveryworkflows/cancelquote`;
export const API_CANCEL_DELIVERY = `/deliveryworkflows/canceldelivery`;
export const API_CANCEL_AND_RETURN_TO_SENDER = `/deliveryworkflows/cancelandreturntosender`;
export const API_REATTEMPT_DELIVERY = `/deliveryworkflows/reattempt`;
export const API_CLONE_DELIVERY = `/deliveryworkflows/clone`;
export const API_GET_QUOTES = `/deliveryworkflows/getquotes`;
export const API_UPDATE_DELIVERY_CONTACT_INFORMATION = `/deliveryworkflows/updatecontactinformation`;
export const API_RECALCULATE_PARCELS = `/deliveryworkflows/recalculateparcels`;
export const API_RECALCULATE_LOGISTICS = `/deliveryworkflows/recalculatelogistics`;
export const API_UPDATE_PARCEL_DETAILS = `/deliveryworkflows/updateparceldetails`;
export const API_REVERSE_LOGISTIC_STATUS = `/deliveryworkflows/reverseLogisticStatus`;
export const API_UPDATE_DELIVERY_PROOF = `/deliveryworkflows/updatedeliveryproof`;

//job workflow endpoints
export const API_CREATE_JOB_FROM_DELIVERY_IDS = `/jobworkflows/createjob`;
export const API_GET_JOB_COST_FROM_DELIVERY_IDS = `/jobworkflows/jobcost`;
export const API_ASSIGN_JOBS_TO_DRIVER = `/jobworkflows/assignjobstodriver`;
export const API_UNASSIGN_DRIVER_FROM_JOB = `/jobworkflows/unassigndriverfromjob`;
export const API_REMOVE_DELIVERY_FROM_JOB = `/jobworkflows/removedeliveryfromjob`;
export const API_ADD_DELIVERIES_TO_JOB = `/jobworkflows/adddeliveriestojob`;
export const API_START_JOB = `/jobworkflows/start`;
export const API_REJECT_JOB = `/jobworkflows/reject`;
export const API_REVERSE_JOB_STATUS = `/jobworkflows/reverseJobStatus`;
export const API_SCAN_JOB_BARCODE = '/jobworkflows/scanbarcode';
export const API_NOTIFY_JOB_DELAY = '/jobworkflows/notifyJobDelay';
export const API_EXPORT_JOB_ROUTES = '/jobworkflows/exportjobroutes';
export const API_GET_JOB_ROUTES = '/jobworkflows/jobRoute';
export const API_GET_JOB_SUMMARY = '/jobworkflows/jobSummary';
export const API_PARCEL_BARCODES = '/parcelBarcodes';

//task workflow endpoints
export const API_START_TASK = `/taskworkflows/start`;
export const API_STOP_TASK = `/taskworkflows/stop`;
export const API_ARRIVE_TASK = `/taskworkflows/arrived`;
export const API_COMPLETE_PICK_UP_TASK = `/taskworkflows/completepickup`;
export const API_COMPLETE_DROP_OFF_TASK = `/taskworkflows/completedropoff`;
export const API_EXCEPTION_DROP_OFF_TASK = `/taskworkflows/exceptiondropoff`;
export const API_EXCEPTION_PICK_UP_TASK = `/taskworkflows/exceptionpickup`;
export const API_GET_TASK_STATE = `/taskworkflows/gettaskstate`;

//notes
export const API_NOTES_MESSAGE_TYPES = `/notemessagetypes`;
export const API_USER_ACTION_NOTES = `/users/getnotesforuser`

//user permissions endpoints
export const API_USER_PERMISSIONS = `/users/getuserpermissions`;

//delivery windows
export const API_DELIVERY_WINDOWS = `/deliverywindows`;

//tracking endpoints
export const API_TRACKING = `/tracking`;
export const API_TRACKING_SEARCH_BY_WAYBILL = `/tracking/searchbywaybill`;
export const API_TRACKING_SEARCH_BY_ORDER_REF = `/tracking/searchbyorderreference`;
export const API_FRONTENDERRORS = `/errors/frontend`;
export const API_MOBILE_ERRORS = '/errors/mobile';
export const API_ERRORS = '/errors';

//driver schedule endpoints
export const API_DRIVER_SCHEDULE = `/driverschedules`;

//email endpoints
export const API_SEND_VACANCY_EMAIL = `/email/sendVacancyEmail`;

//communication types
export const API_COMMUNICATION_TYPES = `/communicationTypes`;

//rate endpoints
export const GET_ACTIVE_RATES_FOR_COMPANY = `/getactiveratesforcompany`;


export const API_BYTES_RESPONSE = { responseType: 'arraybuffer' };

//icon generator microservice URL
export const ICON_GENERATOR_BASE_URL = `${config.REACT_APP_HOST_PROTOCOL}://${config.REACT_APP_CONTENT_HOST}`;
export const ICON_GENERATORE_DRIVER_ICONS = '/drivericons';
export const ICON_GENERATORE_DELIVERY_ICONS = '/deliveryicons';
export const FILE_CONTENT = '/content';
export const ICON_VERSION = `1`;

//system.types
export const SYSTEM_INT = "System.Int32";
export const SYSTEM_INT_ARRAY = "System.Int32[]";
export const SYSTEM_STRING = "System.String";
export const SYSTEM_STRING_ARRAY = "System.String[]";
export const SYSTEM_BOOL = "System.Boolean";
export const SYSTEM_DOUBLE = "System.Double";
export const SYSTEM_FLOAT = "System.Single";
export const SYSTEM_DECIMAL = "System.Decimal";
export const SYSTEM_DATETIME = "System.DateTime";
export const SYSTEM_COORDINATE = "WumDropHubs.Domain.DTOs.Coordinate[]";
export const SYSTEM_OBJECT_ARRAY = "System.Object[]";

//page size defaults
export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_OPTIONS = [20, 50, 100, 200, 500];
export const PAGE_SIZE_OPTIONS_ADVANCED = [20, 50, 100, 200, 500];

//wait intervals
export const SEARCH_WAIT_INTERVAL = 600;

//api expected date format
export const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

//format we use for displaying local dates
export const LOCAL_DATE_FORMAT = "YYYY-MM-DD";
export const LOCAL_TIME_FORMAT = "HH:mm";
export const LOCAL_DATE_TIME = "DD-MM-YYYY HH:mm";
export const TIMELINE_TIME_FORMAT = "h:mm A";
export const TIMELINE_DATE_FORMAT = "ll";

//package sizes
export const SMALL_PACKAGE = {
    length: 40,
    width: 30,
    height: 30,
    weight: 10,
    isFragile: false,
    deliveryItemName: "small package",
}

export const MEDIUM_PACKAGE = {
    length: 70,
    width: 60,
    height: 40,
    weight: 30,
    isFragile: false,
    deliveryItemName: "medium package",
}

export const LARGE_PACKAGE = {
    length: 120,
    width: 90,
    height: 50,
    weight: 90,
    isFragile: false,
    deliveryItemName: "large package",
}

//delivery status groups
export const PAYMENT_STATUS_ENUMS = {
    REFUND_LESS_FEE: -11001,
    REFUND_FULL: -10001,
    QUOTE_CANCELLED: -2000,
    QUOTE_REJECTED_BY_CLIENT: -1000,
    QUOTE_EXPIRED: -1,
    ITEMS_OUTSTANDING: 0,
    QUOTE_NOT_SELECTED: 10001,
    DETAILS_OUTSTANDING: 10010,
    AWAITING_CLIENT_ACCEPTANCE: 10020,
    PAYMENT_PENDING: 10030,
    PAYMENT_FAILED: 10040,
    PAID: 30001
}

export const OPERATION_STATUS_ENUMS = {
    COMPLETE_QUOTE: -3000,
    MOVING_TO_HUB: -2500,
    ASSIGN_TO_JOB: -2000,
    ASSIGN_TO_DRIVER: -1000,
    NONE: 0,
    DRIVER_ARRIVED: 1000,
    RECIPIENT_DELAY_NOTIFIED: 1500
}

export const LOGISTIC_STATUS_ENUMS = {
    UNDELIVERED: -12001,
    EXCEPTION_DROP_OFF: -11001,
    EXCEPTION_PICK_UP: -10001,
    CANCELLED_RETURN_SENDER: -3001,
    CANCELLED_CANCELLATION_FEE: -2001,
    REVERSED_FULL_REFUND: -1501,
    CANCELLED_FULL_REFUND: -1001,
    CANCELLED_QUOTE: -1,
    PENDING: 0,
    STAGING: 1000,
    READY: 2501,
    PENDING_PICKUP: 10001,
    COLLECTED: 15001,
    PENDING_DROP_OFF: 20001,
    DELIVERED: 30001
}

export const HUB_DELIVERY_STATUS_ENUMS = {
    NONE: 0,
    MOVING_TO_HUB: 1,
    ARRIVED_AT_HUB: 2,
    RETURNED_TO_PICKUP: 3

}


//parcel type enums match GET api/parcelTypes
export const PARCEL_TYPES_ENUMS = {
    SMALL: "Small",
    MEDIUM: "Medium",
    LARGE: "Large",
    //EXTRA_LARGE: "Extra Large", Suppress Extra Large wording
    CUSTOM: "Custom"
}


//index ids for SignalR messages
export const INDEX_ENUMS = {
    TERRITORIES: 1,
    DELIVERIES: 2,
    JOBS: 3,
    USERS: 4
}

//task type enums match get api/taskTypes
export const TASK_TYPE_ENUMS = {
    PICK_UP_FROM_ORIGIN: 10000,
    DROP_OFF_AT_DESTINATION: 40000
}

//task status enums match get api/taskStatuses
export const TASK_STATUS_ENUMS = {
    EXCEPTION: -1001,
    CANCELLED: -1,
    NOT_STARTED: 2001,
    IN_PROGRESS: 3001,
    COMPLETED: 4001
}

export const TASK_STATUS_NAME_ENUM = {
    EXCEPTION: "Exception",
    CANCELLED: "Cancelled",
    NOT_STARTED: "Not started",
    IN_PROGRESS: "In progress",
    COMPLETED: "Completed"
}


// km brackets for our customer rates
export const KM_BRACKETS = {
    ZERO_TO_FIVE: "0 - 5 km",
    FIVE_TO_TEN: "5 - 10 km",
    TEN_TO_TWENTY: "10 - 20 km",
    TWENTY_TO_THIRTY: "20 - 30 km",
    THIRTY_TO_FORTY: "30 - 40 km",
    FORTY_TO_FIFTY: "40 - 50 km",
    FIFTY_TO_SIXTY: "50 - 60 km",
    SIXTY_TO_SEVENTY: "60 - 70 km",
    SEVENTY_TO_EIGHTY: "70 - 80 km",
    EIGHTY_TO_ONE_HUNDRED: "80 - 100 km",
}

// special hyper local rates for customers
export const HYPER_LOCAL_RATES = {
    SMALL: 32,
    MEDIUM: 44
}

// terminal task statuses
export const TERMINAL_TASK_STATUSES = [TASK_STATUS_ENUMS.EXCEPTION, TASK_STATUS_ENUMS.CANCELLED, TASK_STATUS_ENUMS.COMPLETED];

export const TERMINAL_LOGISTIC_STATUSES = [
    LOGISTIC_STATUS_ENUMS.DELIVERED,
    LOGISTIC_STATUS_ENUMS.UNDELIVERED,
    LOGISTIC_STATUS_ENUMS.EXCEPTION_PICK_UP,
    LOGISTIC_STATUS_ENUMS.EXCEPTION_DROP_OFF,
    LOGISTIC_STATUS_ENUMS.CANCELLED_FULL_REFUND,
    LOGISTIC_STATUS_ENUMS.CANCELLED_RETURN_SENDER,
    LOGISTIC_STATUS_ENUMS.CANCELLED_CANCELLATION_FEE,
    LOGISTIC_STATUS_ENUMS.CANCELLED_QUOTE,
    LOGISTIC_STATUS_ENUMS.REVERSED_FULL_REFUND
];

//job status enums match get api/taskStatuses
export const JOB_STATUS_ENUMS = {
    CANCELLED: -1,
    UNASSIGNED: 0,
    NOT_STARTED: 2001,
    IN_PROGRESS: 3001,
    COMPLETED: 4001
}

//marker type enums
export const MARKER_LEGEND_ENUMS = {
    PICK_UP: 1,
    DROP_OFF: 2,
    STORE_PICK_UP: 3,
    STORE_DROP_OFF: 4,
    QSR_STORE_PICK_UP: 5
}

//vehicle type enums
export const VEHICLE_TYPE_ENUMS = {
    MOTORCYCLE: 10,
    SEDAN: 20,
    HALF_TON_BAKKIE_WITHOUT_CANOPY: 31,
    HALF_TON_BAKKIE_WITH_CANOPY: 32,
    ONE_TON_BAKKIE_WITHOUT_CANOPY: 41,
    ONE_TON_BAKKIE_WITH_CANOPY: 42,
    H100_FLATBED: 51
}

//vehicle type name enums
export const VEHICLE_TYPE_NAME_ENUMS = {
    MOTORCYCLE: "Motorcycle",
    SEDAN: "Sedan",
    HALF_TON_BAKKIE_WITHOUT_CANOPY: "Half ton bakkie without canopy",
    HALF_TON_BAKKIE_WITH_CANOPY: "Half ton bakkie with canopy",
    ONE_TON_BAKKIE_WITHOUT_CANOPY: "1 ton bakkie without canopy",
    ONE_TON_BAKKIE_WITH_CANOPY: "1 ton bakkie with canopy",
    H100_FLATBED: "H100 flatbed"
}

//note message type enums
export const NOTE_MESSAGE_TYPE_ENUMS = {
    OTHER: 4001
}

export const USER_STATUS_ENUMS = {
    ONLINE: 1000,
    OFFLINE: 2000
}

export const DRIVER_STATUS_ENUMS = {
    OFFLINE: -1001,
    AVAILABLE: 1001,
    AVAILABLEQSRONLY: 1500,
    ONLINE: 2001,
    BUSY: 3001
}

export const UPLOAD_STATUS_ENUMS = {
    FAILED: -2000,
    CANCELLED: -1000,
    UNPROCESSED: 0,
    SUBMITTED: 1000,
    IN_PROGRESS: 2000,
    DELIVERY_CREATED: 3000,
    COMPLETED: 4000
}

export const WEBHOOK_STATUS_ENUMS = {
    FAILED: -2000,
    ERROR: -1000,
    UNKNOWN: 0,
    SUCCESS: 2000
}

export const APPLICATION_TYPE_ENUMS = {
    PARTNER: 1,
    PARTNER_DRIVER: 2,
    DRIVER: 3,
    VEHICLE: 4,
    BUSINESS: 5,
    ASSISTANT: 6
}

export const APPLICATION_TYPE_DESCRIPTIONS = {
    PARTNER: "Partner",
    PARTNER_DRIVER: "Partner Driver",
    DRIVER: "Driver",
    VEHICLE: "Vehicle",
    BUSINESS: "Business",
    ASSISTANT: "Assistant"
}


export const APPLICATION_STATUS_ENUMS = {
    CANCELLED: -2001,
    REJECTED: -1001,
    PENDING: 0,
    APPROVED: 2001
}

export const TRAINING_STATUS_ENUMS = {
    CANCELLED: -1000,
    NONE: 0,
    PENDING: 1000,
    COMPLETE: 2000
}

export const VEHICLE_DRIVER_INVITE_TYPE_ENUMS = {
    PARTNER_DRIVER: "Partner driver",
    INVITE_LATER: "Invite later",
    INVITE_DRIVER: "Invite driver",
    ADD_DRIVER: "Add driver"
}

export const CONTENT_CATEGORY_ENUMS = {
    PROFILE_PICTURE: "Profile picture",
    PROOF_OF_DELIVERY: "Proof of delivery",
    POST_DELIVERY_PICTURE: "Post delivery picture",
    BANK_ACCOUNT_DOCUMENT: "Bank account document",
    COMPANY_REGISTRATION_CERTIFICATE: "Company registration certificate",
    TAX_CLEARANCE_CERTIFICATE: "Tax clearance certificate",
    BBBEE_CERTIFICATE: "BBBEE certificate",
    GIT_INSURANCE: "GIT insurance",
    PUBLIC_LIABILITY_INSURANCE: "Public liability insurance",
    CIT_INSURANCE: "CIT insurance",
    DRIVING_LICENCE: "Driving licence",
    IDENTITY_DOCUMENT: "Identity document",
    PROOF_OF_ADDRESS: "Proof of address",
    POLICE_CLEARANCE_CERTIFICATE: "Police clearance certificate",
    WORK_PERMIT: "Work permit",
    VEHICLE_LICENCE_DISC: "Vehicle licence disc",
    VEHICLE_INSURANCE: "Vehicle insurance",
    ROADWORTHY_CERTIFICATE: "Roadworthy certificate",
    VEHICLE_PHOTO: "Vehicle photo",
    DIRECTORS_ID: "Directors id",
    TAX_CLEARANCE_CHECK: "Tax clearance check",
    BUSINESS_AGREEMENT: "Business agreement",
    PARTNER_AGREEMENT: "Partner agreement",
    VACANCY_ATTACHMENT: "Vacancy attachment",
    VAT_CERTIFICATE: "VAT certificate",
}

//message types for beacon updates
export const USER_ROLES = {
    Administrator: "Administrator",
    ManagementOperator: "Management Operator",
    StaffOperator: "Staff Operator",
    CompanyAdministrator: "Company Administrator",
    CompanyOperator: "Company Operator",
    CompanyUser: "Company User",
    StoreAdministrator: "Store Administrator",
    StoreOperator: "Store Operator",
    StoreUser: "Store User",
    Partner: "Partner",
    Driver: "Driver",
    PublicApi: "Public Api",
    SuperHero: "Super Hero",
    Assistant: "Assistant",
    QSR: "QSR"
};

//message types for beacon updates
export const BEACON_MESSAGE_TYPES = {
    SEND_USERS: 'Send_Users',
    SEND_DELIVERIES: 'Send_Deliveries',
    SEND_JOBS: 'Send_Jobs',
    COORDINATE_UPDATES: 'CoordinateUpdates',
    JOB_TASK_UPDATES: 'JobTaskUpdates',
    USER_PERMISSIONS_UPDATE: 'UserPermissionsUpdate',
    REFRESH_DRIVER_LIST: 'RefreshDriverList',
    REFRESH_DRIVER_LIST_AUTH: 'RefreshDriverListAuth',
    BULK_INDEXER_PONG: 'BulkIndexerPong',
    TRACKING_UPDATES: 'TrackingUpdates',
    WEB_NOTIFICATION: 'WebNotificationBroadcast'
}

//company type enums
export const COMPANY_TYPE_ENUMS = {
    CLOSE_CORPORATION: "Close Corporation",
    LISTED_COMPANY: "Listed Company",
    NATURAL_PERSON: "Natural Person",
    NPO: "NPO",
    OTHER_LEGAL_ENTITIES: "Other Legal Entities",
    PRIVATE_COMPANY: "Private Company",
    REGISTERED_PARTNERSHIP: "Registered Partnership",
    TRUST: "Trust",
    UNREGISTERED_PARTNERSHIP: "Unregistered Partnership"
}

export const TERMINAL_OPTION_ENUMS = {
    NOT_IN_TERMINAL_STATE: 1,
    IN_TERMINAL_STATE: 2
}


export const PARTNER_TYPES = {
    REGISTERED_BUSINESS: "Registered Business",
    NATURAL_PERSON: "Natural Person"
}

//entity state enums for when entities are created, updated, or deleted -> sent via SignalR
export const ENTITY_STATE_ENUMS = {
    EXISTING: 1,
    NEW: 2,
    REMOVED: 3
}

//contact type enums
export const CONTACT_TYPE_ENUMS = {
    RESIDENTIAL: "Residential",
    BUSINESS: "Business"
}

//Transaction type enums
export const TRANSACTION_TYPE_ENUMS = {
    UNLOCK_FUNDS: "Unlock funds",
    CREDIT: "Credit",
    DEBIT: "Debit",
    INVOICE: "Invoice",
    DEPOSIT: "Deposit",
    WITHDRAW: "Withdraw",
    ADVANCE: "Advance",
    CASH_CREDIT: "Cash Credit",
    CASH_DEBIT: "Cash Debit",
    REVERSE_WITHDRAW: "Reverse Withdraw",
    REVERSE_DEPOSIT: "Reverse Deposit",
    REVERSE_ADVANCE: "Reverse Advance"
}

//contact type enums
export const TRANSACTION_ITEM_TYPE_ENUMS = {
    JOB: "Job",
    DELIVERY: "Delivery"
}

//contact type enums
export const TRANSACTION_ITEM_TYPE_ID_ENUMS = {
    DELIVERY: 1,
    JOB: 2
}

//Delivery charge
export const RATE_TYPE_ENUMS = {
    DELIVERY_CHARGE: "Delivery Charge",
    DRIVER_PAY: "Driver Pay"
}

// pricing tier enums
export const PRICING_TIER_ENUMS = {
    CASUAL: "Casual",
    OCCASIONAL: "Occasional",
    REGULAR: "Regular",
    SUPER_USER: "Super-user"
}

// pricing tier thresholds 
export const PRICING_TIER_THRESHOLDS = {
    CASUAL: "0-49",
    OCCASIONAL: "50-499",
    REGULAR: "500-2999",
    SUPER_USER: "3000+"
}

// sla type enums
export const SLA_TYPE_ENUMS = {
    PRIORITY: "Priority",
    STANDARD: "Standard",
    HUPERLOCAL: "Hyperlocal"
}

// day of week type enums
export const DAY_OF_WEEK_TYPE_ENUMS = {
    WEEKDAY: "Weekday",
    WEEKEND: "Weekend"
}

// document status enums
export const DOCUMENT_STATUSES_ENUMS = {
    NONE: 0,
    VALID: 100,
    EXPIRING_SOON: 200,
    EXPIRED: 300,
    PENDING_APPROVAL: 400,
    REJECTED: 500
}

// term status enums
export const TERM_STATUSES_ENUMS = {
    NONE: 0,
    NEEDSACCEPTANCE: 100,
    ACCEPTED: 200
}

// term status enums
export const QSR_DRIVER_TYPES_ENUM = {
    NONE: 0,
    FULLTIME: 1000,
    PARTTIME: 2000
}

export const URGENT_DOCUMENT_STATUSES_ENUMS = [200, 300, 400, 500];

export const MAX_POLYGON_FILTERS = 5;

export const DEFAULT_TIMEZONE = "Africa/Johannesburg";
export const DEFAULT_COUNTRY_CODE = "ZA";

export const MAX_UPLOAD_SIZE = 20000000;

export const MAX_APPLICATION_FILE_SIZE_BYTES = 6000000;

export const LOADER_TIMEOUT_MILLIS = 15000;

export const EXCLUDED_NOTE_MESSAGE_TYPE_IDS = [
    24,//Added to job
    25,//Delivery adjusted
    32,//Delivery cloned
    33,//Parcels adjusted
    34,//Logistic details adjusted
    35,//Contact details updated
    37,//Driver assigned
    41,//Driver unassigned
    46,//Created via Public API
    50,//Removed from job
    51,//Delivery removed
    52,//Deliveries added
    53 //Created via file upload"
];

export const REASONS_NOTE_MESSAGE_TYPE_IDS = [
    60, //1. Lost in transit
    61, //2. Stolen in transit
    62, //3. Damaged in transit
    11, //4. Incorrect invoice
    63, //5. Trader does not have funds
    10, //6. Bad address
    9,  //7. Closed on arrival
    64, //8. Incorrect order placed
    12, //9. Incorrect stock
    68, //10. No invoice
    4,  //11. Cancelled on arrival
    1,  //12. Nothing to collect/no stock
    2,  //13. Parcel not ready for collection
    3,  //14. Incorrect vehicle selected
    5,  //15. Insufficient packaging
    6,  //16. Customer collected
    7,  //17. Contact unknown
    8,  //18. Contact not available
    65, //19. Customer refused
    13, //20. Strike/protest action
    67, //21. No driver confirmation
    66, //22. Late delivery
    14, //24. Hijacked
    69, //25. Banking issues
    70, //26. Late dispatch
    55, //27. Duplicate order
    56, //28. Suspected fraud
]

export const PICK_UP_NOTE_MESSAGE_TYPES_EXLUDE_PROOF = [10, 9, 7, 8, 13];
export const DROP_OFF_NOTE_MESSAGE_TYPES_EXLUDE_PROOF = [60, 61, 10, 9, 7, 8, 14];

export const DELIVERED_NOTE_MESSAGE_TYPES_IDS = [29];
export const CANCELLED_NOTE_MESSAGE_TYPE_IDS = [47];
export const EXCEPTION_NOTE_MESSAGE_TYPE_IDS = [48, 49];

export const SANITIZED_NOTE_MESSAGE_TYPE_DESCRIPTIONS = new Map(Object.entries({
    30: `Your order is being returned to the sender`,
    47: ``,//Cancelled
    48: `An exception was raised while collecting your order`,
    49: `An exception was raised while delivering your order`
}));

//tracking result strings
export const WAYBILL_NOT_FOUND_STRING = "Waybill number not found";
export const ORDER_REFERENCE_NOT_FOUND_STRING = "Order reference not found";

//this is the default portal country for south africa
export const DEFAULT_PORTAL_COUNTRY_DATA =
{
    id: 248,
    commonName: "South Africa",
    cca2: "ZA",
    cca3: "ZAF",
    currency: "ZAR",
    callingCode: "27",
    region: "Africa",
    area: "1221037",
    flag: "🇿🇦",
    timeZone: "Africa/Johannesburg",
    isActive: true
};

//GOOGLE_RECAPTCHA_SITE_KEY
export const GOOGLE_RECAPTCHA_SITE_KEY = "6LeTYe4ZAAAAAHRgdC8Xz8W-7DzFSqN4X3858fe1";

export const SCHEDULED_REPORTS = {
    CUSTOMER_DELIVERIES: 1,
    KPI_PERFORMANCE: 2,
    DELIVERY_SUMMARY: 3,
    SERVICE_LEVELS: 4,
    GROSS_PROFIT_STATISTICS: 5,
    SALES: 6,
    COST_OF_SALES: 7,
    DELIVERY_TERMINATION: 8,
    DRIVER_PERFORMANCE: 9
}

export const WEB_NOTIFICATION_TYPES = {
    NEW_HYPERLOCAL_DELIVERY: 1,
    PICK_UP_WARNING: 2

}

export const WEB_NOTIFICATION_TYPE_GROUPS = {
    DASHBOARD: [WEB_NOTIFICATION_TYPES.NEW_HYPERLOCAL_DELIVERY, WEB_NOTIFICATION_TYPES.PICK_UP_WARNING],
    FINANCE: []
}

export const INVOICE_TYPE_ENUMS = {
    CASH: "Cash",
    CASHLESS: "Cashless",
    TRANSITION: "Transition"
}

export const QSR_MODE_PARAM = "qsrMode";


export const ENVIRONMENTS = {
    PRODUCTION: "Production",
    INTEGRATIONS: "Integrations",
    STAGING: "Staging",
    DEVELOPMENT: "Development"
}

export const LOGITIC_STATUSES_TO_CHECK = ["Pending quote", "Ready", "Pending pick-up", "Collected", "Pending drop-off", "Pending Staging"];

// special driver flag type enums
export const SPECIAL_DRIVER_FLAG_TYPE_ENUMS = {
    NONE: "None",
    DCDRIVER: "Dc Driver"
}


export const ICON_STATE_ENUMS = {
    NORMAL: 0,
    ASSIGNED: 1,
    COMPLETED: 2
}


