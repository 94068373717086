import {
    WHITE_COLOR,
    BLACK_COLOR,
    FONT_FAMILY_HEADER,
    SMALL_FONT, PRIMARY_COLOR
} from '../../wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        backgroundColor: WHITE_COLOR,
        padding: "5px 10px"
    },
    zoomButton: {
        padding: 5
    },
    zoomIcon: {
        fill: lighten(BLACK_COLOR, 0.33),
    },
    distanceLabel:
    {
        margin:  theme.spacing(1)
    },
    distanceHeader: {
        fontFamily: FONT_FAMILY_HEADER,
        fontWeight: "200",
        fontSize: `${SMALL_FONT}px !important`,
        paddingBottom: 0,
        color: PRIMARY_COLOR,
        fontStyle: "italic",
    }
}));
