import React, { Component } from 'react';
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import axios from 'axios';
import { API_BASE_URL } from 'wumdrophubsreactshared/_constants/apiConstants';
import { FONT_FAMILY } from 'wumdrophubsreactshared/_constants/styleConstants';
import { MINIMUM_SEARCH_CHARACTERS } from 'wumdrophubsreactshared/_constants/selectConstants';
import withStylesHook from './../../../utils/withStylesHook';
import _ from 'lodash';
import useStyles from './AddressPicker.css';
import isEqual from '../../../wumdrophubsreactshared/_utils/isEqual';
import { SMALL_FONT } from '../../../wumdrophubsreactshared/_constants/styleConstants';

let customSelectStyles = {
  container: (base) => ({
    ...base,
    flex: 1, // Adjust the flex value as needed
  }),
  control: (base) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    borderRadius: 0,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderBottom: '1px solid hsl(0,0%,90%) !important',
    boxShadow: '0 !important'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none'
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: `${SMALL_FONT}px !important`,
    color: 'rgba(0, 0, 0, 0.40)',
    marginLeft: 23,
  }),
  input: (base) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: `${SMALL_FONT}px !important`,
    alignItems: 'center',
    display: 'flex',
    ':before': {
      content: '" "',
      marginLeft: -3,
      height: 25,
      width: 25
    },
    '& input': {
      font: 'inherit',
    },
  }),
  menu: base => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    zIndex: "2"
  }),
  option: (base) => ({
    ...base,
    paddingLeft: 25,
    fontFamily: FONT_FAMILY + "!important",
    textAlign: 'left',
    justContent: 'left'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    color: 'rgba(0, 0, 0, 0.40)'
  }),
  loadingMessage: (base) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 23,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: SMALL_FONT
  })
};

class AddressPicker extends Component {

  constructor(props) {
    super(props);
    let multi = false;
    if (!isEmpty(this.props.multiSelect)) {
      multi = this.props.multiSelect;
    }

    let value = "";
    if (!isEmpty(this.props.value)) {
      value = this.props.value;
    }

    let dKey = "key";
    let dValue = "value";
    if (!isEmpty(this.props.displayKey)) {
      dKey = this.props.displayKey;
    }
    if (!isEmpty(this.props.displayValue)) {
      dValue = this.props.displayValue;
    }

    let placeholder = "Search...";
    if (!isEmpty(this.props.placeholder)) {
      placeholder = this.props.placeholder;
    }

    this.state = {
      inputValue: '',
      data: [],
      noOptions: "Start typing...",
      isMultiSelect: multi,
      value: value,
      displayKey: dKey,
      displayValue: dValue,
      placeholder: placeholder,
      menuIsOpen: !isEmpty(this.props.menuIsOpen) ? this.props.menuIsOpen : false
    }

    this.nextSelector = this.props.nextSelector;

  }

  handleInputChange = (newValue) => {
    //const inputValue = newValue.replace(/\W/g, '');
    if (newValue.length === 0 || isEmpty(newValue)) {
      this.setState({ noOptions: "Enter an address..." });
    } else if (newValue.length < MINIMUM_SEARCH_CHARACTERS) {
      this.setState({ noOptions: "Minimum of " + MINIMUM_SEARCH_CHARACTERS + " characters for a search..." });
      return "";
    }
    this.setState({ newValue });

    return newValue;
  }

  handleChange = (selected) => {

    this.setState({ menuIsOpen: false }, function () {
      let filter = {};
      filter.field = this.props.fieldName;
      filter.placeId = !isEmpty(selected) ? selected[this.state.displayKey] : null;
      filter.description = !isEmpty(selected) ? selected[this.state.displayValue] : null;
      filter.getAddress = this.getSelectedAddress;

      this.setState({ value: selected }, function () {
        this.props.onSelect(filter).then((address) => {
          this.setState({ value: address }, function () {
            this.closeAndBlur();
            if (!isEmpty(this.props.nextSelector)) {
              if (!isEmpty(this.props.nextSelector.current)) {
                this.props.nextSelector.current.openAndFocus();
              }
            }
          });
        }).catch(() => {
          // this.props.setError(err);
        });
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.values, nextProps.values)) {
      if (isEmpty(nextProps.values)) {
        this.setState({ noOptions: "Enter an address..." })
      } else {
        this.setState({ data: nextProps.values })
      }
    }

    if (!isEmpty(nextProps.multiSelect) && nextProps.multiSelect !== this.props.multiSelect) {
      this.setState({ isMultiSelect: nextProps.multiSelect });
    }

    if (!isEqual(this.props.value, nextProps.value) && !isEmpty(this.props.value) && isEmpty(nextProps.value)) {
      this.setState({ value: nextProps.value });
    }
  }

  getSelectedAddress = (prediction, callback, failure) => {

    if (isEmpty(prediction)) {
      callback("")
    } else {
      return axios.post(API_BASE_URL + "/" + this.props.apiEntity, prediction)
        .then((res) => {
          callback(res.data);
        })
        .catch(err => {
          failure(err);
        });
    }
  };


  openAndFocus = () => {
    const { value } = this.state;

    if (isEmpty(value)) {
      this.setState({ menuIsOpen: true }, function () {
        this.select.focus();
      });
    }
  };

  closeAndBlur() {
    this.setState({ menuIsOpen: false }, function () {
      this.select.blur();
    });
  };


  loadOptions = (inputValue, callback) => {
    if (inputValue.length < MINIMUM_SEARCH_CHARACTERS) {
      callback("")
    } else {
      let filter = "";
      if (!isEmpty(this.props.filter)) {
        filter = "&" + this.props.filter.key + "=" + this.props.filter.value;
      }
      return axios.get(API_BASE_URL + "/" + this.props.apiEntity + "?select=true&search=" + inputValue + filter)
        .then((res) => {
          if (isEmpty(res.data)) {
            this.setState({ noOptions: "No matching addresses found." });
          }
          callback(res.data);
        })
        .catch(() => {
          this.setState({ noOptions: "No matching addresses found." });
          callback("");
        });
    }
  };

  onFocus() {
    const { value } = this.state;

    if (!isEmpty(value)) {
      this.setState({ value: null, tempValue: value }, function () {
        this.setState({ menuIsOpen: true });
      });
    }
    else {
      this.setState({ menuIsOpen: true });
    }

  }

  onBlur(e) {
    const { value, tempValue } = this.state;
    const { handleBlur } = this.props;

    if (isEmpty(value) && !isEmpty(tempValue)) {
      this.setState({ value: tempValue, tempValue: null }, function () {
        this.setState({ menuIsOpen: false });
      });
    }
    else {
      this.setState({ menuIsOpen: false });
    }

    handleBlur(e);
  }

  render() {
    const { classes, icon } = this.props;//isValid
    const { noOptions, displayKey, displayValue, placeholder, value, menuIsOpen } = this.state;

    return (
      <div style={{position:"relative"}} className={classes.selectContainer}>
        <span style={{position:"absolute",left:"-1px",zIndex:"1"}} >
        {icon}
        </span>
        <AsyncSelect
          ref={ref => {
            this.select = ref;
          }}
          inputProps={{ autoComplete: 0, autoFill: 'off' }}
          styles={customSelectStyles}
          cacheOptions
          menuIsOpen={menuIsOpen}
          //defaultValue={value}
          value={value}
          loadOptions={_.debounce(this.loadOptions, 500)}
          onInputChange={_.debounce(this.handleInputChange, 500)}
          onChange={this.handleChange}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus.bind(this)}
          placeholder={placeholder}
          isMulti={this.state.isMultiSelect}
          noOptionsMessage={() => noOptions}
          getOptionLabel={(values) => values[displayValue]}
          getOptionValue={(values) => values[displayKey]}
          isClearable={true}
        //   isDisabled={true}
        />
        <section className={classes.underline} />
      </div>
    );
  }
}

AddressPicker.propTypes = {
  apiEntity: PropTypes.string.isRequired
};

export default withStylesHook(useStyles)(AddressPicker);