import React, { forwardRef } from "react";
import MaskedInput from "react-text-mask";

const PhoneNumberMask = forwardRef(function PhoneNumberMask(props, ref) {
    const { inputRef = () => {}, ...other } = props; 
    return (
        <MaskedInput
            {...other}
            ref={(maskedInputRef) => {
                if (inputRef) inputRef(maskedInputRef ? maskedInputRef.inputElement : null);
                if (ref) ref(maskedInputRef); 
            }}
            mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
});

export default PhoneNumberMask;
