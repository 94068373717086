import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import withStylesHook from 'utils/withStylesHook';
import RefreshIcon from '@mui/icons-material/Refresh';
import styles from './ReconnectButton.css';
import { RETRY_CONNECTION_INTERVAL_MILLIS } from '../../../wumdrophubsreactshared/_constants/beaconConstants';

class ReconnectButton extends React.Component {

    constructor(props){
        super(props);
        this.state = { seconds: RETRY_CONNECTION_INTERVAL_MILLIS/1000 };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.startTimer();
    }

    startTimer() {
       
        if (this.timer === 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        // Check if we're at zero.
        if (seconds === -1) { 
            this.setState({
                seconds: RETRY_CONNECTION_INTERVAL_MILLIS/1000
            });
        } else {
            this.setState({
                seconds: seconds
            });
        }
    }

    handleRefresh = () => {
        if(this.props.onRefresh) {
            this.props.onRefresh();
        }
    }    

    render() {
        const { classes, message } = this.props;
        const { seconds } = this.state;

        return (
            <div className={classes.buttonContainer}>
                <Button variant="contained" size="small" color="primary" fullWidth={true} className={classes.button} onClick={this.handleRefresh}>
                    {message} {seconds}
                    {<RefreshIcon className={classes.rightIcon}/>}
                </Button>
            </div>
        );
    }
}

ReconnectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};

export default withStylesHook(styles)(ReconnectButton);