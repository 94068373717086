 //this function converts a number to equivalent alphanumeric character(s)
 //i.e., 1 = A, 2 = B, 3 = C ... 27 = AA, 28 = AB, 29 = AC etc.
const convertToNumberingScheme = (number) => {
    let baseChar = ("A").charCodeAt(0),
    letters  = "";

    do {
        number -= 1;
        letters = String.fromCharCode(baseChar + (number % 26)) + letters;
        number = (number / 26) >> 0; // quick `floor`
    } while (number > 0);

    return letters;
}
export default convertToNumberingScheme;