import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const useWidth = () => {
  const theme = useTheme();

  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  // Determine the width based on the highest matching breakpoint
  if (isXl) return 'xl';
  if (isLg) return 'lg';
  if (isMd) return 'md';
  if (isSm) return 'sm';
  return 'xs';
};

// HOC: Wraps a component and injects the `width` prop using `useWidth`
function withWidthHook(WrappedComponent) {
  return function WrapperComponent(props) {
    const width = useWidth(); // Get the width from the hook
    return <WrappedComponent {...props} width={width} />;
  };
}

export default withWidthHook;
export { useWidth };
