import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import { lighten } from '@mui/material';
import {
    BLACK_COLOR,
    COMPLEMENTARY_5_COLOR, LIGHT_GREY_COLOR, SMALL_FONT,
    TINY_FONT,
    WHITE_COLOR,
    CALL_TO_ACTION_GREEN_COLOR
} from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        marginBottom: "15px !important"
    },
    feedback: {
        ...feedback
    },
    gridItem: {
        paddingTop: "0px !important",
    },
    expansionPanel: {
        display: "none !important",
        boxShadow: "none !important"
    },
    expansionPanelSummary: {
        padding: "0px !important"
    },
    expansionPanelDetails: {
        padding: "0px !important"
    },
    group: {
        flexDirection: "row !important"
    },
    formControl: {
        margin: "0px 0px 15px 0px !important",
        position: "relative !important",
        padding: "0px !important"
    },
    addressName: {
        width: "calc(100% - 110px) !important"
    },
    addressUnit: {
        marginLeft: "10px !important",
        width: "100px !important"
    },
    gridContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px !important"
        }
    },
    gridMarginLeft: {
        marginLeft: "25px !important",
        position: "relative !important",
        [theme.breakpoints.up('md')]: {
            marginLeft: "0px !important",
            position: "relative !important",
        }
    },
    navCircle: {
        color: `${lighten(BLACK_COLOR, 0.7)} !important`,
        fontSize: `${TINY_FONT}px !important`,
        marginLeft: "-25px !important",
        marginTop: "9px !important",
        position: "absolute !important",
        [theme.breakpoints.up('md')]: {
            margin: "5px 5px 0px -3px !important",
            position: "relative !important"
        }
    },
    navSquare: {
        color: `${lighten(BLACK_COLOR, 0.7)} !important`,
        fontSize: `${TINY_FONT}px !important`,
        marginLeft: "-25px !important",
        marginTop: "9px !important",
        position: "absolute !important",
        [theme.breakpoints.up('md')]: {
            margin: "5px 5px 0px -3px !important",
            position: "relative !important"
        }
    },
    detailsInfo: {
        paddingBottom: "25px !important",
    },
    button: {
        marginTop: `${theme.spacing(1)} !important`,
        marginRight: `${theme.spacing(1)} !important`,
        backgroundColor: `${CALL_TO_ACTION_GREEN_COLOR} !important`,
        color: `${WHITE_COLOR} !important`,
        textTransform: "none !important",
        boxShadow: "none !important",
        fontSize: `${SMALL_FONT}px !important`,
        padding: "7px 28px !important",
        '&:hover': {
            boxShadow: "none !important",
            backgroundColor: `${CALL_TO_ACTION_GREEN_COLOR} !important`,
            color: `${WHITE_COLOR} !important`,
        },
        '&:active': {
            boxShadow: "none !important",
        }
    },
    buttonNext: {
        backgroundColor: `${COMPLEMENTARY_5_COLOR} !important`,
        color: `${WHITE_COLOR} !important`,
        textTransform: "capitalize !important",
        boxShadow: "none !important",
        fontSize: `${SMALL_FONT}px !important`,
        padding: "7px 28px !important",
        '&:hover': {
            boxShadow: "none !important",
            backgroundColor: `${COMPLEMENTARY_5_COLOR} !important`,
            color: `${WHITE_COLOR} !important`,
        },
        '&:active': {
            boxShadow: "none !important",
        }
    },
    buttonTextOnly: {
        color: `${COMPLEMENTARY_5_COLOR} !important`,
        backgroundColor: `${WHITE_COLOR} !important`,
        textTransform: "capitalize !important",
        marginRight: "10px !important",
        boxShadow: "none !important",
        fontSize: `${SMALL_FONT}px !important`,
        padding: "7px 28px !important",
        '&:hover': {
            boxShadow: "none !important",
            color: `${COMPLEMENTARY_5_COLOR} !important`,
            backgroundColor: `${lighten(LIGHT_GREY_COLOR, 0.45)} !important`,
        },
        '&:active': {
            boxShadow: "none !important",
        }
    },
    progress: {
        width: "100% !important",
        marginTop: "10px !important",
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    }
}));
