import {  BLACK_COLOR,
  SMALL_FONT,
  SLATE_COLOR,
  TERTIARY_COLOR,
  LARGE_FONT,
  FONT_FAMILY_HEADER,
  PRIMARY_COLOR,
  FONT_FAMILY,
  TINY_FONT } from "wumdrophubsreactshared/_constants/styleConstants";
  import { lighten } from "@mui/material";

export default theme => ({
    deliveryStepHeader: {
    fontFamily: `${FONT_FAMILY_HEADER} !important`,
    fontWeight: "200 !important",
    fontSize: `${SMALL_FONT}px !important`,
    paddingBottom: "0px !important",
    color: `${PRIMARY_COLOR} !important`,
    fontStyle: "italic !important",
    marginBottom: "9px !important",
  },
  stepHeader: {
    fontFamily: `${FONT_FAMILY_HEADER} !important`,
    fontWeight: "bold !important",
    paddingBottom: "20px !important",
    paddingLeft: "6px !important",
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  stepTitle: {
    color: `${TERTIARY_COLOR} !important`,
  },
  price: {
    color: `${BLACK_COLOR} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    fontWeight: "bold !important",
  },
  expiryText: {
    color: `${TERTIARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
  },
  timeIcon: {
    color: `${lighten(BLACK_COLOR, 0.1)} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    marginRight: "2px !important",
    marginLeft: "2px !important",
    marginBottom: "-3px !important",
  },
  timeHours: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`,
  },
  timeHoursLarge: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${SMALL_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`,
  },
  timeDetailsHours: {
    minWidth: "220px !important",
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`,
  },
  deliveryRemarks: {
    fontFamily: `${FONT_FAMILY} !important`,
    fontSize: `${TINY_FONT}px !important`,
  },
  slaTitle: {
    fontWeight: "600 !important",
    color: `${SLATE_COLOR} !important`,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "30px !important",
    },
  },
  dateText: {
    fontSize: `${SMALL_FONT}px !important`,
    color: `${lighten(BLACK_COLOR, 0.4)} !important`,
    marginTop: "2px !important",
  },
  displayAddress: {
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    fontSize: "0.85em !important",
    fontWeight: "600 !important",
  },
  displayAddressWrap: {
    fontSize: `${SMALL_FONT}px !important`,
    fontWeight: "600 !important",
    paddingRight: "12px !important",
  },
  displayDetailsAddressWrap: {
    fontSize: `${TINY_FONT}px !important`,
    fontWeight: "700 !important",
  },
  inputIconsColor: {
    color: `${BLACK_COLOR} !important`,
    fontSize: "17px !important",
    margin: "5px 5px -5px 0px !important",
  },
  infoIcons: {
    color: `${BLACK_COLOR} !important`,
    fontSize: "17px !important",
  },
  marginTop15: {
    marginTop: "15px !important",
  },
  textTotal: {
    color: `${BLACK_COLOR} !important`,
    fontFamily: `${FONT_FAMILY} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontWeight: "100 !important",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "15px !important",
    },
  },
  textValue: {
    float: "right !important",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "15px !important",
      float: "right !important",
    },
  },
  textPadding: {
    paddingLeft: "0px !important",
  },
  hideElement: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  deliveryTitle: {
    fontWeight: "bold !important",
    padding: "17px 0px 12px 9px !important",
  },
  deliverySubTitle: {
    color: `${TERTIARY_COLOR} !important`,
    fontWeight: "600 !important",
  },
  stepSubTitle: {
    fontSize: `${SMALL_FONT}px !important`,
  },
  gridItemPadding: {
    paddingRight: "45px !important",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px !important",
    },
  },
  setGridWidthAsIs: {
    // leave class here, must be empty
  },
  maxGridWidth: {
    maxWidth: "100% !important",
    flexBasis: "100% !important",
  },
    formControl: {
        paddingTop: 0
    },
    heading: {
        fontSize: "0.9em",
        fontWeight: 600,
        color: PRIMARY_COLOR,
    },
    gridItem: {
        paddingTop: 12,
        paddingBottom: "0 !important"
    },
    inputTextField: {
        fontSize: "12px !important",
        "&::placeholder": {
            fontSize: "1em",
        }
    },
    expansionPanelSummary: {
        marginTop: 0,
        marginBottom: 0
    },
});