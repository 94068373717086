import { whiteColor } from "assets/jss/material-kit-pro-react.jsx";
import {
    FONT_FAMILY,
    FONT_FAMILY_HEADER,
    LIGHT_GREY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { SECONDARY_COLOR, DARK_GREEN_COLOR, WHITE_COLOR, PRIMARY_COLOR, TINY_FONT, SMALL_FONT, COMPLEMENTARY_3_COLOR, COMPLEMENTARY_5_COLOR, TERTIARY_COLOR } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@mui/material";
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    sliderTile: {
        width: "calc(100% - 7px) !important",
        margin: "4px !important",
        padding: "1px !important",
        backgroundColor: WHITE_COLOR + " !important",
        "&:hover": {
            background: lighten(COMPLEMENTARY_3_COLOR, 0.85) + " !important",
        }
    },
    tileHeading: {
        fontFamily: FONT_FAMILY_HEADER + " !important",
        fontSize: SMALL_FONT + " !important",
        textTransform: "capitalize !important",
        fontWeight: "600 !important",
        lineHeight: "19px !important",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "-7px !important"
        }
    },
    tileText: {
        fontFamily: FONT_FAMILY + "!important",
        fontSize: "0.7em !important",
        lineHeight: "12px !important",
        paddingBottom: "12px !important",
        color: lighten(PRIMARY_COLOR, 0.2) + " !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7em !important",
        }
    },
    tileIcon: {
        height: "45px !important",
        marginTop: "5px !important"
    },
    tileButton: {
        background: whiteColor + " !important",
        border: "2px solid " + COMPLEMENTARY_5_COLOR + " !important",
        color: COMPLEMENTARY_5_COLOR + " !important",
        "&:hover": {
            background: COMPLEMENTARY_5_COLOR + " !important",
            color: whiteColor + " !important",
        }
    },
    fragileTitle: {
        fontSize: SMALL_FONT + " !important"
    },

    parcelItem: {
        padding: "5px 5px 0 5px !important"
    },

    parcelItemIcon: {
        height: "28px !important",
        paddingLeft: "12px !important",
        paddingTop: "4px !important",
        [theme.breakpoints.down('sm')]: {
            height: "27px !important",
            paddingLeft: "18px !important",
        }
    },
    parcelItemText: {
        fontFamily: FONT_FAMILY_HEADER + " !important",
        fontSize: SMALL_FONT + " !important",
        textTransform: "capitalize !important",
        fontWeight: "600 !important",
        lineHeight: "19px !important",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "5px !important",
            lineHeight: "27px !important",
        },
    },
    parcelItemSubText: {
        fontFamily: FONT_FAMILY + "!important",
        fontSize: "0.6em !important",
        lineHeight: "12px !important",
        paddingBottom: "12px !important",
        color: lighten(PRIMARY_COLOR, 0.3) + " !important",
        [theme.breakpoints.down('sm')]: {
            padding: "2px 0 0 5px !important",
            fontSize: "11px !important",
            lineHeight: "20px !important",
        },
        marginTop: "2px !important"
    },
    refCode: {
        margin: "0 !important",
        fontSize: SMALL_FONT + " !important",
        backgroundColor: WHITE_COLOR + " !important",
        [theme.breakpoints.down('sm')]: {
            margin: "2px !important",
        }
    },
    deleteButton: {
        marginTop: "2px !important",
        float: "left !important",
        color: SECONDARY_COLOR + " !important"
    },
    moreButton: {
        marginTop: "2px !important",
        float: "right !important",
        color: COMPLEMENTARY_5_COLOR + " !important"
    },
    addIconGrey: {
        color: LIGHT_GREY_COLOR + " !important",
        marginLeft: "2px !important",
        marginTop: "2px !important",
        float: "left !important",
        fontSize: "22px !important"
    },
    addIcon: {
        color: DARK_GREEN_COLOR + " !important",
        marginLeft: "2px !important",
        marginTop: "2px !important",
        float: "left !important",
        fontSize: "22px !important"
    },
    MuiFormControlLabel: {
        root: {
            fontSize: TINY_FONT + " !important",
            color: COMPLEMENTARY_5_COLOR + " !important"
        }
    },
    parcelContainer: {
        padding: "5px !important",
        backgroundColor: COMPLEMENTARY_3_COLOR + " !important"
    },
    parcelSummary: {
        fontSize: TINY_FONT + " !important",
        float: "right !important",
        color: PRIMARY_COLOR + " !important",
        margin: "5px !important",
        fontWeight: "600 !important",
    },
    parcelDetails: {
        fontWeight: "400 !important",
        color: lighten(PRIMARY_COLOR, 0.5) + " !important"
    },
    parcelContent: {
        marginTop: "10px !important"
    },
    fragileLabel: {
        fontSize: TINY_FONT + " !important",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "-24px !important"
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: "3px !important",
        }
    },
    descriptionLabel: {
        fontSize: TINY_FONT + " !important",
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important"
        }
    },
    progress: {
        width: "100% !important",
        marginTop: "50px !important",
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    successSnackbar: {
        backgroundColor: TERTIARY_COLOR + " !important"
    }
}));
