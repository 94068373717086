import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { getCountries } from 'wumdrophubsreactshared/_actions/countries';
import isEmpty from "wumdrophubsreactshared/_utils/isEmpty";

const defaultCountry = { key: 'ZA', flag: '🇿🇦', value: '🇿🇦 South Africa (+27)' };
const CountryPicker = ({ countries, getCountries, onChange, name }) => {
    const [value, setValue] = useState(defaultCountry);
    const [callingCodes, setCallingCodes] = useState([]);
    const options = callingCodes.map(item => ({ ...item, label: item.value }));

    useEffect(() => {
        let array = [];
        if (!isEmpty(countries?.data)) {
            array = countries.data.map(country => {
                return {
                    name:name,
                    key: country.cca2,
                    flag: country.flag,
                    value: `${country.flag} ${country.commonName} (+${country.callingCode})`
                }
            });
            setCallingCodes(array);
        } else {
            getCountries();
        }
    }, [countries, getCountries, name]);

    const handleChange = (selected) => {
        setValue(selected);
        if (onChange)
            onChange(selected);
    }

    return (
        <Select
            variant="standard"            
            name={name}
            options={options}
            isSearchable={true}
            styles={customSelectStyles}
            placeholder="Select"
            onChange={handleChange}
            value={{ ...value, label: value.flag }}
        />
    )
}

let customSelectStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: `0px`,
        borderTop: `0px solid transparent`,
        borderRight: `0px solid transparent`,
        borderLeft: `0px solid transparent`,
        borderBottom: state.isFocused ? '2px solid black' : '1px solid black',
        zIndex: state.isFocused ? `1000` : `0`,
        marginBottom: `10px`,
        boxShadow: `none`,
        width: `45px`,
        cursor: `pointer`,
        transition: 'border-color 0.2s ease-in-out', // Smooth transition for hover effect
        '&:hover': {
            borderColor: 'rgb(51, 51, 51)', // Change to your desired hover border color
        },

    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: `none`,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        display: `none`,
    }),
    input: (base) => ({
        ...base,
        alignItems: `center`,
        display: `flex`,
        ':before': {
            content: '"  "',
            marginLeft: `-3px`,
            height: `25px`,
            width: `30px`,
        },
        '& input': {
            font: `inherit`,
        },
        marginBottom: `-1px`,
    }),
    menu: (base) => ({
        ...base,
        zIndex: `1`,
        width: `350px`,
        minWidth: `350px`,
        marginTop: `0px`,
    }),
    menuList: (base) => ({
        ...base,
        padding: `0px`,
    }),
    singleValue: (base) => ({
        ...base,
        marginLeft: `-5px`,
        marginBottom: `-5px`,
        fontSize: `20px`,
    }),
};




const mapStateToProps = (state) => ({
    countries: state.countries
})
export default connect(mapStateToProps, { getCountries })(CountryPicker);