import {
    ADMIN_CONTAINER_HEIGHT
} from '../../wumdrophubsreactshared/_constants/styleConstants';

export default theme => ({
    root: {
        height: `${ADMIN_CONTAINER_HEIGHT} !important`,
        overflowY: 'auto !important',
        margin: "20px !important"
    },
    loader: {
        display: 'flex !important',
        flexDirection:"column !important",
        justifyContent: 'center !important',
        alignItems: 'center !important',
        height: '90vh !important'
    }
});