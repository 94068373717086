import React from 'react';
import {
    ICON_GENERATORE_DELIVERY_ICONS,
    ICON_GENERATORE_DRIVER_ICONS,
    ICON_GENERATOR_BASE_URL,
    ICON_VERSION,
    JOB_STATUS_ENUMS,
    LOGITIC_STATUSES_TO_CHECK,
    MARKER_LEGEND_ENUMS,
    TASK_TYPE_ENUMS
} from 'wumdrophubsreactshared/_constants/apiConstants';
import {
    DARK_GREEN_COLOR,
    DIRECTIONS_COLOR,
    DRIVER_AVATAR_LIGHTNESS,
    DRIVER_AVATAR_SATURATION,
    SECONDARY_COLOR,
    TERTIARY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import convertToNumberingScheme from 'wumdrophubsreactshared/_utils/convertToNumberingScheme';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import stringToHslColor from 'wumdrophubsreactshared/_utils/stringToHslColor';

const getMarkerIconUrl = ({ section, optionalValues }) => {
    const generateUrl = (label, markerType, color) =>
        `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=${label}&markerType=${markerType}&version=${ICON_VERSION}&markerColour=${encodeURIComponent(color)}`;

    let icon = <></>;
    const { isStore, taskTypeId, isHub, index, tracking, markerLabel, markerType, task, jobs, store, delivery, storeId, driver } = optionalValues || {};

    switch (section) {
        case "jobRouteMap": {
            const color = SECONDARY_COLOR;
            const letter = convertToNumberingScheme((index || 0) + 1);

            if (taskTypeId === TASK_TYPE_ENUMS.PICK_UP_FROM_ORIGIN) {
                if (isHub) {
                    icon = <>{letter}</>;
                } else {
                    const legendType = isStore
                        ? MARKER_LEGEND_ENUMS.STORE_PICK_UP
                        : MARKER_LEGEND_ENUMS.PICK_UP;
                    icon = generateUrl(letter, legendType, color);
                }
            } else {
                const legendType = isStore
                    ? MARKER_LEGEND_ENUMS.STORE_DROP_OFF
                    : MARKER_LEGEND_ENUMS.DROP_OFF;
                icon = generateUrl(letter, legendType, color);
            }
            break;
        }

        case "trackingMap": {
            const { firstName, lastName, id, vehicleTypeId, statusId } = tracking?.data?.tracking?.driver || {};
            const labelColour = stringToHslColor(`${firstName}${lastName}${id}`, DRIVER_AVATAR_SATURATION, DRIVER_AVATAR_LIGHTNESS);
            const initials = `${firstName?.charAt(0).toUpperCase() || ''}${lastName?.charAt(0).toUpperCase() || ''}`;

            icon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DRIVER_ICONS}?initials=${initials}&labelColour=${labelColour}&vehicletype=${vehicleTypeId}&status=${statusId}&version=${ICON_VERSION}`;
            break;
        }

        case "dashboardJobMapMarker": {
            let jobStatusId = JOB_STATUS_ENUMS.UNASSIGNED;
            let markerColourEncoded = DIRECTIONS_COLOR;

            if (!isEmpty(jobs) && !isEmpty(jobs[`#${task.jobId}`])) {
                jobStatusId = jobs[`#${task.jobId}`].jobStatusId;
                if (jobStatusId !== JOB_STATUS_ENUMS.UNASSIGNED) {
                    markerColourEncoded = TERTIARY_COLOR;
                }
            }
            icon = generateUrl(markerLabel, markerType, markerColourEncoded);
            break;
        }

        case "dashboardJobMapQsr": {
            const storeLabel = store?.totalOriginDeliveries ?? "-";
            icon = generateUrl(storeLabel, MARKER_LEGEND_ENUMS.QSR_STORE_PICK_UP, DIRECTIONS_COLOR);
            break;
        }

        case "deliveryMarkerPickUp":
        case "deliveryMarkerDropOff": {
            const markerColour =
                delivery?.operationStatusId >= 0
                    ? LOGITIC_STATUSES_TO_CHECK.includes(delivery?.logisticStatus)
                        ? TERTIARY_COLOR
                        : DARK_GREEN_COLOR
                    : DIRECTIONS_COLOR;

            icon = generateUrl(markerLabel, markerType, markerColour);
            break;
        }

        case "deliveryMarkerQsr": {
            const storeLabel = store?.totalOriginDeliveries ?? "-";
            icon = generateUrl(storeLabel, MARKER_LEGEND_ENUMS.QSR_STORE_PICK_UP, DIRECTIONS_COLOR);
            break;
        }

        case "basicMapStartPoint": {
            const label = "P"
            const legendType = storeId ? MARKER_LEGEND_ENUMS.STORE_PICK_UP : MARKER_LEGEND_ENUMS.PICK_UP;
            icon = generateUrl(label, legendType, DIRECTIONS_COLOR);
            break;
        }
        case "basicMapEndPoint": {
            const label = "D"
            const legendType = storeId ? MARKER_LEGEND_ENUMS.STORE_DROP_OFF : MARKER_LEGEND_ENUMS.DROP_OFF;
            icon = generateUrl(label, legendType, DIRECTIONS_COLOR);
            break;
        }
        case "dashboardBaseMap": {
            let vehicleTypeId = 10;
            if (!isEmpty(driver.vehicle) && !isEmpty(driver.vehicle.vehicleTypeId)) {
                vehicleTypeId = driver.vehicle.vehicleTypeId;
            }
            let labelColour = stringToHslColor(`${driver.firstName}${driver.lastName}${driver.id}`, DRIVER_AVATAR_SATURATION, DRIVER_AVATAR_LIGHTNESS);

            let initials = `${driver.firstName.charAt(0).toUpperCase()}${driver.lastName.charAt(0).toUpperCase()}`;

            let driverStatusId = driver.driverStatusId;

            //fetch the driver icon from our icon generator microservice
            icon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DRIVER_ICONS}?initials=${initials}&labelColour=${labelColour}&vehicletype=${vehicleTypeId}&status=${driverStatusId}&version=${ICON_VERSION}`;
        }
            break;
        default: {
            const label = storeId ? "D" : "P";
            const legendType = storeId ? MARKER_LEGEND_ENUMS.STORE_DROP_OFF : MARKER_LEGEND_ENUMS.STORE_PICK_UP;
            icon = generateUrl(label, legendType, DIRECTIONS_COLOR);
            break;
        }
    }

    return icon;
};

export default getMarkerIconUrl;
