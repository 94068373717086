import { SECONDARY_COLOR, DARK_GREEN_COLOR, ERROR_COLOR, FONT_FAMILY } from "../../../wumdrophubsreactshared/_constants/styleConstants";

export default theme => ({
    customCard: {
        marginTop: "30px !important",
        marginBottom: "30px !important",
        width: "250px !important",
        margin: "0 auto !important",
        padding: "20px !important",
        maxHeight: "calc(100% - 60px) !important",
        overflowY: "auto !important"
    },
    completeCustomDialog: {
        color: DARK_GREEN_COLOR + " !important"
    },
    closeCustomDialog: {
        color: SECONDARY_COLOR + " !important"
    },
    feedback: {
        color: ERROR_COLOR + " !important",
        fontFamily: FONT_FAMILY + " !important",
        fontSize: "10px !important",
    },
    input: {
        marginTop: "15px !important",
    },
    sizesRoot: {
        display: 'flex !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
        '& > *': {
            margin: theme.spacing(1) + " !important",
        },
        marginTop: "15px !important"
    },
    checkbox: {
        marginTop: "15px !important",
        marginLeft: "3px !important"
    }
})
