import {
    CALL_TO_ACTION_GREEN_COLOR,
    SLATE_COLOR, SMALL_FONT, WHITE_COLOR, PRIMARY_COLOR
} from 'wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from "@mui/material";
import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        padding: "0px !important",
    },
    deliveryContainer: {
        minHeight: "218px !important",
    },
    thankyouHeader: {
        fontSize: "30px !important",
        marginBottom: "20px !important",
        color: `${SLATE_COLOR} !important`,
        textAlign: "center !important",
        lineHeight: "36px !important",
    },
    componentContainer: {
        minHeight: "218px !important",
    },
    confirmationCopy: {
        textAlign: "center !important",
        marginBottom: "26px !important",
    },
    confirmationImg: {
        paddingTop: "25px !important",
        textAlign: "center !important",
        margin: "0 auto !important",
        display: "block !important",
    },
    center: {
        textAlign: "center !important",
        marginTop: "10px !important",
    },
    callToActionButton: {
        backgroundColor: `${CALL_TO_ACTION_GREEN_COLOR} !important`,
        color: `${WHITE_COLOR} !important`,
        textTransform: "none !important",
        boxShadow: "none !important",
        fontSize: `${SMALL_FONT}px !important`,
        padding: "7px 28px !important",
        marginTop: "10px !important",
        marginLeft: "5px !important",
        marginRight: "10px !important",
        '&:hover': {
            boxShadow: "none !important",
            backgroundColor: `${lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2)} !important`,
            color: `${WHITE_COLOR} !important`,
        },
        '&:active': {
            boxShadow: "none !important",
        },
    },
    actionButton: {
        backgroundColor: `${PRIMARY_COLOR} !important`,
        color: `${WHITE_COLOR} !important`,
        textTransform: "none !important",
        boxShadow: "none !important",
        fontSize: `${SMALL_FONT}px !important`,
        padding: "7px 28px !important",
        marginTop: "10px !important",
        marginLeft: "5px !important",
        marginRight: "10px !important",
        '&:hover': {
            boxShadow: "none !important",
            backgroundColor: `${lighten(PRIMARY_COLOR, 0.2)} !important`,
            color: `${WHITE_COLOR} !important`,
        },
        '&:active': {
            boxShadow: "none !important",
        },
    },
}));
