import React from 'react';

import { makeStyles } from '@mui/styles';
import styles from './ContactDetails.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Radio} from '@mui/material';
import {RadioGroup} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { ErrorMessage } from 'formik';

import PhoneNumberPicker from '../../common/pickers/PhoneNumberPicker';
import { CONTACT_TYPE_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';
import {
    CommentOutlined,
    HomeWorkOutlined,
    PersonOutlineOutlined
} from '@mui/icons-material';
import { Typography, Tooltip, TextField, Grid } from '@mui/material';
//import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import InputAdornment from "@mui/material/InputAdornment";
const useStyles = makeStyles(styles)

const ContactDetails = (props) => {
    const { contactTypes,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setValues,
        setFieldValue,
        handleAddressTypeChange,
        addressTypeFieldName,
        contactNameFieldName,
        contactCountryFieldName,
        phoneNumberFieldName,
        buildingNameFieldName,
        buildingUnitFieldName,
        remarksFieldName,
        addressType,
        currentUser,
        contactDetailsSameAsUserFieldName,
        contactIdFieldName,
        displayAddressFieldName,
        storeIdFieldName,
        store,
        addressIcon,
        enableAutoComplete = false
    } = props;

    const [state, setState] = React.useState({
        myDetailsChecked: values[contactDetailsSameAsUserFieldName],
        contactsSelectVisible: values[contactIdFieldName] !== null
    });

    const classes = useStyles();

    const handleCheckboxChange = name => event => {
        let newValues = { ...values };
        const { user } = currentUser;
        if (event.target.checked) {
            if (values[storeIdFieldName] && store) {
                newValues[contactCountryFieldName] = user.contactCountryCode;
                newValues[phoneNumberFieldName] = store.phoneNumber;
                newValues[contactNameFieldName] = store.contactName;
                newValues[buildingNameFieldName] = store.unitName;
                newValues[buildingUnitFieldName] = store.unitNumber;
            } else {
                newValues[contactCountryFieldName] = user.contactCountryCode;
                newValues[phoneNumberFieldName] = user.phoneNumber;
                newValues[contactNameFieldName] = `${user.firstName} ${user.lastName}`;
                newValues[buildingNameFieldName] = user.unitName;
                newValues[buildingUnitFieldName] = user.unitNumber;
            }
        } else {
            newValues[contactCountryFieldName] = user.contactCountryCode;
            newValues[contactNameFieldName] = "";
            newValues[phoneNumberFieldName] = "";
            newValues[buildingNameFieldName] = "";
            newValues[buildingUnitFieldName] = "";
        }

        newValues[contactDetailsSameAsUserFieldName] = event.target.checked;

        //set formik values for submit
        setValues(newValues);

        //update checkbox state
        setState({ ...state, [name]: event.target.checked, contactsSelectVisible: false });
    };

    // if phone numbe is not confirmed let ONLY Normal users edit the phone number field even if myDetailsChecked is true
    let phoneNumberConfirmed = currentUser.phoneNumberConfirmed;
    let phoneNumberMaskDisabled = state.myDetailsChecked;

    if (!phoneNumberConfirmed && !values[storeIdFieldName]) {
        phoneNumberMaskDisabled = false;
    }

    return (


        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
                <div className={classes.displayAddressContainer}>
                    {addressIcon}
                    <Tooltip title={values[displayAddressFieldName]}>
                        <Typography className={classes.displayAddress}>{values[displayAddressFieldName]}</Typography>
                    </Tooltip>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.gridItemPadding}>
                <FormControlLabel
                    disabled={!enableAutoComplete}
                    control={
                        <Checkbox
                            checked={state.myDetailsChecked}
                            onChange={handleCheckboxChange('myDetailsChecked')}
                            value="myDetailsChecked"
                            color="primary"
                            className={classes.checkbox}
                        />
                    }
                    labelPlacement="end"
                    classes={{ label: classes.checkboxLabel, labelPlacementStart: classes.labelPlacementStart }}
                    label={`Use ${values[storeIdFieldName] ? "the store" : "my"} contact information`}
                />



                <div className={classes.spacing} />

                <Grid container>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant='standard'
                            id={contactNameFieldName}
                            error={errors[contactNameFieldName] && touched[contactNameFieldName]}
                            success={!errors[contactNameFieldName] && touched[contactNameFieldName]}
                            fullWidth
                            inputProps={{
                                placeholder: "Contact name",
                                type: "text",
                                name: contactNameFieldName,
                                value: values[contactNameFieldName],
                                onChange: handleChange,
                                onBlur: handleBlur,
                                disabled: state.myDetailsChecked,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutlineOutlined className={classes.infoIcons} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <ErrorMessage name={contactNameFieldName}>{msg => <div className={classes.feedback}>{msg}</div>}</ErrorMessage>

                <PhoneNumberPicker
                    contactCountryFieldName={contactCountryFieldName}
                    phoneNumberFieldName={phoneNumberFieldName}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={phoneNumberMaskDisabled}
                    small={true}
                />

            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <div className={classes.detailsBox}>
                    <Typography className={classes.radioText}>Is this</Typography>

                    <RadioGroup
                        sx={{ flexFlow: "row wrap" }}
                        aria-label={addressTypeFieldName}
                        name={addressTypeFieldName}
                        className={classes.group}
                        value={values[addressTypeFieldName] ? values[addressTypeFieldName] : CONTACT_TYPE_ENUMS.RESIDENTIAL}
                        onChange={(event) => { handleAddressTypeChange(event, values, addressType) }}
                    >
                        <FormControlLabel
                            value={contactTypes[1] ? contactTypes[1].key : CONTACT_TYPE_ENUMS.RESIDENTIAL}
                            control={<Radio color="primary" className={classes.checkbox} />}
                            label={contactTypes[1] ? contactTypes[1].value : CONTACT_TYPE_ENUMS.RESIDENTIAL}
                            labelPlacement="end"
                            classes={{ label: classes.radioLabel }}
                        />
                        <FormControlLabel
                            value={contactTypes[0] ? contactTypes[0].key : CONTACT_TYPE_ENUMS.BUSINESS}
                            control={<Radio color="primary" className={classes.checkbox} />}
                            label={contactTypes[0] ? contactTypes[0].value : CONTACT_TYPE_ENUMS.BUSINESS}
                            labelPlacement="end"
                            classes={{ label: classes.radioLabel }}
                        />
                    </RadioGroup>
                </div>
                <Grid container>
                    <Grid item xs={8}>
                        <TextField
                            variant='standard'
                            id={buildingNameFieldName}
                            error={errors[buildingNameFieldName] && touched[buildingNameFieldName]}
                            success={!errors[buildingNameFieldName] && touched[buildingNameFieldName]}
                            fullWidth
                            inputProps={{
                                placeholder: values[addressTypeFieldName] === CONTACT_TYPE_ENUMS.RESIDENTIAL ? "Building" : "Company",
                                type: "text",
                                name: buildingNameFieldName,
                                value: values[buildingNameFieldName],
                                onChange: handleChange,
                                onBlur: handleBlur
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HomeWorkOutlined className={classes.infoIcons} />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant='standard'
                            id={buildingUnitFieldName}
                            error={errors[buildingUnitFieldName] && touched[buildingUnitFieldName]}
                            success={!errors[buildingUnitFieldName] && touched[buildingUnitFieldName]}
                            fullWidth
                            inputProps={{
                                placeholder: "Unit no.",
                                type: "text",
                                name: buildingUnitFieldName,
                                value: values[buildingUnitFieldName],
                                onChange: handleChange,
                                onBlur: handleBlur
                            }}

                        />
                    </Grid>
                </Grid>


                <TextField
                    variant='standard'
                    id={remarksFieldName}
                    error={errors[remarksFieldName] && touched[remarksFieldName]}
                    success={!errors[remarksFieldName] && touched[remarksFieldName]}
                    fullWidth
                    inputProps={{
                        placeholder: "Delivery remarks",
                        type: "text",
                        name: remarksFieldName,
                        value: values[remarksFieldName],
                        onChange: handleChange,
                        onBlur: handleBlur
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CommentOutlined className={classes.infoIcons} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>


    );
}

export default ContactDetails;
