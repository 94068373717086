import {
    BLACK_COLOR,
    SMALL_FONT,
    SLATE_COLOR,
    TERTIARY_COLOR,
    LARGE_FONT,
    FONT_FAMILY_HEADER,
    PRIMARY_COLOR,
    FONT_FAMILY,
    TINY_FONT,
    WHITE_COLOR,
    COMPLEMENTARY_3_COLOR
  } from "wumdrophubsreactshared/_constants/styleConstants";
  import { lighten } from "@mui/material";
  
  export default theme => ({
     deliveryStepHeader: {
        fontFamily: `${FONT_FAMILY_HEADER} !important`,
        fontWeight: "200 !important",
        fontSize: `${SMALL_FONT}px !important`,
        paddingBottom: "0px !important",
        color: `${PRIMARY_COLOR} !important`,
        fontStyle: "italic !important",
        marginBottom: "9px !important",
      },
      stepHeader: {
        fontFamily: `${FONT_FAMILY_HEADER} !important`,
        fontWeight: "bold !important",
        paddingBottom: "20px !important",
        paddingLeft: "6px !important",
        [theme.breakpoints.up("md")]: {
          display: "none !important",
        },
      },
      stepTitle: {
        color: `${TERTIARY_COLOR} !important`,
      },
      price: {
        color: `${BLACK_COLOR} !important`,
        fontSize: `${LARGE_FONT}px !important`,
        fontWeight: "bold !important",
      },
      expiryText: {
        color: `${TERTIARY_COLOR} !important`,
        fontSize: `${TINY_FONT}px !important`,
      },
      timeIcon: {
        // color: `${lighten(BLACK_COLOR, 0.1)} !important`,
        color: "#333333 !important",
        fontSize: `${LARGE_FONT}px !important`,
        marginRight: "2px !important",
        marginLeft: "2px !important",
        marginBottom: "-3px !important",
      },
      timeHours: {
        color: `${PRIMARY_COLOR} !important`,
        fontSize: `${TINY_FONT}px !important`,
        fontFamily: `${FONT_FAMILY} !important`,
      },
      timeHoursLarge: {
        color: `${PRIMARY_COLOR} !important`,
        fontSize: `${SMALL_FONT}px !important`,
        fontFamily: `${FONT_FAMILY} !important`,
      },
      timeDetailsHours: {
        minWidth: "220px !important",
        color: `${PRIMARY_COLOR} !important`,
        fontSize: `${TINY_FONT}px !important`,
        fontFamily: `${FONT_FAMILY} !important`,
      },
      deliveryRemarks: {
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `${TINY_FONT}px !important`,
      },
      slaTitle: {
        fontWeight: "600 !important",
        color: `${SLATE_COLOR} !important`,
        [theme.breakpoints.down("sm")]: {
          lineHeight: "30px !important",
        },
      },
      dateText: {
        fontSize: `${SMALL_FONT}px !important`,
        color: `${lighten(BLACK_COLOR, 0.4)} !important`,
        marginTop: "2px !important",
      },
      displayAddress: {
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
        fontSize: "0.85em !important",
        fontWeight: "600 !important",
      },
      displayAddressWrap: {
        fontSize: `${SMALL_FONT}px !important`,
        fontWeight: "600 !important",
        paddingRight: "12px !important",
      },
      displayDetailsAddressWrap: {
        fontSize: `${TINY_FONT}px !important`,
        fontWeight: "700 !important",
      },
      inputIconsColor: {
        color: `${BLACK_COLOR} !important`,
        fontSize: "17px !important",
        margin: "5px 5px -5px 0px !important",
      },
      infoIcons: {
        color: `${BLACK_COLOR} !important`,
        fontSize: "17px !important",
      },
      marginTop15: {
        marginTop: "15px !important",
      },
      textTotal: {
        color: `${BLACK_COLOR} !important`,
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `${TINY_FONT}px !important`,
        fontWeight: "100 !important",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "15px !important",
        },
      },
      textValue: {
        float: "right !important",
        [theme.breakpoints.down("xs")]: {
          paddingRight: "15px !important",
          float: "right !important",
        },
      },
      textPadding: {
        paddingLeft: "0px !important",
      },
      hideElement: {
        [theme.breakpoints.down("sm")]: {
          display: "none !important",
        },
      },
      deliveryTitle: {
        fontWeight: "bold !important",
        padding: "17px 0px 12px 9px !important",
      },
      deliverySubTitle: {
        color: `${TERTIARY_COLOR} !important`,
        fontWeight: "600 !important",
      },
      stepSubTitle: {
        fontSize: `${SMALL_FONT}px !important`,
      },
      gridItemPadding: {
        paddingRight: "45px !important",
        [theme.breakpoints.down("sm")]: {
          paddingRight: "0px !important",
        },
      },
      setGridWidthAsIs: {
        // leave class here, must be empty
      },
      maxGridWidth: {
        maxWidth: "100% !important",
        flexBasis: "100% !important",
      },
      deliveryContainer: {
          backgroundColor: `${COMPLEMENTARY_3_COLOR} !important`,
          borderRadius: "4px !important",
          padding: "16px 24px 15px 24px !important",
          minHeight: "130px !important",
          [theme.breakpoints.up('sm')]: {
              marginRight: "15px !important",
              marginLeft: "13px !important",
          },
          [theme.breakpoints.down('xs')]: {
              marginBottom: "20px !important",
              padding: "16px 24px !important",
              minHeight: "auto !important",
          }
      },
      detailContainer: {
          backgroundColor: `${WHITE_COLOR} !important`,
      },
      priceAlignment: {
          [theme.breakpoints.down('sm')]: {
              float: "left !important",
          }
      },
      deliveryInfo: {
          paddingBottom: "1px !important",
          paddingLeft: "12px !important",
      },
      displayAddressContainer: {
          display: "flex !important",
      },
      deliveryDay: {
          color: `${TERTIARY_COLOR} !important`,
          fontWeight: "600 !important",
          wordBreak: "break-word !important",
      },
      addressTitle: {
          color: `${TERTIARY_COLOR} !important`,
          fontWeight: "600 !important",
          fontSize: "13px !important",
          lineHeight: "22px !important",
      },
      timeWindowText: {
          fontSize: `${SMALL_FONT}px !important`,
          color: `${lighten(BLACK_COLOR, 0.4)} !important`,
      },
      detailsSummaryStepper: {
          paddingTop: "0px !important",
          paddingBottom: "25px !important",
      },
      twoColumn1: {
          display: "inline-block !important",
          width: "45% !important",
          verticalAlign: "top !important",
          [theme.breakpoints.down('sm')]: {
              width: "100% !important",
              display: "block !important",
          }
      },
      twoColumn2: {
          display: "inline-block !important",
          width: "55% !important",
          verticalAlign: "top !important",
          [theme.breakpoints.down('sm')]: {
              width: "100% !important",
              display: "block !important",
          }
      },
      heading: {
          fontSize: `${SMALL_FONT}px !important`,
          fontWeight: "600 !important",
          paddingBottom: "5px !important",
      },
      statusContainer: {
          backgroundColor: `${COMPLEMENTARY_3_COLOR} !important`,
          borderRadius: "4px !important",
          padding: "5px 0px 5px 0px !important",
          lineHeight: "16px !important",
          marginBottom: "15px !important",
      },
  });
  