import {
    BLACK_COLOR, FONT_FAMILY,
    PRIMARY_COLOR,
    SMALL_FONT,
    WHITE_COLOR,
    LIGHTGREY_COLOR, TINY_FONT,
    SLATE_COLOR,
    TERTIARY_COLOR,
    LARGE_FONT,
    FONT_FAMILY_HEADER,
} from "wumdrophubsreactshared/_constants/styleConstants";
  import { lighten } from "@mui/material";

export default theme => ({
     deliveryStepHeader: {
        fontFamily: `${FONT_FAMILY_HEADER} !important`,
        fontWeight: "200 !important",
        fontSize: `${SMALL_FONT}px !important`,
        paddingBottom: "0px !important",
        color: `${PRIMARY_COLOR} !important`,
        fontStyle: "italic !important",
        marginBottom: "9px !important",
      },
      stepHeader: {
        fontFamily: `${FONT_FAMILY_HEADER} !important`,
        fontWeight: "bold !important",
        paddingBottom: "20px !important",
        paddingLeft: "6px !important",
        [theme.breakpoints.up("md")]: {
          display: "none !important",
        },
      },
      stepTitle: {
        color: `${TERTIARY_COLOR} !important`,
      },
      price: {
        color: `${BLACK_COLOR} !important`,
        fontSize: `${LARGE_FONT}px !important`,
        fontWeight: "bold !important",
      },
      expiryText: {
        color: `${TERTIARY_COLOR} !important`,
        fontSize: `${TINY_FONT}px !important`,
      },
      timeIcon: {
        color: `${lighten(BLACK_COLOR, 0.1)} !important`,
        fontSize: `${LARGE_FONT}px !important`,
        marginRight: "2px !important",
        marginLeft: "2px !important",
        marginBottom: "-3px !important",
      },
      timeHours: {
        color: `${PRIMARY_COLOR} !important`,
        fontSize: `${TINY_FONT}px !important`,
        fontFamily: `${FONT_FAMILY} !important`,
      },
      timeHoursLarge: {
        color: `${PRIMARY_COLOR} !important`,
        fontSize: `${SMALL_FONT}px !important`,
        fontFamily: `${FONT_FAMILY} !important`,
      },
      timeDetailsHours: {
        minWidth: "220px !important",
        color: `${PRIMARY_COLOR} !important`,
        fontSize: `${TINY_FONT}px !important`,
        fontFamily: `${FONT_FAMILY} !important`,
      },
      deliveryRemarks: {
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `${TINY_FONT}px !important`,
      },
      slaTitle: {
        fontWeight: "600 !important",
        color: `${SLATE_COLOR} !important`,
        [theme.breakpoints.down("sm")]: {
          lineHeight: "30px !important",
        },
      },
      dateText: {
        fontSize: `${SMALL_FONT}px !important`,
        color: `${lighten(BLACK_COLOR, 0.4)} !important`,
        marginTop: "2px !important",
      },
      displayAddress: {
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
        fontSize: "0.85em !important",
        fontWeight: "600 !important",
      },
      displayAddressWrap: {
        fontSize: `${SMALL_FONT}px !important`,
        fontWeight: "600 !important",
        paddingRight: "12px !important",
      },
      displayDetailsAddressWrap: {
        fontSize: `${TINY_FONT}px !important`,
        fontWeight: "700 !important",
      },
      inputIconsColor: {
        color: `${BLACK_COLOR} !important`,
        fontSize: "17px !important",
        margin: "5px 5px -5px 0px !important",
      },
      infoIcons: {
        color: `${BLACK_COLOR} !important`,
        fontSize: "17px !important",
      },
      marginTop15: {
        marginTop: "15px !important",
      },
      textTotal: {
        color: `${BLACK_COLOR} !important`,
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `${TINY_FONT}px !important`,
        fontWeight: "100 !important",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "15px !important",
        },
      },
      textValue: {
        float: "right !important",
        color: BLACK_COLOR,
        fontSize: "14px !important",
        [theme.breakpoints.down("xs")]: {
          paddingRight: "15px !important",
          float: "right !important",
        },
      },
      textPadding: {
        paddingLeft: "0px !important",
      },
      hideElement: {
        [theme.breakpoints.down("sm")]: {
          display: "none !important",
        },
      },
      deliveryTitle: {
        fontWeight: "bold !important",
        padding: "17px 0px 12px 9px !important",
      },
      deliverySubTitle: {
        color: `${TERTIARY_COLOR} !important`,
        fontWeight: "600 !important",
      },
      stepSubTitle: {
        fontSize: `${SMALL_FONT}px !important`,
      },
      gridItemPadding: {
        paddingRight: "45px !important",
        [theme.breakpoints.down("sm")]: {
          paddingRight: "0px !important",
        },
      },
      setGridWidthAsIs: {
        // leave class here, must be empty
      },
      maxGridWidth: {
        maxWidth: "100% !important",
        flexBasis: "100% !important",
      },
    gridItem: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
    },
    defaultContainer: {
        padding: "8px !important",
        marginLeft: "-5px !important"
    },
    componentContainer: {
        border: "1px solid",
        borderColor: `${LIGHTGREY_COLOR} !important`,
        borderRadius: "4px !important",
        padding: "5px !important",
        textAlign: "left !important",
        marginBottom: "0 !important",
        [theme.breakpoints.down('xs')]: {
            padding: "21px !important",
            marginBottom: "30px !important",
        }
    },
    detailsContainer: {
        marginBottom: "0 !important",
        marginTop: "0 !important",
        padding: "8px 16px !important",
        lineHeight: "25px !important",
        [theme.breakpoints.down('xs')]: {
            padding: "0 !important",
        }
    },
    textRow: {
        width: "100% !important",
        fontFamily: FONT_FAMILY + "!important",
    },
    textKeyQty: {
        color: `${TERTIARY_COLOR} !important`,
        fontSize: SMALL_FONT + " !important",
    },
    textKey: {
        fontWeight: "400 !important",
        fontSize: TINY_FONT + " !important",
        color: `${PRIMARY_COLOR} !important`,
        paddingLeft: "6px !important"
    },
    textBold: {
        fontWeight: "bold !important",
        fontFamily: FONT_FAMILY + "!important",
    },
    textDivider: {
        margin: "14px 0 !important"
    },
    tileIcon: {
        height: "20px !important",
        marginRight: "10px !important",
        float: "left !important",
    },
    table: {
        marginTop: "0 !important",
        marginBottom: "10px !important"
    },
    numberBadge: {
        height: "20px !important",
        borderRadius: "5px !important",
        padding: "0 5px 0 5px !important",
        float: "right !important",
        fontSize: "0.9em !important",
        color: `${WHITE_COLOR} !important`,
        background: `${TERTIARY_COLOR} !important`
    },
    summaryTitle: {
        fontWeight: "bold !important",
        padding: "12px 0 0 14px !important",
        [theme.breakpoints.down('xs')]: {
            padding: "8px 16px 16px 0 !important"
        }
    },
    subheading: {
        color: `${TERTIARY_COLOR} !important`,
        fontWeight: "bold !important",
    }
});
