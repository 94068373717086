import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    progress: {
        width: "100%",
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
  }));