import React from 'react';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
// import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
// import RefreshIcon from '@mui/icons-material/Refresh'; // deprecate
import withStylesHook from 'utils/withStylesHook';
import styles from './ConnectionErrorCountdown.css';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';

class ConnectionErrorCountdown extends React.PureComponent {

  constructor(props) {
      super(props);
      let open = false;
      if(!isEmpty(this.props.open)) {
          open = this.props.open;
      }
      this.state = {
        open: open,
        seconds: 30
      };

      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
      this.startTimer();
  }

  componentWillUnmount() {
      clearInterval(this.timer);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.open !== nextProps.open) {
        this.setState({open: nextProps.open})
    }
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;

      // Check if we're at zero.
      if (seconds === -1) { 
          window.location.reload();
      } else {
          this.setState({
              seconds: seconds
          });
      }
  }

  handleRefresh = () => {
    window.location.reload();
  }

  render() {

    const { classes } = this.props;
    const { seconds } = this.state;

    return (
      <div>
          <div className={classes.loadingText}>{this.props.children} <b className={classes.seconds}>{seconds}</b>...</div>
          <div className={classes.action}>
          <IconButton className={classes.button} onClick={this.handleRefresh} aria-label="Refresh" style={{ padding: 12}}>
              <RefreshIcon className={classes.icon} />
          </IconButton>
          </div>
      </div>
    );
  }
}

ConnectionErrorCountdown.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStylesHook(styles)(ConnectionErrorCountdown);