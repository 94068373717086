import React, { Fragment, lazy } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStylesHook from "utils/withStylesHook";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from "@mui/icons-material/Menu";
import UserIcon from '@mui/icons-material/Person';
import BackIcon from '@mui/icons-material/Undo';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import useStyles from "./Header.css";
import logo from 'logo.svg';
import NewDeliveryButton from "../../common/buttons/NewDeliveryButton";
import Box from "@mui/material/Box";
import { GetEnvironmentAsync } from "devenvironmentutil";
import LoginButton from "components/common/buttons/LoginButton";
import packageJson from '../../../../package.json'; // Adjust the path if needed
import withWidthHook from 'utils/useWidth';
import { Paper } from "@mui/material";


const DrawerContent = lazy(() => import('./DrawerContent'));

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      environment: "local",
      drawerOpen: false,
      anchorEl: null
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      drawerOpen: !prevState.drawerOpen
    }));
  }

  async componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
    GetEnvironmentAsync().then((environment) => {
      this.setState({ environment });
    });
  }
  headerColorChange = () => {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }

  handleLoggedInMenuOpen = event => {
    const { auth } = this.props;
    if (auth.previousUsers.length > 0) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.props.history.push('/profile');
    }
  };

  handleSwitchToUser = (user) => {
    if (this.props.onSwitchToUser) {

      //this is the function we want to fire for rerouting on successful login
      let navigationFunction = () => window.location.reload();

      //this is the function for setting localStorage to maintain our JWT auth token
      let setLocalStorageFunction = (key, value) => localStorage.setItem(key, value);

      //this is the function for getting localStorage item
      let getLocalStorageFunction = (key) => localStorage.getItem(key);

      //this is the function for getting localStorage item
      let removeLocalStorageFunction = (key) => localStorage.removeItem(key);

      this.props.onSwitchToUser(user, navigationFunction, setLocalStorageFunction, getLocalStorageFunction, removeLocalStorageFunction);
    }
    this.setState({ anchorEl: null });
  };

  handleLoggedInMenuClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    const { anchorEl, environment } = this.state;
    const { classes, color, fixed, absolute, portal, auth, fullWidth, onOpenDeliveryWizard } = this.props;

    const appBarStyle = {
      position: absolute ? "absolute" : fixed ? "fixed" : "relative"
    };

    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fullWidth]: fullWidth
    });


    const toolBarClasses = classNames({
      [classes.container]: true,
      [classes.toolBarFullWidth]: fullWidth
    });

    const buttonClasses = classNames({
      [classes.title]: true,
      [classes.buttonFullWidth]: fullWidth
    });

    const isMenuOpen = Boolean(anchorEl);

    const renderLoggedInMenu = (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={this.handleLoggedInMenuClose}
      >
        {!isEmpty(auth.currentUser) &&
          <MenuItem key={0} className={classes.backToUserItem} onClick={() => this.props.history.push('/profile')}>
            <UserIcon className={classes.backToUserIcon} /> {auth.currentUser.user.firstName}'s profile
          </MenuItem>}
        {auth.previousUsers.map(user => {
          return <MenuItem key={user.id} className={classes.backToUserItem} onClick={() => this.handleSwitchToUser(user)}>
            <BackIcon className={classes.backToUserIcon} /> Back to {user.email}
          </MenuItem>
        })
        }

      </Menu>
    );

    return (
      <div>
        <AppBar sx={appBarStyle} className={appBarClasses}>
          <Toolbar className={toolBarClasses}>
            <Button className={buttonClasses}>
              <Link className={classes.logoLink} to='/'>
                <img src={logo} alt="logo" width="126" height="33" />
                {!isEmpty(portal.data.flag) ? <span className={classes.flag}>{portal.data.cca2}</span> : ""}
              </Link>
            </Button>
            {!environment.toLocaleLowerCase().includes("production") && <div className={`${classes.platformBtn}`}>{`${environment}`}</div>}
            <Hidden mdDown>
              {
                !fullWidth &&
                <div style={{ width: '100%' }}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/pricing" >Pricing</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/businesses" >For businesses</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/drivers" >For drivers</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/developers" >For developers</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/track-order" >Track my order</Button>
                    </Box>
                  </Box>
                </div>
              }
            </Hidden>

            <Fragment>
              <div style={{ flex: 1 }} />
              <Hidden mdDown>
                {!auth.isAuthenticated ?
                  <LoginButton />
                  :
                  <NewDeliveryButton fullWidth={false} onOpenDeliveryWizard={onOpenDeliveryWizard} auth={auth} />
                }
              </Hidden>
              {!auth.isAuthenticated ?
                <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                :
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              }

              <Drawer
                anchor="right"
                open={this.state.drawerOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                <DrawerContent handleLoggedInMenuOpen={this.handleLoggedInMenuOpen} onOpenDeliveryWizard={onOpenDeliveryWizard} fullWidth={fullWidth} />
                <Paper elevation={3} className={classes.version}>version <span style={{ fontSize: 10 }}>{packageJson.version}</span></Paper>
              </Drawer>
            </Fragment>
          </Toolbar>
        </AppBar>
        {auth.impersonating && renderLoggedInMenu}
      </div>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  fullWidth: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired
  })
};

export default withStylesHook(useStyles)(withWidthHook(Header));
