import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import authTypesReducer from './authTypesReducer';
import autoAssignStatusesReducer from './autoAssignStatusesReducer';
import applicationInvitesReducer from './applicationInvitesReducer';
import applicationStatusesReducer from './applicationStatusesReducer';
import documentStatusesReducer from './documentStatusesReducer';
import navReducer from './navReducer';
import territoriesReducer from './territoriesReducer';
import usersReducer from './usersReducer';
import companiesReducer from './companiesReducer';
import contactTypesReducer from './contactTypesReducer';
import contactsReducer from './contactsReducer';
import deliveriesReducer from './deliveriesReducer';
import jobsReducer from './jobsReducer';
import jobStatusesReducer from './jobStatusesReducer';
import tasksReducer from './tasksReducer';
import taskStatusesReducer from './taskStatusesReducer';
import taskTypesReducer from './taskTypesReducer';
import vehiclesReducer from './vehiclesReducer';
import rolesReducer from './rolesReducer';
import accountTypesReducer from './accountTypesReducer';
import companyTypesReducer from './companyTypesReducer';
import companyPricingTierReducer from './companyPricingTierReducer';
import vehicleTypesReducer from './vehicleTypesReducer';
import deliveryItemsReducer from './deliveryItemsReducer';
import deliveryStatusesReducer from './deliveryStatusesReducer';
import paymentStatusesReducer from './paymentStatusesReducer';
import operationStatusesReducer from './operationStatusesReducer';
import companiesSelectReducer from './companiesSelectReducer';
import hubsReducer from './hubsReducer';
import hubTypesReducer from './hubTypesReducer';
import territoriesSelectReducer from './territoriesSelectReducer';
import addressesReducer from './addressesReducer';
import territoriesAreasReducer from './territoriesAreasReducer';
import territoriesExceptionReducer from './territoriesExceptionsReducer';
import areaReducer from './areaReducer';
import ratesReducer from './ratesReducer';
import rateTypesReducer from './rateTypesReducer';
import slaTypesReducer from './slaTypesReducer';
import handlingTypeReducer from './handlingTypeReducer';
import dayOfWeekTypesReducer from './dayOfWeekTypesReducer';
import parcelTypesReducer from './parcelTypesReducer';
import parcelTypesSelectReducer from './parcelTypesSelectReducer';
import quotesReducer from './quotesReducer';
import dashboardDeliveryStatusGroupsReducer from './dashboardDeliveryStatusGroupsReducer';
import dashboardDeliverySortsReducer from './dashboardDeliverySortsReducer';
import dashboardJobStatusGroupsReducer from './dashboardJobStatusGroupsReducer';
import dashboardJobSortsReducer from './dashboardJobSortsReducer';
import partnerApplicationsWorkflowReducer from './partnerApplicationsWorkflowReducer';
import businessApplicationsWorkflowReducer from './businessApplicationsWorkflowReducer';
import jobsWorkflowReducer from './jobsWorkflowReducer';
import tasksWorkflowReducer from './tasksWorkflowReducer';
import dashboardDriverStatusGroupsReducer from './dashboardDriverStatusGroupsReducer';
import dashboardDriverSortsReducer from './dashboardDriverSortsReducer';
import storesReducer from './storesReducer';
import storesSelectReducer from './storesSelectReducer';
import signalRSubscriptionsReducer from './signalRSubscriptionsReducer';
import portalReducer from './portalReducer';
import timeZonesReducer from './timeZonesReducer';
import terminalReducer from './terminalReducer';
import countriesReducer from './countriesReducer';
import accountsReducer from './accountsReducer';
import accountsSelectReducer from './accountsSelectReducer';
import transactionsReducer from './transactionsReducer';
import transactionItemsReducer from './transactionItemsReducer';
import deliveryWindowsReducer from './deliveryWindowsReducer';
import noteMessageTypeCategoriesReducer from './noteMessageTypeCategoriesReducer';
import noteMessageTypesReducer from './noteMessageTypesReducer';
import fundsReceivedReducer from './fundsReceivedReducer';
import fundsAllocationReducer from './fundsAllocationReducer';
import fundsReceivedTypesReducer from './fundsReceivedTypesReducer';
import fundsReceivedStatusesReducer from './fundsReceivedStatusesReducer';
import transactionTypesReducer from './transactionTypesReducer';
import transactionStatusesReducer from './transactionStatusesReducer';
import banksReducer from './banksReducer';
import bankAccountsReducer from './bankAccountsReducer';
import bankAccountTypesReducer from './bankAccountTypesReducer';
import withdrawalsReducer from './withdrawalsReducer';
import deliveryDateTypesReducer from './deliveryDateTypesReducer';
import jobDateTypesReducer from './jobDateTypesReducer';
import notesReducer from './notesReducer';
import jobDetailsReducer from './jobDetailsReducer';
import jobCostReducer from './jobCostReducer';
import trackingReducer from './trackingReducer';
import uploadBatchesReducer from './uploadBatchesReducer';
import deliveryUploadsReducer from './deliveryUploadsReducer';
import logisticsStatusUploadsReducer from './logisticsStatusUploadsReducer';
import storeUploadsReducer from './storeUploadsReducer';
import uploadDistanceBatchesReducer from './uploadDistanceBatchesReducer';
import distanceUploadsReducer from './distanceUploadsReducer';
import uploadStatusesReducer from './uploadStatusesReducer';
import webhooksReducer from './webhooksReducer';
import webhookStatusesReducer from './webhookStatusesReducer';
import webhookTypesReducer from './webhookTypesReducer';
import ratingsReducer from './ratingsReducer';
import announcementsReducer from './announcementsReducer';
import trainingStatusesReducer from './trainingStatusesReducer';
import mobileDevicesReducer from './mobileDevicesReducer';
import notificationDevicesReducer from './notificationDevicesReducer';
import logisticStatusesAllowedReducer from './logisticStatusesAllowedReducer';
import emailsReducer from './emailsReducer';
import configsReducer from './configsReducer';
import communicationTypesReducer from './communicationTypesReducer';
import questionnairesReducer from './questionnairesReducer';
import qsrDriverTypesReducer from './qsrDriverTypesReducer';
import rateUploadsReducer from './rateUploadsReducer';

/* application vetting */
import businessApplicationsReducer from './businessApplicationsReducer';
import driverApplicationsReducer from './driverApplicationsReducer';
import assistantApplicationsReducer from './assistantApplicationsReducer';
import partnerApplicationsReducer from './partnerApplicationsReducer';
import vehicleApplicationsReducer from './vehicleApplicationsReducer';

import reportCustomerDeliveriesReducer from './reportCustomerDeliveriesReducer';
import reportDeliveryExceptionsReducer from './reportDeliveryExceptionsReducer';
import reportDistanceStatisticsReducer from './reportDistanceStatisticsReducer';
import reportDriverStatisticsReducer from './reportDriverStatisticsReducer';
import reportParcelStatisticsReducer from './reportParcelStatisticsReducer';
import reportServiceLevelsReducer from './reportServiceLevelsReducer';
import reportDriverPerformanceReducer from './reportDriverPerformanceReducer';
import reportDeliverySummaryReducer from './reportDeliverySummaryReducer';
import reportGrossProfitsReducer from './reportGrossProfitsReducer';
import reportDeliveryTerminationReducer from './reportDeliveryTerminationReducer';
import reportDeliveryArrivalReducer from './reportDeliveryArrivalReducer';
import reportDriverAutoAssignEfficiencyReducer from './reportDriverAutoAssignEfficiencyReducer';
import reportDriverScheduleReducer from './reportDriverScheduleReducer';
import reportDriverAnalysisReducer from './reportDriverAnalysisReducer';
import reportHubDeliveryMovementReducer from './reportHubDeliveryMovementReducer';

import archiveCustomerDeliveriesReducer from './archiveCustomerDeliveriesReducer';
import archivePartnerDeliveriesReducer from './archivePartnerDeliveriesReducer';
import archivePartnerJobsReducer from './archivePartnerJobsReducer';

import scheduledReportReducer from './scheduledReportReducer';
import scheduledReportTypesReducer from './scheduledReportTypesReducer';
import scheduledReportPeriodsReducer from './scheduledReportPeriodsReducer';
import statisticsReducer from './statisticsReducer';

import webNotificationReducer from './webNotificationReducer';
import assistantsReducer from './assistantsReducer';
import driverSchedulesReducer from './driverSchedulesReducer';
import driverLocationsReducer from './driverLocationsReducer';
import driverRoutesReducer from './driverRoutesReducer';
import parcelBarcodesReducer from './parcelBarcodesReducer';
import supersetDashboardsReducer from './supersetDashboardsReducer';
import hubDeliveriesReducer from './hubDeliveriesReducer';
import userSessionsReducer from './userSessionsReducer';
import companyCommunicationsReducer from './companyCommunicationsReducer';
import paymentCardsReducer from './paymentCardsReducer';
import togglesReducer from './togglesReducer';
import specialDriverReducer from './specialDriverReducer';
import userActionNotesReducer from './userActionNotesReducer';
const reducers = {
    accounts: accountsReducer,
    accountsSelect: accountsSelectReducer,
    accountTypes: accountTypesReducer,
    addresses: addressesReducer,
    announcements: announcementsReducer,
    applicationInvites: applicationInvitesReducer,
    applicationStatuses: applicationStatusesReducer,
    area: areaReducer,
    auth: authReducer,
    authTypes: authTypesReducer,
    archiveCustomerDeliveries: archiveCustomerDeliveriesReducer,
    archivePartnerDeliveries: archivePartnerDeliveriesReducer,
    archivePartnerJobs: archivePartnerJobsReducer,
    assistantApplications: assistantApplicationsReducer,
    assistants: assistantsReducer,
    autoAssignStatuses: autoAssignStatusesReducer,

    banks: banksReducer,
    bankAccounts: bankAccountsReducer,
    bankAccountTypes: bankAccountTypesReducer,
    businessApplications: businessApplicationsReducer,
    businessApplicationsWorkflow: businessApplicationsWorkflowReducer,

    communicationTypes: communicationTypesReducer,
    companies: companiesReducer,
    companiesSelect: companiesSelectReducer,
    companyTypes: companyTypesReducer,
    companyPricingTiers: companyPricingTierReducer,
    companyCommunications: companyCommunicationsReducer,
    contacts: contactsReducer,
    contactTypes: contactTypesReducer,
    countries: countriesReducer,
    configs: configsReducer,

    dashboardDeliverySorts: dashboardDeliverySortsReducer,
    dashboardDeliveryStatusGroups: dashboardDeliveryStatusGroupsReducer,
    dashboardDriverStatusGroups: dashboardDriverStatusGroupsReducer,
    dashboardDriverSorts: dashboardDriverSortsReducer,
    dashboardJobStatusGroups: dashboardJobStatusGroupsReducer,
    dashboardJobSorts: dashboardJobSortsReducer,
    dayOfWeekTypes: dayOfWeekTypesReducer,
    deliveries: deliveriesReducer,
    deliveryItems: deliveryItemsReducer,
    deliveryDateTypes: deliveryDateTypesReducer,
    deliveryStatuses: deliveryStatusesReducer,
    deliveryUploads: deliveryUploadsReducer,
    logisticsStatusUploads: logisticsStatusUploadsReducer,
    storeUploads: storeUploadsReducer,
    deliveryWindows: deliveryWindowsReducer,
    distanceUploads: distanceUploadsReducer,
    documentStatuses: documentStatusesReducer,
    driverApplications: driverApplicationsReducer,
    driverSchedules: driverSchedulesReducer,
    driverLocations: driverLocationsReducer,
    rateUploads: rateUploadsReducer,

    emails: emailsReducer,
    errors: errorReducer,

    fundsReceived: fundsReceivedReducer,
    fundsReceivedTypes: fundsReceivedTypesReducer,
    fundsReceivedStatuses: fundsReceivedStatusesReducer,
    fundsAllocation: fundsAllocationReducer,

    hubs: hubsReducer,
    hubDeliveries: hubDeliveriesReducer,
    hubTypes: hubTypesReducer,
    handlingType : handlingTypeReducer,
    
    jobs: jobsReducer,
    jobStatuses: jobStatusesReducer,
    jobDateTypes: jobDateTypesReducer,
    jobsWorkflow: jobsWorkflowReducer,
    jobDetails: jobDetailsReducer,
    jobCost: jobCostReducer,

    logisticStatusesAllowed: logisticStatusesAllowedReducer,

    mobileDevices: mobileDevicesReducer,

    nav: navReducer,
    notes: notesReducer,
    noteMessageTypeCategories: noteMessageTypeCategoriesReducer,
    noteMessageTypes: noteMessageTypesReducer,
    notificationDevices: notificationDevicesReducer,

    operationStatuses: operationStatusesReducer,

    parcelTypes: parcelTypesReducer,
    parcelTypesSelect: parcelTypesSelectReducer,
    paymentStatuses: paymentStatusesReducer,
    portal: portalReducer,
    partnerApplications: partnerApplicationsReducer,
    partnerApplicationsWorkflow: partnerApplicationsWorkflowReducer,
    parcelBarcodes: parcelBarcodesReducer,
    paymentCards: paymentCardsReducer,

    qsrDriverTypes: qsrDriverTypesReducer,
    quotes: quotesReducer,
    questionnaires: questionnairesReducer,

    rates: ratesReducer,
    rateTypes: rateTypesReducer,
    ratings: ratingsReducer,
    roles: rolesReducer,
    reportCustomerDeliveries: reportCustomerDeliveriesReducer,
    reportDeliveryExceptions: reportDeliveryExceptionsReducer,
    reportDistanceStatistics: reportDistanceStatisticsReducer,
    reportDriverStatistics: reportDriverStatisticsReducer,
    reportParcelStatistics: reportParcelStatisticsReducer,
    reportServiceLevels: reportServiceLevelsReducer,
    reportGrossProfits: reportGrossProfitsReducer,
    reportDriverPerformance: reportDriverPerformanceReducer,
    reportDeliverySummary: reportDeliverySummaryReducer,
    reportDeliveryTermination: reportDeliveryTerminationReducer,
    reportDeliveryArrival: reportDeliveryArrivalReducer,
    reportDriverAutoAssignEff: reportDriverAutoAssignEfficiencyReducer,
    reportDriverSchedule: reportDriverScheduleReducer,
    reportDriverAnalysis: reportDriverAnalysisReducer,
    reportHubDeliveryMovement: reportHubDeliveryMovementReducer,

    scheduledReports: scheduledReportReducer,
    scheduledReportTypes: scheduledReportTypesReducer,
    scheduledReportPeriods: scheduledReportPeriodsReducer,
    signalRSubscriptions: signalRSubscriptionsReducer,
    slaTypes: slaTypesReducer,
    stores: storesReducer,
    storesSelect: storesSelectReducer,
    superset: supersetDashboardsReducer,
    statistics: statisticsReducer,

    terminal: terminalReducer,
    tasks: tasksReducer,
    taskStatuses: taskStatusesReducer,
    taskTypes: taskTypesReducer,
    tasksWorkflow: tasksWorkflowReducer,
    territories: territoriesReducer,
    territoriesSelect: territoriesSelectReducer,
    territoriesAreas: territoriesAreasReducer,
    territoriesExceptions: territoriesExceptionReducer,
    timeZones: timeZonesReducer,
    tracking: trackingReducer,
    trainingStatuses: trainingStatusesReducer,
    transactions: transactionsReducer,
    transactionItems: transactionItemsReducer,
    transactionTypes: transactionTypesReducer,
    transactionStatuses: transactionStatusesReducer,
    toggles: togglesReducer,

    users: usersReducer,
    userSessions: userSessionsReducer,
    userActionNotes : userActionNotesReducer,
    uploadBatches: uploadBatchesReducer,
    uploadDistanceBatches: uploadDistanceBatchesReducer,
    uploadStatuses: uploadStatusesReducer,

    vehicles: vehiclesReducer,
    vehicleTypes: vehicleTypesReducer,
    vehicleApplications: vehicleApplicationsReducer,

    withdrawals: withdrawalsReducer,
    webhooks: webhooksReducer,
    webhookTypes: webhookTypesReducer,
    webhookStatuses: webhookStatusesReducer,

    webNotifications: webNotificationReducer,
    driverRoutes: driverRoutesReducer,
    specialDrivers:specialDriverReducer
}

const rootReducers = combineReducers(reducers);

export default rootReducers;