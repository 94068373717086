import { SECONDARY_COLOR, WHITE_COLOR, FONT_FAMILY } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  buttonContainer: {
    position: "absolute !important",
    zIndex: "1500 !important",
    marginTop: "10px !important",
    left: "43% !important",
  },
  button: {
    margin: "0px !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    top: "10px !important",
    fontFamily: FONT_FAMILY + " !important",
    backgroundColor: SECONDARY_COLOR + " !important",
    '&:hover': {
      background: lighten(SECONDARY_COLOR, 0.2) + " !important"
    },
    padding: "2px 10px !important",
  },
  leftIcon: {
    marginRight: theme.spacing(1) + "px !important",
    fontSize: "20px !important",
  },
  rightIcon: {
    marginLeft: theme.spacing(1) + "px !important",
    fontSize: "20px !important",
  },
  iconSmall: {
    fontSize: "20px !important",
  },
  progress: {
    width: "18px !important",
    height: "18px !important",
    float: "right !important",
    marginLeft: theme.spacing(1) + "px !important",
    color: WHITE_COLOR + " !important",
  }
}));
