import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import { makeStyles } from '@mui/styles';
import { CircularProgress, lighten } from '@mui/material';
import { CALL_TO_ACTION_GREEN_COLOR, DARK_GREEN_COLOR, PRIMARY_COLOR, WHITE_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

function ConfirmationDialog({ confirmDisabled, open, onCloseClick, onConfirmClick, title, children, loading, subtitle, fullWidth, maxWidth }) {
  const classes = useStyles();

  function handleConfirm() {
    onConfirmClick();
    if (isEmpty(loading))
      onCloseClick();
  }

  let styleFullWidthTrue = {width:"calc(100% - 64px)",maxWidth: "600px"}
  let styleFullWidthFalse = { width: "350px",maxWidth: "calc(100% - 64px)"}
  return (
    <div>
      <Dialog
        sx={{ '& .MuiDialog-paper':  fullWidth ? styleFullWidthTrue : styleFullWidthFalse }}
        open={open}
        onClose={onCloseClick}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        fullWidth={!isEmpty(fullWidth) ? fullWidth : false}
        maxWidth={!isEmpty(maxWidth) ? maxWidth : false}
      >
        <DialogTitle className={classes.title}>{title} <span className={classes.subtitle}><br />{subtitle}</span></DialogTitle>

        <DialogContent className={classes.content}>
          {children}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button fullWidth color="primary" onClick={onCloseClick} variant="contained">
            No
          </Button>
          <Button className={classes.btnYes} sx={{backgroundColor:confirmDisabled ? "rgb(68, 68, 68)":`${CALL_TO_ACTION_GREEN_COLOR}`}} fullWidth onClick={handleConfirm} disabled={isEmpty(confirmDisabled) ? loading : confirmDisabled} variant="contained" >
            {loading ? <CircularProgress size={25} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles({
  dialog: {
    minWidth: "350 !important"
  },
  title: {
    color: `${WHITE_COLOR} !important`,
    background: `${PRIMARY_COLOR} !important`,
    fontWeight: "bold",
    textAlign: "center !important",
    padding: "20px !important"
  },
  content: {
    marginTop: "25px !important"
  },
  subtitle: {

  },
  actions: {
    justifyContent: "center !important",
    textAlign: "center !important",
    padding: "25px !important"
  },
  btnYes: {
    '&:hover': {
      background: `${lighten(DARK_GREEN_COLOR, 0.2)} !important`
    }
  }
});

export default ConfirmationDialog;