import React from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';
import { DARK_GREEN_COLOR, SMALL_FONT, WHITE_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

const useStyles = makeStyles({
  goButton: {
    backgroundColor: `${DARK_GREEN_COLOR} !important`,
    textTransform: 'none !important',
    '&:hover': {
        background: `${lighten(DARK_GREEN_COLOR, 0.2)} !important`
    },
    color: `${WHITE_COLOR} !important`,
    fontSize: `${SMALL_FONT}px !important`,
    maxWidth: '200px !important',
    paddingRight: '30px !important',
    paddingLeft: '30px !important',
    marginRight: '12px !important',
    whiteSpace: 'nowrap !important',
},
buttonIcon: {
    marginRight: '30px !important',
    color: 'white !important',
},

});


const NewDeliveryButton = ({ fullWidth, onOpenDeliveryWizard, auth }) => {

  const classes = useStyles();
  let canBook = hasPermission(auth, { isNormalUser: true });
  return (
    <Button fullWidth={fullWidth} disabled={canBook !== true} variant="contained" className={classes.goButton} onClick={onOpenDeliveryWizard}>
      New delivery
    </Button>
  );
}

NewDeliveryButton.propTypes = {
  auth: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  onOpenDeliveryWizard: PropTypes.func.isRequired
};

export default NewDeliveryButton;