import {
    PRIMARY_COLOR,
    WHITE_COLOR,
    TERTIARY_COLOR,
    LIGHT_GREY_COLOR, COMPLEMENTARY_2_COLOR, SLATE_COLOR, CALL_TO_ACTION_GREEN_COLOR
} from "../../wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    root: {
        width: '100% !important',
        height: '100% !important'
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`,
    },
    completed: {
        display: 'inline-block !important',
    },
    instructions: {
        marginTop: `${theme.spacing(1)} !important`,
        marginBottom: `${theme.spacing(1)} !important`,
    },
    stepButton: {
        //backgroundColor: PRIMARY_COLOR
    },
    stepIcon: {
        color: `${PRIMARY_COLOR} !important`
    },
    stepIconActive: {
        color: `${TERTIARY_COLOR} !important`
    },
    stepper: {
        paddingTop: "20px !important",
        paddingBottom: "20px !important",
        paddingLeft: "0px !important",
        paddingRight: "15px !important",
        [theme.breakpoints.down('sm')]: {
            display: 'none !important'
       }
    },
    stepHorizontal: {
        paddingLeft: "0px !important"
    },
    mobileStepper: {
        bottom: "0px !important",
        left: "0px !important",
        position: "absolute !important",
        width: "92.5% !important",
        padding: "15px !important",
        background: "white !important",
        color: SLATE_COLOR + " !important",
        borderTop: "1px solid !important",
        borderColor: LIGHT_GREY_COLOR + " !important",
        [theme.breakpoints.up('sm')]: {
            width: "96.5% !important",
        }
    },
    progress: {
        width: "100% !important",
        marginTop: "50px !important",
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    bottomButton: {
        color: PRIMARY_COLOR + " !important",
        backgroundColor: WHITE_COLOR + " !important",
        textTransform: "capitalize !important",
        marginRight: "12px !important",
        fontSize: "16px !important",
        '&:hover': {
            backgroundColor: lighten(LIGHT_GREY_COLOR, 0.45) + " !important",
        },
    },
    bottomButtonGreen: {
        color: WHITE_COLOR + " !important",
        backgroundColor: CALL_TO_ACTION_GREEN_COLOR + " !important",
        textTransform: "capitalize !important",
        fontSize: "16px !important",
        paddingLeft: "16px !important",
        '&:hover': {
            background: lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2) + " !important"
        }
    },
    barColor: {
        backgroundColor: PRIMARY_COLOR + " !important",
    },
    barColorBackground: {
        backgroundColor: COMPLEMENTARY_2_COLOR + " !important",
        [theme.breakpoints.down('xs')]: {
            marginRight: "8px !important",
        }
    },
    summaryContent: {
        paddingTop: "20px !important",
        paddingBottom: "20px !important",
        paddingLeft: "5px !important",
        paddingRight: "25px !important",
    },
    centerAlign: {
        width: "50px !important",
        height: "auto !important",
        margin: "20% auto !important",
        padding: "10px !important"
    },
    buttonSpacer: {
        width: "82px !important",
        height: "36px !important"
    },
}));
