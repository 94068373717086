import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor, 
  drawerWidth,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import {
  SECONDARY_COLOR,
  FONT_FAMILY,
  APP_BAR_HEIGHT,
  WHITE_COLOR, TINY_FONT, TERTIARY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    boxShadow: "none !important",
    marginBottom: "0px !important",
  },
  toolBarFullWidth: {
    "@media (min-width: 576px)": {
      maxWidth: "none !important"
    },
    "@media (min-width: 768px)": {
      maxWidth: "none !important"
    },
    "@media (min-width: 992px)": {
      maxWidth: "none !important"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "none !important"
    }
  },
  buttonFullWidth: {
    paddingTop: "6px !important",
    paddingBottom: "0px !important"
  },
  appBar: {
    display: "flex",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px !important",
    borderColor: "#edf1f5 !important",
    boxShadow: "none !important",
    padding: "0.625rem 0 !important",
    marginBottom: "20px",
    color: grayColor[15] + " !important",
    width: "100% !important",
    backgroundColor: whiteColor + " !important",
    transition: "all 150ms ease 0s !important",
    alignItems: "center !important",
    flexFlow: "row nowrap !important",
    justifyContent: "flex-start !important",
    height: `${APP_BAR_HEIGHT}px !important`,
    paddingRight: "0px !important"
  },  
  absolute: {
    top: "auto !important"
  },

  container: {
    ...container,
    minHeight: "50px !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    display: "flex !important",
    flexWrap: "nowrap !important"
  },
  title: {
    "&,& a": {
      ...defaultFont,
      minWidth: "unset !important",
      lineHeight: "30px !important",
      fontSize: "18px !important",
      borderRadius: "3px !important",
      textTransform: "none !important",
      whiteSpace: "nowrap !important",
      color: "inherit !important",
      height: "30px !important",        
      padding: '3px 0 !important',
      "&:hover,&:focus": {
        color: "inherit !important",
        background: "transparent !important"
      }
    }
  },
  appResponsive: {
    margin: "20px 0px !important",
    marginTop: "0px !important"
  },
  primary: {
    backgroundColor: primaryColor[0] + " !important",
    color: whiteColor + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.46) !important"
  },
  info: {
    backgroundColor: infoColor[0] + " !important",
    color: whiteColor + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.46) !important"
  },
  success: {
    backgroundColor: successColor[0] + " !important",
    color: whiteColor + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.46) !important"
  },
  warning: {
    backgroundColor: warningColor[0] + " !important",
    color: whiteColor + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.46) !important"
  },
  danger: {
    backgroundColor: dangerColor[0] + " !important",
    color: whiteColor + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.46) !important"
  },
  rose: {
    backgroundColor: roseColor[0] + " !important",
    color: whiteColor + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.46) !important"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
    paddingTop: "25px !important",
    color: whiteColor + " !important"
  },
  dark: {
    color: whiteColor + " !important",
    backgroundColor: grayColor[9] + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46) !important"
  },
  white: {
    border: "0 !important",
    padding: "0.625rem 0 !important",
    color: grayColor[15] + " !important",
    backgroundColor: whiteColor + " !important",
    borderBottomStyle: "solid !important",
    borderBottomWidth: "1px !important",
    borderColor: "#edf1f5 !important",
    boxShadow: "0 4px 20px rgba(16,19,20,.04) !important"
  },
  drawerPaper: {
     width: `${drawerWidth}px !important`,
    fontFamily: FONT_FAMILY + " !important",
    backgroundColor: 'rgb(242, 242, 242) !important',
    overflowX: "hidden !important"
  },
  logoLink: {
    textDecoration: 'none !important',
    backgroundColor: 'transparent !important',
    color: `${grayColor[15]} !important`,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },  
  flag: {
    position: 'relative !important',
    left: '5px !important',
    top: '-15px !important',
    fontSize: '0.7em !important',
    fontWeight: 'normal !important',
    backgroundColor: "transparent !important",
  },
  backToUserItem: {
    fontSize: "13px !important",
    lineHeight: "13px !important",
    height: "50px !important"
  },
  backToUserIcon: {
    fontSize: "16px !important",
    marginRight: "5px !important"
  },
  platformBtn: {
    color: WHITE_COLOR + " !important",
    fontSize: `${TINY_FONT}px !important`,
    padding: '6px 9px !important',
    borderBottomLeftRadius: '4px !important',
    borderBottomRightRadius: '4px !important',
    zIndex: 3000,
    position: 'absolute !important',
    marginTop: '-9px !important',
    top: "0px !important",
    left: "185px !important",
    backgroundColor: SECONDARY_COLOR + " !important"
  },
  menuItemsBar: {
    backgroundColor: "transparent !important",
    marginLeft: "10px !important",
    paddingTop: "15px !important",
    color: grayColor[15] + " !important",
    fontFamily: FONT_FAMILY + " !important",
    textTransform: 'none !important',
    '&.MuiButton-root, &.MuiButton-text':{
        '&:hover,&:focus': {
            backgroundColor: "transparent !important",
            color: TERTIARY_COLOR + " !important"
        }
    },
    '& .MuiTypography-body1': {
        fontSize: '0.885rem !important',
    },
  },
version:{ position: "fixed", bottom: 10, right : 10, fontSize: 12, backgroundColor: 'white', paddingRight : 5,paddingLeft : 5, borderRadius: 5}
}));

export default useStyles;
