import {SECONDARY_COLOR} from '../../wumdrophubsreactshared/_constants/styleConstants';
import { darken } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    appBar: {
        position: 'absolute !important',
        backgroundColor: `${SECONDARY_COLOR} !important`,
        paddingLeft:  `${theme.spacing(1)} !important`,
        borderTop: `1px solid ${darken(SECONDARY_COLOR, 0.1)}`
    }
}));