//persistent sidebar types
export const LEFT_PERSISTENT_SIDEBAR = "LEFT_PERSISTENT_SIDEBAR";
export const RIGHT_PERSISTENT_SIDEBAR = "RIGHT_PERSISTENT_SIDEBAR";

//tab panel types
export const DASHBOARD_LEFT_PANEL_TABS = "DASHBOARD_LEFT_PANEL_TABS";
export const DASHBOARD_RIGHT_PANEL_TABS = "DASHBOARD_RIGHT_PANEL_TABS";

//entity types
export const ACCOUNTS = "accounts";
export const TERRITORIES = "territories";
export const TRANSACTIONS = "transactions";
export const TRANSACTION_ITEMS = "transaction-items";
export const FUNDS_RECEIVED = "funds-received";
export const BANK_ACCOUNTS = "bank-accounts";
export const WITHDRAWALS = "withdrawals";
export const ADVANCES = "advances";
export const RATINGS = "ratings";
export const ANNOUNCEMENTS = "announcements";
export const NOTIFICATION_DEVICES = "notification-devices";
export const MOBILE_DEVICES = "mobile-devices";
export const QUESTIONNAIRES = "questionnaires";
export const HUBS = "hubs";

export const ALLOCATION = "allocation";
export const USERS = "users";
export const ELASTICUSERS = "elasticusers";
export const COMPANIES = "companies";
export const DELIVERIES = "deliveries";
export const UPLOAD_BATCHES = "batches";
export const UPLOAD_DISTANCE_BATCHES = "distancebatches";
export const STORE_UPLOAD = "uploads";
export const DELIVERY_UPLOAD = "uploads";
export const LOGISTICAL_STATUS_UPLOAD = "uploads";
export const STORE_BATCH_UPLOAD = "Store";
export const DELIVERY_BATCH_UPLOAD = "Delivery";
export const LOGISTICS_STATUS_BATCH_UPLOAD = "LogisticsStatus";
export const STORE_BATCH_UPLOAD_URL = "/stores/batches/add";
export const DELIVERY_BATCH_UPLOAD_URL = "/stores/:id/batches";
export const LOGISTICS_STATUS_BATCH_UPLOAD_URL = "/logisticsStatus/batches/add";
export const JOBS = "jobs";
export const TASKS = "tasks";
export const RATE_UPLOAD = "uploads";
export const RATE_BATCH_UPLOAD = "Rate";
export const RATE_BATCH_UPLOAD_URL = "/rates/batches/add"
export const VEHICLES = "vehicles";
export const WEBHOOKS = "webhooks";
export const WEBHOOKAUTHS = "webhookauths";
export const SCHEDULEDREPORTS = "scheduledreports";
export const ADDRESSES = "addresses";
export const AREAS = "areas";
export const RATES = "rates";
export const ASSISTANTS = "assistants"; //technically a sub category of users. we use this type on assistant views
export const DRIVERS = "drivers"; //technically a sub category of users. we use this type on driver views
export const STORES = "stores";
export const DASHBOARD_CONTACTS = "elasticcontacts";
export const TERRITORIES_AREAS = "territory-areas";
export const TERRITORIES_EXCEPTIONS = "territory-exceptions";
export const ACCOUNT_TRANSACTIONS = "account-transactions";
export const PICK_UP = "PICK_UP";
export const DROP_OFF = "DROP_OFF";
export const EXCEPTIONS = "exceptions";
export const LOGISTIC_STATUSES = "logisticstatuses";
export const SLA_TYPES = "slatypes";
export const DRIVER_SCHEDULE = "driver-schedules";
export const PARCEL_TYPES = "parceltypes";
export const RATE_TYPES = "ratetypes";
export const COMPANY_PRICING_TIERS = "companyPricingTiers";
export const DAY_OF_WEEK_TYPES = "dayofweektypes";

export const REPORT_CUSTOMER_DELIVERIES = "report-customer-deliveries";
export const REPORT_DELIVERY_EXCEPTIONS = "report-delivery-exceptions";
export const REPORT_DRIVER_STATISTICS = "report-driver-stats";
export const REPORT_DISTANCE_STATISTICS = "report-distance-stats";
export const REPORT_PARCEL_STATISTICS = "report-parcel-stats";
export const REPORT_GROSS_PROFITS = "report-gross-profits";
export const REPORT_SERVICE_LEVELS = "report-service-levels";
export const REPORT_KPI_PERFORMANCE = "report-KPI-performance";
export const REPORT_DELIVERY_SUMMARY = "report-delivery-summary";
export const REPORT_DELIVERY_TERMINATION = "report-delivery-termination";
export const REPORT_DELIVERY_ARRIVAL = "report-delivery-arrival";
export const REPORT_DRIVER_AUTO_ASSIGN_EFF = 'report-driver-auto-assign-efficiency';
export const REPORT_DRIVER_SCHEDULE = "report-driver-schedule";
export const REPORT_DRIVER_ANALYSIS = "report-driver-analysis";
export const REPORT_DRIVER_ANALYSIS_NAME = "driver-assignment,-acceptance-and-compliance-metrics ";
export const REPORT_DRIVER_ROUTES = "report-driver-routes";
export const REPORT_HUB_DELIVERIES = "report-hub-deliveries";


export const DASHBOARD_AUTO_ASSIGN = "auto-assign-report";
export const DASHBOARD_DRIVER_SCHEDULE = "dashboard-driver-schedule";

export const ARCHIVE_CUSTOMER_DELIVERIES = "archive-customer-deliveries";
export const ARCHIVE_PARTNER_DELIVERIES = "archive-partner-deliveries";
export const ARCHIVE_PARTNER_JOBS = "archive-partner-jobs";


// job task panel types
export const JOB_TASK_TYPES = {
    INCOMPLETE: 'incomplete',
    COMPLETED: 'completed'
}

// applications views
export const BUSINESS_APPLICATIONS = "business-applications";
export const PARTNER_APPLICATIONS = "partner-applications";
export const DRIVER_APPLICATIONS = "driver-applications";
export const ASSISTANT_APPLICATIONS = "assistant-applications";
export const VEHICLE_APPLICATIONS = "vehicle-applications";
export const BANK_ACCOUNT_APPLICATIONS = "bank-account-applications";

//label types
export const LABEL_YES = "LABEL_YES";
export const LABEL_NO = "LABEL_NO";
export const LABEL_OD = "LABEL_OD";
export const LABEL_SD = "LABEL_SD";
export const LABEL_GREY = "LABEL_GREY";
export const LABEL_PAYMENT_STATUS = "LABEL_PAYMENT_STATUS";
export const LABEL_DELIVERY_STATUS = "LABEL_DELIVERY_STATUS";

//form types
export const FORM_CREATE = "CREATE";
export const FORM_EDIT = "EDIT";

//sla types
export const STANDARD = "Standard";
export const PRIORITY = "Priority";
export const HYPERLOCAL = "Hyperlocal";
export const EMERGENCY_RELIEF = "Emergency Relief";

//payment status types
export const PAYMENT_SUCCESS = "payment-success";
export const PAYMENT_FAILED = "payment-failed";

//select types for dashboard filter bar
export const SELECT_FILTER = "SELECT_FILTER";
export const SELECT_SORT = "SELECT_SORT";

//select types for navbar on dashboard
export const SELECT_REGION = "SELECT_REGION";
export const SELECT_DATE = "SELECT_DATE";

//phone number regex
export const PHONE_REG_EXP = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

//superset dashboards
export const FINANCE_METRICS_REPORT = "finance-metrics-report";
export const DELIVERY_DUE_REPORT = "delivery-due-report";
export const ADDRESS_DISCREPANCY_REPORT = "address-discrepancy-report";
export const ADDRESS_VARIATION_REPORT = "address-variation-report"; 
export const DRIVER_PAY_ANALYSIS = "driver-pay-analysis-report"; 
export const DRIVER_WAIT_TIME = "driver-wait-time-report"; 
export const MASSMART_EXCEPTIONS_REPORT = "massmart-exceptions-report"; 
export const AUTO_DRIVER_ASSIGNMENT = "auto-driver-assignment";
export const AMENDMENT_REPORT = "amendment-report";
export const LOCATION_PROXIMITY_REPORT = "location_proximity_report";
export const DRIVER_ASSIGNMENT_AUDIT = "driver-assignment-audit";
export const ACCEPT_AND_COMPLIENCE = "accept-and-compliance";