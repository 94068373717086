import { makeStyles } from '@mui/styles';
import {
    CALL_TO_ACTION_GREEN_COLOR, COMPLEMENTARY_5_COLOR,
    DARK_GREEN_COLOR,
    ERROR_COLOR, FONT_FAMILY, LIGHT_BLUE,
    MEDIUM_FONT, MEDIUM_GREY_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, SMALL_FONT, TERTIARY_COLOR,
    TINY_FONT,
    WHITE_COLOR
} from '../../../wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from "@mui/material";

export default makeStyles(theme => ({
    select: {
        fontFamily: `${FONT_FAMILY} !important`,
    },
    label:{
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `${SMALL_FONT}px !important`,
        "&.Mui-focused":{
            color:`${MEDIUM_GREY_COLOR} !important`
        }
   },
    field: {
        marginLeft: `${theme.spacing(1)} !important`,
        marginRight: `${theme.spacing(1)} !important`,
        width: '100%',
        maxWidth: '90%',
        marginBottom: `${theme.spacing(2)} !important`,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '95%',
        }
    },
    isActiveField: {
        //marginLeft: theme.spacing(1),
    },
    isExclusionField: {
        //marginLeft: theme.spacing(1),
    },
    isHighRiskField: {
        //marginLeft: theme.spacing(1),
    },
    activeSpacing: {
        marginBottom: "5px !important",
    },
    selectField: {
        width: '100%',
        maxWidth: '90%',
        marginBottom: `${theme.spacing(1)} !important`,
        marginTop: "0px !important",
        marginLeft: "5px !important",
        [theme.breakpoints.down('xs')]: {
            maxWidth: '95%',
        }
    },
    subField: {
        marginBottom: "22px !important",
    },
    errorMessage: {
        color: `${ERROR_COLOR} !important`,
        marginBottom: `${theme.spacing(1/2)} !important`,
    },
    errorContainer: {
        margin: `${theme.spacing(1)} !important`,
        paddingBottom: `${theme.spacing(2)} !important`,
    },
    requiredText: {
        color: `${ERROR_COLOR} !important`,
        fontSize: "12px !important",
        marginTop: "5px !important",
    },
    successMessage: {
        color: `${DARK_GREEN_COLOR} !important`,
        margin: `${theme.spacing(1)} !important`,
        paddingBottom: `${theme.spacing(2)} !important`,
    },
    //New
    actions: {
        display: "flex !important",
        justifyContent: "flex-end !important",
        paddingTop: `${theme.spacing(2)} !important`,
    },
    feedback: {
        color: "red !important",
        fontSize: "12px !important",
    },
    group: {
        flexDirection: "row !important",
        marginLeft: "3px !important",
        marginBottom: "5px !important",
    },
    button: {
        '&:focus': {
            outline: 'none !important',
        },
        //marginTop: theme.spacing(2),
        marginLeft: `${theme.spacing(2)} !important`,
    },
    actionsBar: {
        margin: '19px 7px !important',
    },
    actionsBarCompany: {
        margin: '0px 7px 40px 0px !important',
    },
    submitButton: {
        textTransform: 'capitalize !important',
       fontSize: `${MEDIUM_FONT}px !important`,
        color: `${WHITE_COLOR} !important`,
        backgroundColor: `${CALL_TO_ACTION_GREEN_COLOR} !important`,
        paddingRight: "25px !important",
        paddingLeft: "25px !important",
        '&:hover': {
            background: `${lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2)} !important`,
        },
    },
    cancelButton: {
        background: `${PRIMARY_COLOR} !important`,
        textTransform: 'capitalize !important',
       fontSize: `${MEDIUM_FONT}px !important`,
        color: WHITE_COLOR,
        marginRight: "10px !important",
        marginLeft: "4px !important",
        '&:hover': {
            background: `${lighten(PRIMARY_COLOR, 0.2)} !important`,
        },
    },
    greenStatus: {
        background: `${lighten(CALL_TO_ACTION_GREEN_COLOR, 0.8)} !important`,
    },
    blueStatus: {
        background: `${lighten(COMPLEMENTARY_5_COLOR, 0.8)} !important`,
    },
    lightblueStatus: {
        background: `${lighten(LIGHT_BLUE, 0.6)} !important`,
    },
    orangeStatus: {
        background: `${lighten(TERTIARY_COLOR, 0.9)} !important`,
    },
    redStatus: {
        background: `${lighten(SECONDARY_COLOR, 0.9)} !important`,
    },
    rightButton: {
        marginTop: "-12px !important",
        marginRight: "-12px !important",
        float: "right !important",
    },
    outlinedButton: {
        textTransform: "capitalize !important",
       fontSize: `${MEDIUM_FONT}px !important`,
        border: '1px solid black',
        padding: '5px 15px',
        float: "right",
        marginBottom: "15px !important",
    },
    dropzoneParagraph: {
        fontSize: `${SMALL_FONT}px !important`,
        fontFamily:`${FONT_FAMILY} !important`,
        fontWeight: "400 !important",
        lineHeight: "1.334 !important",
    },
    invisible: {
        display: 'none !important',
    },
    ////////////
    paper: {
        position: 'relative !important',
        //zIndex: 0, // Set the z-index to a lower value
        marginBottom: "20px !important",
        padding: "20px !important",
    },
    saveFab: {
        color: `${WHITE_COLOR} !important`,
        backgroundColor: `${DARK_GREEN_COLOR} !important`,
        float: "right !important",
    },
    alertTitle: {
        fontSize: "0.675rem !important",
        fontWeight: "400 !important"
    },
    alertRoot: {
        // marginTop: 5,
        // marginBottom: 5,
        marginLeft: "0px !important"
    },
    alertStatus:{
        fontSize: `${TINY_FONT}px !important`,
        paddingBottom: "4px !important"
    },
    overlay: {
        position: 'absolute !important',
        top: "0px !important",
        left: "0px !important",
        width: '100% !important',
        height: '100% !important',
        backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
        zIndex: "1 !important",
        display: 'flex !important',
        flexDirection: "column !important",
        alignItems: 'center !important',
        justifyContent: 'center !important',
        borderRadius: "4px !important",
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
        },
    },
    elementWrapper: {
        '& > *': {
            marginTop: `${theme.spacing(2)} !important`,
        },
    },
}));

