import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { PRIMARY_COLOR, TERTIARY_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';
import { ChevronRight } from '@mui/icons-material';


const useStyles = makeStyles({
    button: {
        textTransform: 'none !important',
        marginTop: '8px !important',
        width: 250,
        '&.MuiButton-root, &.MuiButton-text':{
            '&:hover,&:focus': {
                backgroundColor: "transparent",
                color: TERTIARY_COLOR
            }
        }
    },
    login: {
        color: PRIMARY_COLOR,
        marginRight: 10,
        fontWeight: 600,
        '&:hover,&:focus': {
            backgroundColor: "transparent",
            color: TERTIARY_COLOR
        }
    },
    signup: {
        fontWeight: 600,
        color: TERTIARY_COLOR,
    },
    icon: {
        color: TERTIARY_COLOR,
        marginRight: "3px !important"
    }
});

const LoginButton = () => {
    const classes = useStyles();
    return (
        <Button component={Link} to="/login" className={classes.button}>
            <span className={classes.login}>Log in</span>
            <span className={classes.signup}>Sign up</span>
            <ChevronRight className={classes.icon} />
        </Button>
    );
};

export default LoginButton;