/* eslint-disable no-unused-vars */
import {
    GET_USERS,
    CLEAR_USERS,
    CLEAR_USERS_SUCCESS,
    PUT_USERS,
    DELETE_USERS,
    POST_USERS,
    GET_USERS_BY_ID,
    UPDATE_USERS,
    NEW_USERS,
    GET_USERS_AGGREGATIONS,
    GENERATE_USER_API,
    SET_LOADING_USERS,
    SYNC_USER,
    VERIFY_USER,
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    success: null,
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case VERIFY_USER:
            return {
                ...state,
                data: {
                    ...action.payload,
                    isVerified: action.payload
                },
                loading: false
            }
        case GET_USERS: //here we key items by id for O(1) lookup/update/delete from redux
            return {
                ...state,
                data: {
                    ...action.payload, items: action.payload.items.reduce((obj, item) => {
                        obj[`#${item.id}`] = item; //here we key on #id so as not to lose ordering from backend
                        return obj;
                    }, {})
                },
                search: !isEmpty(action.search) ? action.search : "",
                loading: false
            }
        case GET_USERS_BY_ID:
            return {
                ...state,
                data: action.payload,
                search: action.search,
                loading: false
            }
        case GET_USERS_AGGREGATIONS:
            return {
                ...state,
                data: {
                    ...state.data,
                    aggregationDictionary: action.payload.aggregationDictionary,
                    totalItems: action.payload.totalItems,
                    updateAggregations: false
                }
            }
        case PUT_USERS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} user`,
                loading: false
            }
        case POST_USERS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} user`,
                loading: false
            }
        case DELETE_USERS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} users.`,
                loading: false
            }
        case SYNC_USER:
            return {
                ...state,
                success: `Successfully synced user`,
                loading: false
            }
        case SET_LOADING_USERS:
            return {
                ...state,
                loading: action.payload
            }
        case CLEAR_USERS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_USERS:
            return {
                ...state,
                data: {},
                success: ''
            }
        // case UPDATE_DRIVER_LOCATIONS:
        //     //here action.payload is hash map (i.e., Map()) of key = driverId, value = coordinates
        //     //we loop through our hashmap and update the locations that have changed in the driver list
        //     let drivers = Object.assign({}, state.data.items);
        //     for (const [key, value] of action.payload.entries()) {
        //         if (drivers[key]) {
        //             drivers[key].lastKnownLocation = value;
        //         }
        //     }

        //     return {
        //         ...state,
        //         data: { ...state.data, items: drivers }
        //     }
        case UPDATE_USERS: //this is for updating users in redux with data from signalR
            //here action.payload is hash map (i.e., Map()) of key = userId, value = user object
            let users = Object.assign({}, state.data.items);

            let existingUsers = [];
            if (!isEmpty(state.data.existing)) {
                existingUsers = [...state.data.existing];
            }

            for (const [key, value] of action.payload.entries()) {
                if (users[key]) {
                    users[key] = value;
                } else if (isEmpty(state.data.existing) || !state.data.existing.includes(key)) {
                    if (isEmpty(state.data.new) || !state.data.new.includes(key))
                        existingUsers.push(key);
                }
            }

            return {
                ...state,
                data: { ...state.data, items: users, existing: existingUsers, updateAggregations: true }
            }

        case NEW_USERS: //this is for adding new user message in redux with data from signalR
            //here action.payload is hash set (i.e., Set()) of key = jobId

            let newUsers = [];
            if (!isEmpty(state.data.new)) {
                newUsers = [...state.data.new];
            }

            for (const value of action.payload.values()) {
                newUsers.push(value);
            }

            return {
                ...state,
                data: { ...state.data, new: newUsers, updateAggregations: true }
            }
        case GENERATE_USER_API:
            let user = Object.assign({}, state.data);
            user.publicApiKeyOne = action.payload.publicApiKeyOne;
            user.publicApiKeyTwo = action.payload.publicApiKeyTwo;
            return {
                ...state,
                data: user,
                success: `${PUT_SUCCESS} user`
            }
        default:
            return state;
    }
}