import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {
    LIGHTGREY_COLOR,
    SECONDARY_COLOR,
    PRIMARY_COLOR
} from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    feedback: {
        ...feedback
    },
    deliveryMap: {
        height: '215px !important',
        margin: '5px 0px 10px 0px !important',
        border: `1px solid ${LIGHTGREY_COLOR} !important`,
    },
    storesSwitch: {
        marginTop: '5px !important',
    },
    button: {
        padding: '9px !important',
        marginTop: '4px !important',
        marginLeft: '-5px !important',
    },
    buttonChecked: {
        padding: '9px !important',
        marginTop: '4px !important',
        color: `${SECONDARY_COLOR} !important`,
        marginLeft: '-5px !important',
    },
    leadControl: {
        margin: '0px 0px 15px 0px !important',
        position: 'relative !important',
        padding: '0px !important',
    },
    collectOnDeliveryRoot: {
        marginTop: '-8px !important',
    },
    collectOnDeliveryLabel: {
        fontSize: '14px !important',
        color: `${PRIMARY_COLOR} !important`,
    },
    collectPlacement: {
        marginLeft: '2px !important',
    },
    collectChecked: {
        color: `${PRIMARY_COLOR} !important`,
    },
    datePickerAdmin: {
        marginBottom: '10px !important',
    },
}));
