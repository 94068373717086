import React from 'react';
import { ICON_STATE_ENUMS, TASK_TYPE_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';
import convertToNumberingScheme from 'wumdrophubsreactshared/_utils/convertToNumberingScheme';
import storePickUp from 'wumdrophubsreactshared/_resources/images/map-stores/store-pick-up.svg';
import storePickUpAssigned from 'wumdrophubsreactshared/_resources/images/map-stores/store-pick-up-assigned.svg';
import storePickUpCompleted from 'wumdrophubsreactshared/_resources/images/map-stores/store-pick-up-completed.svg';
import pickUpNormal from 'wumdrophubsreactshared/_resources/images/map-markers/pick-up-normal.svg';
import pickUpAssigned from 'wumdrophubsreactshared/_resources/images/map-markers/pick-up-assigned.svg';
import pickUpCompleted from 'wumdrophubsreactshared/_resources/images/map-markers/pick-up-completed.svg';
import storeDropOff from 'wumdrophubsreactshared/_resources/images/map-stores/store-drop-off.svg';
import storeDropOffAssigned from 'wumdrophubsreactshared/_resources/images/map-stores/store-drop-off-assigned.svg';
import storeDropOffCompleted from 'wumdrophubsreactshared/_resources/images/map-stores/store-drop-off-completed.svg';
import dropOffNormal from 'wumdrophubsreactshared/_resources/images/map-markers/drop-off-normal.svg';
import dropOffAssigned from 'wumdrophubsreactshared/_resources/images/map-markers/drop-off-assigned.svg';
import dropOffCompleted from 'wumdrophubsreactshared/_resources/images/map-markers/drop-off-completed.svg';

const getMarkerIcon = ({ taskTypeId, isStore, isHub, index = 0, iconState = 0,style={},handleOnClick = () => {} }) => {
    let icon = <></>;
    const isAlphabet = (char) => /^[A-Za-z]$/.test(char);
    let letter = isAlphabet(index) ? index : convertToNumberingScheme(index + 1);
    if (taskTypeId === TASK_TYPE_ENUMS.PICK_UP_FROM_ORIGIN) {
        if (isHub) {
            icon = <>{letter}</>
        } else if (isStore) {
            if (iconState === ICON_STATE_ENUMS.ASSIGNED) {
                icon = storePickUpAssigned;
            } else if (iconState === ICON_STATE_ENUMS.COMPLETED) {
                icon = storePickUpCompleted;
            } else {
                icon = storePickUp;
            }
        } else {
            if (iconState === ICON_STATE_ENUMS.ASSIGNED) {
                icon = pickUpAssigned;
            } else if (iconState === ICON_STATE_ENUMS.COMPLETED) {
                icon = pickUpCompleted;
            } else {
                icon = pickUpNormal;
            }
        }
    } else if (taskTypeId === TASK_TYPE_ENUMS.DROP_OFF_AT_DESTINATION) {
        if (isStore) {
            if (iconState === ICON_STATE_ENUMS.ASSIGNED) {
                icon = storeDropOffAssigned;
            } else if (iconState === ICON_STATE_ENUMS.COMPLETED) {
                icon = storeDropOffCompleted;
            } else {
                icon = storeDropOff;
            }
        } else {
            if (iconState === ICON_STATE_ENUMS.ASSIGNED) {
                icon = dropOffAssigned;
            } else if (iconState === ICON_STATE_ENUMS.COMPLETED) {
                icon = dropOffCompleted;
            } else {
                icon = dropOffNormal;
            }
        }
    }


    return (
        <div
            onClick={handleOnClick}
            style={{
                position: 'relative',
                display: 'inline-block',
                width: 26,
                height: 32,
                textAlign: 'center',
                color: '#fff',
                verticalAlign: "top",
                ...style
            }}
        >
            {icon && (
                <img
                    src={icon}
                    alt="Marker Icon"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                />
            )}
            <span style={{
                position: 'absolute',
                top: '45%',
                left: '52%',
                transform: 'translate(-50%, -50%)',
                fontSize: '14px', // Adjust font size as needed
            }}>
                {letter}
            </span>
        </div>
    );
}

export default getMarkerIcon;
