import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import mapQouteToSlaTypes from 'wumdrophubsreactshared/_utils/mapQouteToSlaTypes';
import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import { changeTimeSlot } from 'wumdrophubsreactshared/_actions/quotes'
import { STANDARD, PRIORITY, HYPERLOCAL, EMERGENCY_RELIEF } from 'wumdrophubsreactshared/_constants/typeConstants';
import { Formik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import * as yup from 'yup';


import withStylesHook from 'utils/withStylesHook';
import styles from './QuoteStep.css';
import { Typography, Grid } from '@mui/material';
import {Radio} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import localiseCurrency from 'wumdrophubsreactshared/_utils/localiseCurrency';
import convertUtcToLocalDate from 'wumdrophubsreactshared/_utils/convertUtcToLocalDate';
import convertUtcToLocalTime from 'wumdrophubsreactshared/_utils/convertUtcToLocalTime';
import { DEFAULT_TIMEZONE } from 'wumdrophubsreactshared/_constants/apiConstants';
import getCountDownTimeUtc from 'wumdrophubsreactshared/_utils/getCountdownTime';
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";
import SlaCard from "./SlaCard";
import TimePickerSelect from '../../common/pickers/TimePickerSelect';
import { Alert } from "@mui/material";

//Formik and Yup Validation
const validationSchema = yup.object().shape({
    quoteId: yup.number().min(1, "Delivery option is required").required("Delivery option is required")
});

class QuoteStep extends Component {

    constructor(props) {
        super(props);

        let timeZone = "Africa/Johannesburg";
        if (!isEmpty(this.props.portal.data) && !isEmpty(this.props.portal.data.timeZone)) {
            timeZone = this.props.portal.data.timeZone;
        }

        let quoteTypes = mapQouteToSlaTypes(this.props.quoteResponse, timeZone);
        let selectedIndex = quoteTypes.slaTypes.findIndex(quote => quote.id === quoteTypes.initialValues.quoteId);

        this.state = {
            refreshProps: false,
            hasServerError: false,
            initialValues: quoteTypes.initialValues,
            slaTypes: quoteTypes.slaTypes,
            selectedIndex: selectedIndex
        };

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (!isEqual(this.props.quoteResponse, nextProps.quoteResponse)) {
            const { selectedIndex } = this.state;
            let timeZone = "Africa/Johannesburg";
            if (!isEmpty(this.props.portal.data) && !isEmpty(this.props.portal.data.timeZone)) {
                timeZone = this.props.portal.data.timeZone;
            }

            let quoteTypes = mapQouteToSlaTypes(nextProps.quoteResponse, timeZone);

            if (selectedIndex && quoteTypes.slaTypes[selectedIndex])
                quoteTypes.initialValues.quoteId = quoteTypes.slaTypes[selectedIndex].id;

            this.setState({ slaTypes: quoteTypes.slaTypes, initialValues: quoteTypes.initialValues });
        }
    }

    startTimer() {
        if (this.timer === 0) {
            this.timer = setInterval(this.countDown, 30000); //update expiration date every 30 seconds
        }
    }

    countDown() {
        //update new expiration countdowns and set state so a re-render happens.
        const { slaTypes } = this.state;

        let updatedSlaTypes = [...slaTypes];

        for (let i = 0; i < updatedSlaTypes.length; i++) {
            updatedSlaTypes[i].expirationCountdown = getCountDownTimeUtc(updatedSlaTypes[i].quoteExpirationUtc);
        }
        this.setState({ slaTypes: updatedSlaTypes });
    }

    handleClick = (index) => {
        const { slaTypes, initialValues } = this.state;

        let quoteId = slaTypes[index].id;
        let deliveryId = initialValues.deliveryId;

        let newValues = { ...initialValues };

        newValues.deliveryId = deliveryId;
        newValues.quoteId = quoteId;

        this.setState({ selectedIndex: index, initialValues: newValues });
    };

    handleFormikSubmit(values) {
        this.props.clearErrors();
        this.props.handleStepSubmit(values);
    }

    buildSelectedOrderText(timeZone) {
        const { selectedIndex, slaTypes } = this.state;

        let selectedOrderText = '';

        if (!isEmpty(slaTypes[selectedIndex])) {
            let deliveryDate = convertUtcToLocalDate(slaTypes[selectedIndex].pickupWindowStartUtc, timeZone);

            let dropOffStartTime = convertUtcToLocalTime(slaTypes[selectedIndex].dropOffWindowStartUtc, timeZone);
            let dropOffEndTime = convertUtcToLocalTime(slaTypes[selectedIndex].dropOffWindowEndUtc, timeZone);

            deliveryDate = this.formatDateText(deliveryDate);
            selectedOrderText = `Your order will be delivered ${deliveryDate} between ${dropOffStartTime} and ${dropOffEndTime}.`;
        }

        return selectedOrderText;
    }

    formatDateText(deliveryDate) {
        let dateText = '';

        if (deliveryDate !== "Tomorrow" && deliveryDate !== "Today" && deliveryDate !== "Yesterday") {
            dateText = `on ${deliveryDate}`;
        } else {
            dateText = deliveryDate.toLowerCase();
        }

        return dateText;
    }

    handleChangeOrderClick = () => {
        if (this.props.onChangeOrderClick) {
            this.props.onChangeOrderClick();
        }
    }

    handleTimeChange = (selected) => {
        const { initialValues } = this.state;
        let deliveryId = initialValues.deliveryId;
        let deliveryTime = initialValues.deliveryTime;

        return new Promise((resolve, reject) => {
            if (deliveryTime !== selected) {
                this.setState({ timeLoading: true }, function () {
                    this.props.changeTimeSlot(deliveryId, selected.value).then(() => {
                        resolve();
                    });
                })
            }
            else {
                resolve();
            }
        });
    }

    render() {
        const {
            auth,
            classes,
            portal,
            wizardRef,
            loading,
            quoteResponse } = this.props;

        const {
            slaTypes,
            initialValues,
            selectedIndex } = this.state;

        let timeZone = portal.data.timeZone ? portal.data.timeZone : DEFAULT_TIMEZONE;


        let portalCode = "";
        if (!isEmpty(portal) && !isEmpty(portal.data)) {
            portalCode = portal.data.cca2
        }

        return (

            <div>
                {loading ?
                    <div className={classes.progress}>
                        <CircularProgress />
                    </div> :
                    <Formik
                        onSubmit={values => this.handleFormikSubmit(values)}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({ handleSubmit, values, errors, handleBlur }) => (
                            <form onSubmit={handleSubmit} id={`${wizardRef}3`} noValidate>
                                <Typography className={classes.deliveryStepHeader}>
                                    Select your preferred quote
                                </Typography>
                                {selectedIndex === -1 &&
                                    (!isEmpty(errors) &&
                                        <Alert severity="error">
                                            Please select your preferred quote to continue.
                                        </Alert>)
                                }

                                <List component="nav">
                                    {!isEmpty(slaTypes) && slaTypes.map((quote, index) => {
                                        let isHighRisk = this.props.quoteResponse.isHighRisk;
                                        let isRoundTrip = this.props.quoteResponse.isRoundTrip;
                                        let charge = isRoundTrip === true ? 2 : 1;
                                        let priceIncl = (quote.priceExcl + (quote.priceExcl * quote.vatRate)) * charge;
                                        let totalQuotePrice = localiseCurrency(priceIncl, portal.currency, portal.cca2);

                                        //set explanatory text
                                        let deliveryDateText = "";
                                        let slaTypeLabel = "";
                                        if (quote.slaType === STANDARD) {
                                            slaTypeLabel = <Typography className={classes.slaTitle}>Standard <span className={classes.dot}>•</span> <span className={classes.slaSubtext}>Flexible</span></Typography>;
                                        } else if (quote.slaType === PRIORITY) {
                                            slaTypeLabel = <Typography className={classes.slaTitle}>Priority <span className={classes.dot}>•</span> <span className={classes.slaSubtext}>Precise</span></Typography>;
                                        } else if (quote.slaType === HYPERLOCAL) {
                                            slaTypeLabel = <Typography className={classes.slaTitle}>Hyperlocal <span className={classes.dot}>•</span> <span className={classes.slaSubtext}>Near</span></Typography>;
                                        } else if (quote.slaType === EMERGENCY_RELIEF) {
                                            slaTypeLabel = <Typography className={classes.slaTitle}>Emergency Relief <span className={classes.dot}>•</span> <span className={classes.slaSubtext}>Free</span></Typography>;
                                        }

                                        let quoteDeliveryDatePairValue = {};
                                        if (!isEmpty(quote.pickupWindowStartUtc)) {
                                            quoteDeliveryDatePairValue.key = convertUtcToLocalDate(quote.pickupWindowStartUtc, portal.data.timeZone, false);
                                            quoteDeliveryDatePairValue.value = convertUtcToLocalDate(quote.pickupWindowStartUtc, portal.data.timeZone, true);
                                        }

                                        let stepDeliveryTime = {};
                                        if (!isEmpty(initialValues.deliveryTime)) {
                                            stepDeliveryTime.key = initialValues.deliveryTime;
                                            stepDeliveryTime.value = initialValues.deliveryTime;
                                        }

                                        let deliveryDate = this.formatDateText(convertUtcToLocalDate(quote.pickupWindowStartUtc, timeZone));
                                        let pickUpStartTime = convertUtcToLocalTime(quote.pickupWindowStartUtc, timeZone);
                                        let pickUpEndTime = convertUtcToLocalTime(quote.pickupWindowEndUtc, timeZone);

                                        let dropOffStartTime = convertUtcToLocalTime(quote.dropOffWindowStartUtc, timeZone);
                                        let dropOffEndTime = convertUtcToLocalTime(quote.dropOffWindowEndUtc, timeZone);

                                        deliveryDateText = <Typography className={classes.dateText}> Delivered <span className={classes.deliveryDate}>{deliveryDate}</span></Typography>;

                                        let expirationText = '';
                                        let isDisabled = false;
                                        if (!isEmpty(quote.expirationCountdown)) {
                                            if (quote.expirationCountdown === 'Expired') {
                                                //if the quote is expired, we disable it
                                                isDisabled = true;
                                                expirationText = quote.expirationCountdown;
                                            } else {
                                                expirationText = `Expires in ${quote.expirationCountdown}`
                                            }
                                        }

                                        let selectOrderText = this.buildSelectedOrderText(timeZone);

                                        return (
                                            <div key={index}>
                                                <ListItem
                                                    button
                                                    selected={selectedIndex === index}
                                                    onClick={(e) => this.handleClick(index)}
                                                    value={values.quoteId}
                                                    classes={{ selected: classes.selectedText }}
                                                    className={classes.listItem}
                                                    key={index + "li"}
                                                    disabled={isDisabled}
                                                >
                                                    <ListItemIcon key={index + "i"} className={classes.radioContainer}>
                                                        <Radio
                                                            checked={selectedIndex === index}
                                                            className={classes.radioButton}
                                                            color="primary"
                                                            value={index}
                                                            name={quote.slaType}
                                                            aria-label={quote.slaType}
                                                            key={index + "r"}
                                                        />
                                                    </ListItemIcon>
                                                    <Grid container>
                                                        {
                                                            quote.slaType === PRIORITY && !isEmpty(selectedIndex) && selectedIndex === index ?
                                                                <Grid item xs={12}>
                                                                    <Grid container className={classes.itemContainer}>
                                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                                            <Typography className={`${classes.slaTitle} ${classes.titleAlignment}`}>Schedule this priority for:</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.timePickerContainer}>
                                                                            <TimePickerSelect
                                                                                fieldName={"deliveryTime"}
                                                                                id={"deliveryTime"}
                                                                                label="First available timeslot"
                                                                                onSelect={this.handleTimeChange}
                                                                                apiEntity={`DeliveryWindows/GetAvailableTimeSlots/${portalCode}/1`} // NB! you need to supply the name of the entity to search on in the API
                                                                                multiSelect={false}
                                                                                date={quoteDeliveryDatePairValue}
                                                                                value={!isEmpty(stepDeliveryTime) ? stepDeliveryTime : null}
                                                                                placeholder={"First available timeslot"}
                                                                                handleBlur={handleBlur}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                                :
                                                                ""
                                                        }
                                                        <Grid item xs={12}>
                                                            <SlaCard
                                                                auth={auth}
                                                                selectOrderText={selectOrderText}
                                                                slaTypeLabel={slaTypeLabel}
                                                                deliveryDateText={deliveryDateText}
                                                                index={index}
                                                                isHighRisk={isHighRisk}
                                                                isRoundTrip={isRoundTrip}
                                                                selectedIndex={selectedIndex}
                                                                pickUpStartTime={pickUpStartTime}
                                                                pickUpEndTime={pickUpEndTime}
                                                                dropOffStartTime={dropOffStartTime}
                                                                dropOffEndTime={dropOffEndTime}
                                                                totalQuotePrice={totalQuotePrice}
                                                                expirationText={expirationText}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </div>
                                        )
                                    })}

                                </List>
                                {
                                    quoteResponse.isRoundTrip &&
                                    <Typography className={classes.doubleCharge} variant="body1" style={{ paddingBottom: '12px' }}>
                                        * Subject to collect on delivery double charge
                                    </Typography>
                                }

                                {
                                    quoteResponse.isHighRisk &&
                                    <Typography className={classes.highRisk} variant="body1" style={{ paddingBottom: '12px' }}>
                                        * Subject to surcharges
                                    </Typography>
                                }

                            </form>
                        )}
                    </Formik>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    serverErrors: state.errors,
    portal: state.portal
})

const combinedStyles = combineStyles(styles, appStyles);

export default compose(
    withStylesHook(combinedStyles),
    withRouter,
    connect(mapStateToProps, { clearErrors, changeTimeSlot })
)(QuoteStep);