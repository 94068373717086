import {
    MEDIUM_FONT,
    DARK_GREY_COLOR,
    LIGHT_GREY_COLOR,
    BLACK_COLOR,
    TINY_FONT
} from 'wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    gridRoot: {
        flexGrow: "1 !important",
        marginTop: `${theme.spacing(0.5)} !important`,
        fontSize: `${TINY_FONT}px !important`
    },
    dialogContent: {
        padding: "0 20px 0px !important",
        marginBottom: "62px !important",
        [theme.breakpoints.down('sm')]: {
            marginTop: "40px !important",
            padding: "0 16px 0px !important",
            overflowY: 'scroll !important',
        }
    },
    progress: {
        marginTop: `${theme.spacing(5)} !important`,
        marginBottom: `${theme.spacing(5)} !important`
    },
    cardTitle: {
        color: `${DARK_GREY_COLOR} !important`,
        fontSize: `${MEDIUM_FONT}px !important`
    },
    cardHeader: {
        backgroundColor: `${lighten(LIGHT_GREY_COLOR, 0.25)} !important`,
    },
    dialogPaper: {
        width: "100% !important",
        maxWidth: "780px !important",
        minHeight: "540px !important",
        [theme.breakpoints.up('md')]: {
            maxHeight: "600px !important"
        },
        overflowX: "hidden !important"
    },
    closeButton: {
        padding: "10px !important",
        position: "absolute !important",
        right: "0 !important",
        top: "0 !important",
        [theme.breakpoints.up('md')]: {
            padding: "8px !important"
        }
    },
    closeIcon: {
        width: "0.9em !important",
        height: "0.9em !important",
        color: `${lighten(BLACK_COLOR, 0.45)} !important`
    }
}));
