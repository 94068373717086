import React, { Fragment, Component } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import localiseCurrency from 'wumdrophubsreactshared/_utils/localiseCurrency';
import { ButtonGroup, Button, Typography, TextField, Tooltip, Grid, Paper } from '@mui/material';
import ConfirmationDialog from './dialogs/ConfirmationDialog';
import { PAYMENT_STATUS_ENUMS, TRANSACTION_ITEM_TYPE_ENUMS, TRANSACTION_ITEM_TYPE_ID_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';
import { getQuoteDetails } from 'wumdrophubsreactshared/_actions/quotes';
import { getJobDetails, getJobDetailsTransactionItems } from 'wumdrophubsreactshared/_actions/jobDetails';
import { createJobTransactionItem, createDeliveryTransactionItem, clearTransactionItemsSuccess } from 'wumdrophubsreactshared/_actions/transactionItems';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import { Warning, TrendingDown, TrendingUp } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import SimpleDialog from './dialogs/SimpleDialog';
import isEqual from 'react-fast-compare';
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';

const initialValues = {
    transactionId: "",
    deliveryId: "",
    jobId: "",
    amount: "",
    description: "",
    isCredit: false,
    transactionItemTypeId: null
}

//Formik and Yup Validation
const validationSchema = yup.object().shape({
    amount: yup.number().required("Amount excl is required").positive("Amount excl cannot be less than 0"),
    description: yup.string().required("Reason is required")
});

class DebitAndCredit extends Component {

    constructor(props) {
        super(props);

        let defaultValues = this.getDefaultValues();
        this.state = {
            initialValues: defaultValues,
            transactionOpen: false,
            confirmOpen: false,
            loading: false
        };
    }

    handleTransactionOpen = () => this.setState({ transactionOpen: true });
    handleTransactionClose = () => this.setState({ transactionOpen: false });

    handleConfirmationOpen = () => this.setState({ confirmOpen: true, transactionOpen: false });
    handleConfirmationClose = () => this.setState({ confirmOpen: false });

    getDefaultValues = () => {
        return {
            ...initialValues,
            transactionId: this.props.transactionId,
            deliveryId: this.props.deliveryId,
            jobId: this.props.jobId,
            transactionItemType: !isEmpty(this.props.jobId) ? TRANSACTION_ITEM_TYPE_ENUMS.JOB : !isEmpty(this.props.deliveryId) ? TRANSACTION_ITEM_TYPE_ENUMS.DELIVERY : undefined,
            transactionItemTypeId: this.props.transactionItemTypeId,

        };
    }

    handleTransactionType = (isCredit) => {
        let newValues = { ...this.getDefaultValues(), isCredit: isCredit };
        this.setState({ initialValues: newValues }, () => this.handleTransactionOpen());
    }

    _getSelectedPhrase = () => {
        const { jobId, deliveryId, transactionItemTypeId } = this.props;
        if (!isEmpty(deliveryId)) {
            if (!isEmpty(transactionItemTypeId) && transactionItemTypeId === TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB) {
                return `Driver pay`;
            }
            else {
                return `Delivery price`;
            }
        }

        if (!isEmpty(jobId)) {
            return `Job cost`;
        }

        return `Nothing`;
    }

    dispalyTransactionMessage = () => {
        const { initialValues } = this.state;
        if (initialValues.isCredit)
            return `${this._getSelectedPhrase()} will be increased.`;
        else
            return `${this._getSelectedPhrase()} will be reduced.`;
    }

    diplayConfirmationMessage = () => {
        const { initialValues } = this.state;
        let action = initialValues.isCredit ? "increase" : "reduce";
        return `Are you sure you want to ${action} ${this._getSelectedPhrase().toLowerCase()} by ${localiseCurrency(initialValues.amount * 1)} VAT exclusive?`;
    }

    handleFormikSubmit = (values) => {
        this.setState({ initialValues: values }, this.handleConfirmationOpen());
    }

    handleCreateTransactionItem = () => {
        const { initialValues } = this.state;

        this.setState({ loading: true });
        this.props.clearTransactionItemsSuccess();

        if (initialValues.transactionItemType === TRANSACTION_ITEM_TYPE_ENUMS.JOB)
            this.props.createJobTransactionItem(initialValues);

        if (initialValues.transactionItemType === TRANSACTION_ITEM_TYPE_ENUMS.DELIVERY)
            this.props.createDeliveryTransactionItem(initialValues);
    }

    reloadData() {
        const { jobId, deliveryId, getQuoteDetails, getJobDetailsTransactionItems } = this.props;
        if (!isEmpty(jobId) && !isEmpty(getJobDetailsTransactionItems)) {
            getJobDetails(jobId);
            getJobDetailsTransactionItems(jobId);
        }
        if (!isEmpty(deliveryId) && !isEmpty(getQuoteDetails)) {
            getQuoteDetails(deliveryId);
        }
    }

    validNewAmount = () => {
        const { initialValues } = this.state;
        let amountExcl = isEmpty(initialValues.amount) ? 0 : initialValues.isCredit ? initialValues.amount * 1 : initialValues.amount * -1;
        return (this.props.currentAmountExcl + amountExcl).toFixed(2) >= 0;
    }

    renderDisplaySummary = () => {
        const { initialValues } = this.state;
        const { currentAmountExcl, transactionItemTypeId } = this.props;

        let amountExcl = isEmpty(initialValues.amount) ? 0 : initialValues.isCredit ? initialValues.amount * 1 : initialValues.amount * -1;
        let newAmountExcl = currentAmountExcl + amountExcl;
        let color = initialValues.isCredit ? "primary" : "secondary";
        let label = initialValues.transactionItemType === TRANSACTION_ITEM_TYPE_ENUMS.JOB || (!isEmpty(transactionItemTypeId) && transactionItemTypeId === TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB) ? "cost" : "price";

        return (

            <Paper elevation={2} style={{ padding: "10px" }}>
                <Grid container>
                    <Grid item xs={6} >
                        <Typography variant="body2"> Current {label} excl</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" align="right"> {localiseCurrency(currentAmountExcl)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color={color}>{initialValues.isCredit ? "Increase by" : "Reduce by"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color={color} align="right">  {localiseCurrency(amountExcl)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color={color}> New  {label} excl</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color={color} align="right">   {localiseCurrency(newAmountExcl)}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.jobDetails, prevProps.jobDetails) && !isEmpty(this.props.jobDetails.job) && !isEmpty(this.props.jobDetails.transactionItems)) {
            this.setState({ loading: false });
        }

        if (!isEqual(this.props.quotes, prevProps.quotes) && !isEmpty(this.props.quotes.data)) {
            this.setState({ loading: false });
        }

        if (!isEmpty(this.props.errors) && !isEmpty(this.props.errors.message) && this.state.loading) {
            if (this.props.errors.status === 404)
                this.props.history.push('/404');
            this.setState({ loading: false });
        }

        if (this.props.transactionItems !== prevProps.transactionItems && !isEmpty(this.props.transactionItems.success)) {
            this.setState({ loading: false, transactionOpen: false, confirmOpen: false });
            this.reloadData();
        }
    }

    componentWillUnmount() {
        this.props.clearTransactionItemsSuccess();
    }


    render() {
        const { jobId, deliveryId, auth, quotes, jobDetails, transactionItemTypeId, transactionItems } = this.props;
        const { initialValues, loading, transactionOpen, confirmOpen } = this.state;

        let formId = "debit-and-credit-form";
        let buttonText = !isEmpty(deliveryId) && (!isEmpty(transactionItemTypeId) && transactionItemTypeId === TRANSACTION_ITEM_TYPE_ID_ENUMS.DELIVERY) ? "Price" : "Cost";
        let canConfirm = this.validNewAmount();
        let label = initialValues.transactionItemType === TRANSACTION_ITEM_TYPE_ENUMS.JOB || (!isEmpty(transactionItemTypeId) && transactionItemTypeId === TRANSACTION_ITEM_TYPE_ID_ENUMS.JOB) ? "cost" : "price";

        return (
            <Fragment>
                {
                    (
                        (!isEmpty(deliveryId) && quotes.details.paymentStatusId === PAYMENT_STATUS_ENUMS.PAID) ||
                        (!isEmpty(jobId) && !isEmpty(jobDetails.driver))
                    ) &&
                    hasPermission(auth, { isAdministrator: true, isManagementOperator: true }) &&
                    <Fragment>
                        <br />
                        <ButtonGroup aria-label="finance group" fullWidth={true}>
                            <Tooltip title="Reduce amount excl">
                                <Button
                                    color="secondary"
                                    startIcon={<TrendingDown />}
                                    onClick={() => this.handleTransactionType(false)}
                                >{buttonText}</Button>
                            </Tooltip>

                            <Tooltip title="Increase amount excl">
                                <Button
                                    color="primary"
                                    endIcon={<TrendingUp />}
                                    onClick={() => this.handleTransactionType(true)}
                                >{buttonText}</Button>
                            </Tooltip>
                        </ButtonGroup>

                        <SimpleDialog
                            open={transactionOpen}
                            onCloseClick={this.handleTransactionClose}
                            formId={formId}
                            title={`${this._getSelectedPhrase()} transaction`}
                            loading={loading}
                            submitText="Proceed"
                        >
                            <Formik
                                ref={this.formik}
                                onSubmit={values => this.handleFormikSubmit(values)}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setValues }) => (

                                    <form onSubmit={handleSubmit} id={formId} noValidate>
                                        <Typography>
                                            {this.dispalyTransactionMessage()}
                                        </Typography>
                                        <TextField
                                variant="standard"
                                            name="amount"
                                            label="Amount excl"
                                            type="number"
                                            fullWidth={true}
                                            error={!isEmpty(errors.amount)}
                                            helperText={errors.amount}
                                            value={values.amount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            margin="normal"
                                        />

                                        <TextField
                                variant="standard"
                                            name="description"
                                            label="Short reason"
                                            fullWidth={true}
                                            error={!isEmpty(errors.description)}
                                            helperText={errors.description}
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            margin="normal"
                                        />
                                    </form>
                                )}
                            </Formik>
                        </SimpleDialog>

                        <ConfirmationDialog
                            open={confirmOpen}
                            onConfirmClick={this.handleCreateTransactionItem}
                            onCloseClick={this.handleConfirmationClose}
                            title={`${this._getSelectedPhrase()} confirmation`}
                            loading={transactionItems.loading}
                            confirmDisabled={!canConfirm}
                        >
                            <Typography color="secondary"><Warning style={{ marginBottom: -5 }} /> Transaction cannot be undone!</Typography>
                            <br />
                            <Typography>
                                {this.diplayConfirmationMessage()}
                            </Typography>
                            <br />
                            {this.renderDisplaySummary()}
                            {
                                !canConfirm &&
                                <Typography variant="caption" color="secondary">
                                    New  {label} excl cannot be less than 0.
                                </Typography>
                            }
                        </ConfirmationDialog>
                    </Fragment>
                }
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    transactionItems: state.transactionItems,
    jobDetails: state.jobDetails,
    quotes: state.quotes,
});

export default compose(
    connect(mapStateToProps, { createJobTransactionItem, createDeliveryTransactionItem, clearTransactionItemsSuccess, getJobDetails, getJobDetailsTransactionItems, getQuoteDetails }),
)(DebitAndCredit);