import React from 'react';
import Alert from '@mui/material/Alert';
import { Link } from '@mui/material';
import { connect } from 'react-redux';
import { APP_BAR_HEIGHT, SECONDARY_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';

const VerifyEmailBar = ({ auth, absolute }) => {
    return (
        auth?.currentUser?.user?.emailConfirmed === false && !absolute &&
        <Alert
            severity="error"
            variant="filled"
            style={{ marginTop: APP_BAR_HEIGHT, backgroundColor: SECONDARY_COLOR , display:"flex", justifyContent:"center"}}
        >
            For added security, please click <Link href="/confirm-email"><b>here</b></Link> to verify your email address or contact support for assistance.
        </Alert>
    );
}

const mapStateToProps = (state) => ({
    portal: state.portal,
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps)(VerifyEmailBar);
