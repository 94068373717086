import { makeStyles } from '@mui/styles';
import {
    WHITE_COLOR,
    SECONDARY_COLOR,
    FONT_FAMILY,
    PRIMARY_COLOR, TINY_FONT
} from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { darken } from '@mui/material';


export default makeStyles(theme => ({
    loadingText: {
        color: PRIMARY_COLOR,
        fontSize: `${TINY_FONT}px !important`,
        marginTop: 15,
        marginBottom: 15,
        fontFamily: FONT_FAMILY + "!important",
        textAlign: "center !important",
      },
    icon: {
        fontSize: 32,
        color: WHITE_COLOR
    },
    button: {
        marginBottom:  "16px",
        marginTop: 0,
        backgroundColor: SECONDARY_COLOR,
        '&:hover': {
            backgroundColor: darken(SECONDARY_COLOR, 0.4)
        }
    },
    action: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: "15px"
    },
    countDown: {
        fontWeight: 600
    }
}));