import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import withStylesHook from 'utils/withStylesHook';

import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import { getContactTypes } from 'wumdrophubsreactshared/_actions/contactTypes';
import { getStores } from 'wumdrophubsreactshared/_actions/stores';
import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import mapQuoteToDetails from 'wumdrophubsreactshared/_utils/mapQuoteToDetails';
import styles from './DetailsStep.css';
import cleanPhoneNumber from '../../../wumdrophubsreactshared/_utils/cleanPhoneNumber';
import { PHONE_REG_EXP, PICK_UP, DROP_OFF } from '../../../wumdrophubsreactshared/_constants/typeConstants';
import buildPageMeta from 'wumdrophubsreactshared/_utils/buildPageMeta';
import ContactDetails from './ContactDetails';
import AdvancedDetails from './AdvancedDetails';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Grid, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import combineStyles from "../../../wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "../../../App.css";
import formatDisplayAddress from 'wumdrophubsreactshared/_utils/formatDisplayAddress';

//Formik and Yup Validation
const originValidationSchema = yup.object().shape({
    originContactName: yup.string().nullable().required("Name is required"),
    originContactCountry: yup.string().nullable().required("Code is required"),
    originPhoneNumber: yup.string().nullable().required("Phone number is required").matches(PHONE_REG_EXP, 'Phone number is not valid')
});

const desinationValidationSchema = yup.object().shape({
    destinationContactName: yup.string().nullable().required("Name is required"),
    destinationContactCountry: yup.string().nullable().required("Code is required"),
    destinationPhoneNumber: yup.string().nullable().required("Phone number is required").matches(PHONE_REG_EXP, 'Phone number is not valid')
});

const additionalValidationSchema = yup.object().shape({
    destinationContactName: yup.string().nullable().required("Name is required"),
    destinationContactCountry: yup.string().nullable().required("Code is required"),
    destinationPhoneNumber: yup.string().nullable().required("Phone number is required").matches(PHONE_REG_EXP, 'Phone number is not valid')
});

class DetailsStep extends Component {

    constructor(props) {
        super(props);
        let initialValues = mapQuoteToDetails(this.props.quoteResponse);
        const { auth, portal } = this.props;

        if (this.props.getContactTypes) {
            this.props.getContactTypes();
        }

        if (this.props.getElasticContactsPage) {
            let meta = buildPageMeta({}, []);
            this.props.getElasticContactsPage(meta);
        }

        let originStore = initialValues.originStoreId ? this.getStoreById(initialValues.originStoreId) : null;
        let destinationStore = initialValues.destinationStoreId ? this.getStoreById(initialValues.destinationStoreId) : null;
        let enableAddressBook = false;

        if (auth.isAuthenticated) {
            const { user } = auth.currentUser;

            //NB! only allow users who are not company or store users to use the address book feature
            if (isEmpty(user.companyId) && isEmpty(user.storeId)) {
                enableAddressBook = true;
            }

            let contactCountry = !isEmpty(user.contactCountryCode) ? user.contactCountryCode : portal.data.cca2;
            if (isEmpty(initialValues.originContactCountry))
                initialValues.originContactCountry = contactCountry;
            if (isEmpty(initialValues.destinationContactCountry))
                initialValues.destinationContactCountry = contactCountry;

            if (isEmpty(initialValues.originPhoneNumber))
                initialValues.originPhoneNumber = initialValues.originStoreId ? originStore.phoneNumber : user.phoneNumber;

            if (isEmpty(initialValues.originContactName))
                initialValues.originContactName = initialValues.originStoreId ? originStore.contactName : `${user.firstName} ${user.lastName}`;

            if (isEmpty(initialValues.originBuildingName))
                initialValues.originBuildingName = initialValues.originStoreId ? originStore.unitName : user.unitName;

            if (isEmpty(initialValues.originBuildingUnit))
                initialValues.originBuildingUnit = initialValues.originStoreId ? originStore.unitNumber : user.unitNumber;


        } else {
            initialValues.originContactCountry = portal.data.cca2;
            initialValues.destinationContactCountry = portal.data.cca2;
        }

        this.state = {
            refreshProps: false,
            initialValues: initialValues,
            loading: true,
            originStore,
            destinationStore,
            enableAddressBook
        };
    }

    handleAddressTypeChange = (event, values, addressType) => {
        let newValues = { ...values };
        if (addressType === PICK_UP) {
            newValues.originAddressType = event.target.value;
            this.setState({ initialValues: newValues })
        }

        if (addressType === DROP_OFF) {
            newValues.destinationAddressType = event.target.value;
            this.setState({ initialValues: newValues })
        }
    }

    handleFormikSubmit(values) {

        const { detailsActiveStep } = this.props;

        //this stops people from being able to go to next step accidentally
        let valid = false;

        //here we clean our phone numbers before submitting
        switch (detailsActiveStep) {
            case 0:
                values.originPhoneNumber = cleanPhoneNumber(values.originPhoneNumber);
                valid = !isEmpty(values.originPhoneNumber) && !isEmpty(values.originContactCountry) && !isEmpty(values.originContactName);
                break;
            case 1:
                values.destinationPhoneNumber = cleanPhoneNumber(values.destinationPhoneNumber);
                valid = !isEmpty(values.destinationPhoneNumber) && !isEmpty(values.destinationContactCountry) && !isEmpty(values.destinationContactName);
                break;

            default:
                valid = true; // additional details step always valid
                break;
        }

        if (valid) {
            this.props.clearErrors();
            this.props.handleStepSubmit(values);
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { auth, portal } = this.props;
        if (!isEqual(this.props.quoteResponse, nextProps.quoteResponse)) {
            let initialValues = mapQuoteToDetails(nextProps.quoteResponse);
            if (auth.isAuthenticated) {
                let user = auth.currentUser.user;
                let contactCountry = !isEmpty(user.contactCountryCode) ? user.contactCountryCode : portal.data.cca2;
                if (isEmpty(initialValues.originContactCountry))
                    initialValues.originContactCountry = contactCountry;
                if (isEmpty(initialValues.destinationContactCountry))
                    initialValues.destinationContactCountry = contactCountry;
            } else {
                initialValues.originContactCountry = portal.data.cca2;
                initialValues.destinationContactCountry = portal.data.cca2;
            }
            this.setState({ initialValues: initialValues });
        }
    }

    getStoreById = (storeId) => {
        let store = null;
        if (isEmpty(this.props.stores.data) && this.props.getStores) {
            this.props.getStores().then(() => {
                if (this.props.stores.data.items)
                    store = this.props.stores.data.items.find(x => x.id === storeId);
            });
        } else if (!isEmpty(this.props.stores.data) && !isEmpty(this.props.stores.data.items)) {
            store = this.props.stores.data.items.find(x => x.id === storeId);
        }

        return store;
    }


    render() {
        const {
            classes,
            contactTypes,
            wizardRef,
            quoteResponse,
            auth, bindSubmitForm, detailsActiveStep, loading } = this.props;

        const { refreshProps, initialValues, originStore, destinationStore, enableAddressBook } = this.state;

        let formId = `${wizardRef}4`;

        return (
            <div>
                <Typography className={classes.deliveryStepHeader}>
                    Enter your contact details &amp; additional information
                </Typography>
                <Stepper orientation="vertical" activeStep={detailsActiveStep} >
                    <Step>
                        <StepLabel><Typography className={classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Pick-up:</span> {formatDisplayAddress(quoteResponse.originAddress)}</Typography></StepLabel>
                        <StepContent>
                            <Formik
                                onSubmit={(values) => this.handleFormikSubmit(values)}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={originValidationSchema}
                            >
                                {(formikProps) => {
                                    const { handleSubmit, handleChange, handleBlur, values, touched, errors, setValues } = formikProps;

                                    return (
                                        <form onSubmit={handleSubmit} id={formId} noValidate >
                                            {// eslint-disable-next-line
                                            (!detailsActiveStep == 0 && loading) ? (
                                                <div className={classes.progress}><CircularProgress /></div>
                                            ) :
                                                <div>
                                                    <ContactDetails
                                                        title="pick-up"
                                                        contactTypes={contactTypes}
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        refreshProps={refreshProps}
                                                        handleAddressTypeChange={this.handleAddressTypeChange}
                                                        addressTypeFieldName="originAddressType"
                                                        contactNameFieldName="originContactName"
                                                        contactCountryFieldName="originContactCountry"
                                                        phoneNumberFieldName="originPhoneNumber"
                                                        buildingNameFieldName="originBuildingName"
                                                        buildingUnitFieldName="originBuildingUnit"
                                                        contactDetailsSameAsUserFieldName="originContactDetailsSameAsUser"
                                                        contactIdFieldName="originContactId"
                                                        remarksFieldName="originRemarks"
                                                        addressType={PICK_UP}
                                                        setValues={setValues}
                                                        currentUser={auth.currentUser}
                                                        //displayAddressFieldName="originDisplayAddress"
                                                        storeIdFieldName="originStoreId"
                                                        store={originStore}
                                                        enableAddressBook={enableAddressBook}
                                                        enableAutoComplete={true}
                                                    />
                                                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            form={formId}
                                                            type="submit"
                                                            className={classes.button}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Grid>
                                                </div>
                                            }
                                        </form>
                                    )
                                }}
                            </Formik>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel><Typography className={classes.displayAddressWrap}><span className={classes.deliverySubTitle}>Drop-off:</span> {formatDisplayAddress(quoteResponse.destinationAddress)}</Typography></StepLabel>
                        <StepContent>
                            <Formik
                                onSubmit={values => this.handleFormikSubmit(values)}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={desinationValidationSchema}
                            >
                                {(formikProps) => {
                                    const { handleSubmit, handleChange, handleBlur, values, touched, errors, setValues } = formikProps;
                                    // bind the submission handler remotely
                                    //bindSubmitForm(formikProps.submitForm);

                                    return (
                                        <form onSubmit={handleSubmit} id={formId} noValidate>
                                            {// eslint-disable-next-line
                                            (!detailsActiveStep == 1 || loading) ? (<div className={classes.progress}><CircularProgress /></div>) :
                                                <div>
                                                    <ContactDetails
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        refreshProps={refreshProps}
                                                        title="drop-off"
                                                        contactTypes={contactTypes}
                                                        handleAddressTypeChange={this.handleAddressTypeChange}
                                                        addressTypeFieldName="destinationAddressType"
                                                        contactNameFieldName="destinationContactName"
                                                        contactCountryFieldName="destinationContactCountry"
                                                        phoneNumberFieldName="destinationPhoneNumber"
                                                        buildingNameFieldName="destinationBuildingName"
                                                        buildingUnitFieldName="destinationBuildingUnit"
                                                        contactDetailsSameAsUserFieldName="destinationContactDetailsSameAsUser"
                                                        contactIdFieldName="destinationContactId"
                                                        remarksFieldName="destinationRemarks"
                                                        addressType={DROP_OFF}
                                                        setValues={setValues}
                                                        currentUser={auth.currentUser}
                                                        //displayAddressFieldName="destinationDisplayAddress"
                                                        storeIdFieldName="destinationStoreId"
                                                        store={destinationStore}
                                                        getStoreById={this.getStoreById}
                                                        enableAddressBook={enableAddressBook}
                                                        enableAutoComplete={enableAddressBook}
                                                    />
                                                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            form={formId}
                                                            type="submit"
                                                            className={classes.button}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Grid>
                                                </div>
                                            }
                                        </form>
                                    )
                                }}
                            </Formik>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel><Typography className={classes.stepSubTitle}>Additional details (optional)</Typography></StepLabel>
                        <StepContent>
                            <Formik
                                onSubmit={values => this.handleFormikSubmit(values)}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={additionalValidationSchema}
                            >
                                {(formikProps) => {
                                    const { handleSubmit, handleChange, handleBlur, values, touched, errors, setValues } = formikProps;

                                    // bind the submission handler remotely
                                    bindSubmitForm(formikProps.submitForm);

                                    return (
                                        <form onSubmit={handleSubmit} id={formId} noValidate>
                                            { // eslint-disable-next-line
                                            (!detailsActiveStep == 2 || loading) ? (<div className={classes.progress}><CircularProgress /></div>) :
                                                <AdvancedDetails
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    refreshProps={refreshProps}
                                                    setValues={setValues}
                                                />
                                            }
                                        </form>
                                    )
                                }}
                            </Formik>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

DetailsStep.propTypes = {
    auth: PropTypes.object.isRequired,
    portal: PropTypes.object.isRequired,
    quoteResponse: PropTypes.object.isRequired,
    contactTypes: PropTypes.object.isRequired,
    stores: PropTypes.object.isRequired,
    getContactTypes: PropTypes.func.isRequired,
    getStores: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    handleStepSubmit: PropTypes.func.isRequired,
    wizardRef: PropTypes.string.isRequired,
    detailsActiveStep: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    bindSubmitForm: PropTypes.func,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    serverErrors: state.errors,
    portal: state.portal,
    contactTypes: state.contactTypes,
    stores: state.stores
})

const combinedStyles = combineStyles(styles, appStyles);

export default compose(
    withStylesHook(combinedStyles),
    withRouter,
    connect(mapStateToProps, { clearErrors, getContactTypes, getStores })
)(DetailsStep);