import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './LoadingMessage.css';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../../logo.svg';
import ani1 from '../../../assets/img/Loading.svg';


const useStyles = makeStyles(styles);

const LoadingMessage = ({ message = "Loading...", component = null }) => {

    const classes = useStyles();

    const loader = <div className={classes.loadingBox}>
        <CircularProgress className={classes.loadingCircle} />
        <div className={classes.loadingText}>{message}</div>
    </div>;

    return (
        <div className={classes.splashScreen}>
            <div><img src={ani1} alt="Processing" height="140px" /></div>
            <div className={classes.logo}><img src={logo} alt="logo" height="33px" /></div>
            {component ? component : loader}
        </div>
    );
}

export default LoadingMessage;