import React, { createContext, useContext } from 'react';
import { LoadScript } from '@react-google-maps/api';
import LoadingMessage from '../init/LoadingMessage';

const GoogleMapsContext = createContext();
const libraries = ['drawing', 'geometry', 'places'];

export const GoogleMapsProvider = ({ children }) => {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={libraries}
      loadingElement={<LoadingMessage message="Loading maps" />} // Optional: Loading indicator
      containerElement={<div style={{ height: '100%' }} />} // Ensure the container has a height
      mapElement={<div style={{ height: '100%' }} />} // Ensure the map element has a height
    >
      <GoogleMapsContext.Provider value={{ loaded: true }}>
        {children}
      </GoogleMapsContext.Provider>
    </LoadScript>
  );
};

export const useGoogleMaps = () => {
  return useContext(GoogleMapsContext);
};
