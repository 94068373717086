import { GET_USER_ACTION_NOTES, CLEAR_USER_ACTION_NOTES } from "../_actions/types";

const initialState = {
    data: {},
    success: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        
        case GET_USER_ACTION_NOTES:
            return {
                ...state,
                data: action.payload 
            };
        case CLEAR_USER_ACTION_NOTES:
            return {
                 ...state,
                data: action.payload
            };    
        default:
            return state;
    }
}
