import React from 'react';
import { useTheme } from '@mui/styles';

// Generic HOC to inject classes using a custom `useStyles` hook
function withStylesHook(useStyles) {
  return function (WrappedComponent) {
    return function WrapperComponent(props) {
      const classes = useStyles();
      const theme = useTheme();
      return <WrappedComponent {...props} classes={classes} theme={theme} />;
    };
  };
}

export default withStylesHook;
