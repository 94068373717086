import { feedback } from 'assets/jss/material-kit-pro-react.jsx'
import {
  BLACK_COLOR,
  SMALL_FONT,
  PRIMARY_COLOR,
  SLATE_COLOR,
  TERTIARY_COLOR,
  BOX_BACKGROUND,
  COMPLEMENTARY_2_COLOR,
  COMPLEMENTARY_5_COLOR,
  LARGE_FONT,
  TINY_FONT,
  FONT_FAMILY_HEADER,
  FONT_FAMILY
} from 'wumdrophubsreactshared/_constants/styleConstants'
import { lighten } from '@mui/material'


export default theme => ({
  deliveryStepHeader: {
    fontFamily: `${FONT_FAMILY_HEADER} !important`,
    fontWeight: '200 !important',
    fontSize: `${SMALL_FONT}px !important`,
    paddingBottom: '0px !important',
    color: `${PRIMARY_COLOR} !important`,
    fontStyle: 'italic !important',
    marginBottom: '9px !important'
  },
  stepHeader: {
    fontFamily: `${FONT_FAMILY_HEADER} !important`,
    fontWeight: 'bold !important',
    paddingBottom: '20px !important',
    paddingLeft: '6px !important',
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  },
  stepTitle: {
    color: `${TERTIARY_COLOR} !important`
  },
  price: {
    color: `${BLACK_COLOR} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    fontWeight: 'bold !important'
  },
  expiryText: {
    color: `${TERTIARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`
  },
  timeIcon: {
    color: `${lighten(BLACK_COLOR, 0.1)} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    marginRight: '2px !important',
    marginLeft: '2px !important',
    marginBottom: '-3px !important'
  },
  timeHours: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`
  },
  timeHoursLarge: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${SMALL_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`
  },
  timeDetailsHours: {
    minWidth: '220px !important',
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`
  },
  deliveryRemarks: {
    fontFamily: `${FONT_FAMILY} !important`,
    fontSize: `${TINY_FONT}px !important`
  },
  slaTitle: {
    fontWeight: '600 !important',
    color: `${SLATE_COLOR} !important`,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '30px !important'
    }
  },
  dateText: {
    fontSize: `${SMALL_FONT}px !important`,
    color: `${lighten(BLACK_COLOR, 0.4)} !important`,
    marginTop: '2px !important'
  },
  displayAddress: {
    whiteSpace: 'nowrap !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    fontSize: '0.85em !important',
    fontWeight: '600 !important'
  },
  displayAddressWrap: {
    fontSize: `${SMALL_FONT}px !important`,
    fontWeight: '600 !important',
    paddingRight: '12px !important'
  },
  displayDetailsAddressWrap: {
    fontSize: `${TINY_FONT}px !important`,
    fontWeight: '700 !important'
  },
  inputIconsColor: {
    color: `${BLACK_COLOR} !important`,
    fontSize: '17px !important',
    margin: '5px 5px -5px 0px !important'
  },
  infoIcons: {
    color: `${BLACK_COLOR} !important`,
    fontSize: '17px !important'
  },
  marginTop15: {
    marginTop: '15px !important'
  },
  textTotal: {
    color: `${BLACK_COLOR} !important`,
    fontFamily: `${FONT_FAMILY} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontWeight: '100 !important',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px !important'
    }
  },
  textValue: {
    float: 'right !important',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '15px !important',
      float: 'right !important'
    }
  },
  textPadding: {
    paddingLeft: '0px !important'
  },
  hideElement: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  deliveryTitle: {
    fontWeight: 'bold !important',
    padding: '17px 0px 12px 9px !important'
  },
  deliverySubTitle: {
    color: `${TERTIARY_COLOR} !important`,
    fontWeight: '600 !important'
  },
  stepSubTitle: {
    fontSize: `${SMALL_FONT}px !important`
  },
  gridItemPadding: {
    paddingRight: '45px !important',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px !important'
    }
  },
  setGridWidthAsIs: {
    // leave class here, must be empty
  },
  maxGridWidth: {
    maxWidth: '100% !important',
    flexBasis: '100% !important'
  },
  feedback: {
    ...feedback,
    marginTop: '10px !important'
  },
  root: {
    backgroundColor: `${theme.palette.background.paper} !important`
  },
  radioContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  radioButton: {
    padding: '0px !important'
  },
  helpButton: {
    padding: '3px !important',
    float: 'right !important',
    marginTop: '-5px !important'
  },
  helpIcon: {
    width: '0.95em !important',
    height: '0.95em !important'
  },
  listItem: {
    color: `${lighten(BLACK_COLOR, 0.45)} !important`,
    backgroundColor: `${BOX_BACKGROUND} !important`,
    borderRadius: '3px !important',
    marginBottom: '10px !important',
    '&:hover': {
      background: `${lighten(COMPLEMENTARY_2_COLOR, 0.85)} !important`
    }
  },
  priceDifference: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${SMALL_FONT}px !important`
  },
  selectedText: {
    fontWeight: 'bold !important',
    color: `${lighten(BLACK_COLOR, 0.15)} !important`
  },
  listItemText: {
    color: 'inherit !important'
  },
  detailsHeading: {
    color: `${COMPLEMENTARY_5_COLOR} !important`,
    fontWeight: 'bold !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
    fontSize: `${SMALL_FONT}px !important`,
    [theme.breakpoints.down('sm')]: {}
  },
  progress: {
    width: '100% !important',
    marginTop: '50px !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    justifyContent: 'center !important'
  },
  slaSubtext: {
    fontWeight: '400 !important',
    fontSize: '15px !important',
    color: `${SLATE_COLOR} !important`
  },
  dot: {
    fontWeight: '600 !important'
  },
  timeWindowText: {
    fontSize: `${SMALL_FONT}px !important`,
    color: `${lighten(BLACK_COLOR, 0.4)} !important`
    //marginTop: '5px !important'
  },
  timeWindowSection: {
    [theme.breakpoints.up('md')]: {
      marginTop: '8px !important',
      marginBottom: '6px !important'
    }
  },
  checkMark: {
    float: 'right !important',
    display: 'inline-block !important',
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    },
    color: `${PRIMARY_COLOR} !important`,
    marginTop: '7px !important'
  },
  slaDescription: {
    display: 'inline-block !important'
  },
  icon: {
    color: `${lighten(BLACK_COLOR, 0.4)} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    marginRight: '2px !important',
    marginBottom: '-4px !important'
  },
  marginZero: {
    marginBottom: '2px !important'
  },
  deliveryDate: {
    color: `${COMPLEMENTARY_5_COLOR} !important`,
    fontWeight: 'bold !important'
  },
  changeOrderText: {
    fontSize: `${SMALL_FONT}px !important`,
    float: 'right !important',
    position: 'absolute !important',
    bottom: '75px !important'
  },
  changeOrderLink: {
    color: `${TERTIARY_COLOR} !important`,
    '&:hover': {
      cursor: 'pointer !important'
    }
  },
  deliveryTimeConfirmation: {
    lineHeight: '18px !important',
    fontWeight: 'bold !important',
    fontSize: `${TINY_FONT}px !important`,
    color: `${TERTIARY_COLOR} !important`,
    padding: '7px 0 !important',
    [theme.breakpoints.up('md')]: {}
  }
})
