import React, { Component } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import AdminContainer from '../../AdminContainer';
import RateUploadForm from './RateUploadForm';
import {  UPLOAD_BATCHES, RATES } from 'wumdrophubsreactshared/_constants/typeConstants';
import { getRateUpload, updateRateUpload, clearRateUploadsSuccess } from 'wumdrophubsreactshared/_actions/rateUploads';
import withWidthHook from 'utils/useWidth';
import CircularProgress from '@mui/material/CircularProgress';
import AdminCard from 'components/administration/AdminCard';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';


class RateUploadEditForm extends Component {
    constructor(props) {
        super(props);

        let url = props.match.url;
        let firstParentId = null;
        let firstParentType = null;
        let secondParentId = null;
        let secondParentType = null;
        let secondParentText = null;

        if (url.toLowerCase().includes(`/${UPLOAD_BATCHES}/`)) {
            firstParentId = this.props.match.params.uploadBatchId;
            firstParentType = RATES;
            secondParentId = props.match.params.rateUploadId;
            secondParentType = UPLOAD_BATCHES;
            secondParentText = `#${secondParentId}`;
        }

        this.state = {
            loading: false,
            firstParentId: firstParentId,
            firstParentType: firstParentType,
            secondParentId: secondParentId,
            secondParentText: secondParentText,
            secondParentType: secondParentType
        };


        if (this.props.match.params.rateUploadId > 0) {
            this.props.getRateUpload(this.props.match.params.rateUploadId);
        }

       
    }


    handleFormikSubmit = (values) => {
        this.props.updateRateUpload(this.props.match.params.rateUploadId, values);
        this.setState({ loading: true });
    }

    handleCancel = () => {
        const { firstParentId } = this.state;
        this.props.history.push(`/${RATES}/${UPLOAD_BATCHES}/${firstParentId}/uploads`);
    }

    componentDidUpdate() {
        if (!isEmpty(this.props.rateUploads) && !isEmpty(this.props.rateUploads.success) && this.state.loading) {
            this.setState({ loading: false });
            this.handleCancel();
        }
    }

    render() {
        const {
            firstParentId,
            firstParentType,
            secondParentId,
            secondParentText,
            secondParentType
        } = this.state;

        const { rateUploads } = this.props;
 

        let rateName = !isEmpty(rateUploads.data) ? rateUploads.data.Name : "";
        return (
            <AdminContainer
                type={UPLOAD_BATCHES}
                linkText="Edit"
                currentLinkDisabled={true}
                firstParentId={firstParentId}
                firstParentText={rateName}
                firstParentType={firstParentType}
                secondParentId={secondParentId}
                secondParentText={secondParentText}
                secondParentType={secondParentType}
                thirdParentType={UPLOAD_BATCHES}
                thirdParentText={`#${this.props.match.params.rateUploadId}`}
            >


                <AdminCard title="Update rate upload">
                    {this.state.loading ?
                        <CircularProgress />
                        :
                        <RateUploadForm
                            cancelText="Back"
                            handleCancel={this.handleCancel}
                            rateUpload={rateUploads.data}
                            handleFormikSubmit={this.handleFormikSubmit}
                    
                        />

                    }
                </AdminCard>
            </AdminContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    rates: state.rates,
    rateUploads: state.rateUploads
});

export default compose(
    connect(mapStateToProps, {getRateUpload, updateRateUpload, clearRateUploadsSuccess }),
    withWidthHook
)(RateUploadEditForm);
