import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { MAP_CENTER_LAT, MAP_CENTER_LNG, MAP_STYLES, DIRECTIONS_COLOR } from 'wumdrophubsreactshared/_constants/styleConstants';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import getPositionPoint from 'wumdrophubsreactshared/_utils/getPositionPoint';
import MapControl from './MapControl';
import { Paper, Grid, Typography } from '@mui/material';
import withStylesHook from 'utils/withStylesHook';
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";
import styles from './BasicMap.css';
import getMarkerIconUrl from 'utils/getMarkerIconUrl';
;
const combinedStyles = combineStyles(styles, appStyles);
const center = {
    lat: MAP_CENTER_LAT,
    lng: MAP_CENTER_LNG
};

const DeliveryMap = ({ classes, originAddress, destinationAddress }) => {
    const [google, setGoogle] = useState(window.google);
    const [map, setMap] = useState(null);
    const [startPoint, setStartPoint] = useState({});
    const [endPoint, setEndPoint] = useState({});
    const [directions, setDirections] = useState(null);
    const [distance, setDistance] = useState(null);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    const convertCoordinate = (coordinate) => {
        if (!isEmpty(coordinate)) return { lat: coordinate.lat, lng: coordinate.lon };
        return null;
    };

    const drawDirections = useCallback(() => {
        if (google && !isEmpty(startPoint) && !isEmpty(endPoint)) {
            const DirectionsService = new google.maps.DirectionsService();
            DirectionsService.route({
                origin: new google.maps.LatLng(startPoint.lat, startPoint.lng),
                destination: new google.maps.LatLng(endPoint.lat, endPoint.lng),
                travelMode: google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    let distanceInKm = Math.round((result.routes[0].legs[0].distance.value / 1000) * 100) / 100;
                    setDirections(result);
                    setDistance(`${distanceInKm} km`);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    }, [startPoint, endPoint, google]);

    useEffect(() => {
        const checkGoogle = setInterval(() => {
            if (typeof window.google !== 'undefined') {
                setGoogle(window.google);
                clearInterval(checkGoogle);
            }
        }, 500);
        return () => clearInterval(checkGoogle);
    }, [google]);

    useEffect(() => {

        let newMap = map;
        let endPoint = {};
        let startPoint = {};

        if (!isEmpty(originAddress?.coordinates)) {
            startPoint = convertCoordinate(originAddress.coordinates);
            setStartPoint(startPoint);
        }

        if (!isEmpty(destinationAddress?.coordinates)) {
            endPoint = convertCoordinate(destinationAddress.coordinates);
            setEndPoint(endPoint);
        }

        if (google) {
            let bounds = new google.maps.LatLngBounds(center);
            if (!isEmpty(startPoint) && !isEmpty(endPoint)) {
                bounds.extend(startPoint);
                bounds.extend(endPoint);
                bounds.extend(getPositionPoint(startPoint, 90, 10));
                bounds.extend(getPositionPoint(endPoint, 90, 10));
                bounds.extend(getPositionPoint(startPoint, 270, 10));
                bounds.extend(getPositionPoint(endPoint, 270, 10));
            } else if (!isEmpty(startPoint)) {
                bounds.extend(startPoint);
                bounds.extend(getPositionPoint(startPoint, 0, 5));
                bounds.extend(getPositionPoint(startPoint, 90, 5));
                bounds.extend(getPositionPoint(startPoint, 180, 5));
                bounds.extend(getPositionPoint(startPoint, 270, 5));
            } else if (!isEmpty(endPoint)) {
                bounds.extend(endPoint);
                bounds.extend(getPositionPoint(endPoint, 0, 5));
                bounds.extend(getPositionPoint(endPoint, 90, 5));
                bounds.extend(getPositionPoint(endPoint, 180, 5));
                bounds.extend(getPositionPoint(endPoint, 270, 5));
            }

            if (!isEmpty(newMap)) {
                newMap.fitBounds(bounds);
                setMap(newMap);
            }
        }
    }, [originAddress, destinationAddress, map, google]);


    useEffect(() => {
        drawDirections();
    }, [startPoint, endPoint, google, drawDirections]);

    return (<GoogleMap
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={{ width: '100%', height: "100%" }}
        options={{ draggable: false, gestureHandling: 'greedy', zoomControl: false, mapTypeControl: false, streetViewControl: false, fullscreenControl: false, styles: MAP_STYLES }}
    >
        {!isEmpty(startPoint) && !isEmpty(originAddress) &&
            <Marker position={startPoint} icon={getMarkerIconUrl({ section: "basicMapStartPoint", optionalValues: { storeId: originAddress.storeId } })} />
        }

        {!isEmpty(endPoint) && !isEmpty(destinationAddress) &&
            <Marker position={endPoint} icon={getMarkerIconUrl({ section: "basicMapEndPoint", optionalValues: { storeId: destinationAddress.storeId } })} />
        }

        {!isEmpty(directions) &&
            <DirectionsRenderer
                options={{
                    directions: directions,
                    suppressMarkers: true,
                    polylineOptions: { strokeColor: DIRECTIONS_COLOR }
                }}
            />
        }

        {!isEmpty(startPoint) && !isEmpty(endPoint) && directions &&
            <MapControl position={google.maps.ControlPosition.RIGHT_TOP} mapContext={map}>
                <div className={classes.distanceLabel} >
                    <Paper className={classes.root} elevation={2}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <Typography className={classes.distanceHeader}>
                                    Distance: {distance}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Paper>
                </div>
            </MapControl>
        }
    </GoogleMap>
    );
};

export default withStylesHook(combinedStyles)(DeliveryMap);
