import React, { Component } from 'react';
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import axios from 'axios';
import { API_BASE_URL } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import { FONT_FAMILY, SMALL_FONT } from '../../../wumdrophubsreactshared/_constants/styleConstants';
import { MINIMUM_SEARCH_CHARACTERS } from '../../../wumdrophubsreactshared/_constants/selectConstants';
import Typography from '@mui/material/Typography';
import withStylesHook from './../../../utils/withStylesHook';
import { ADDRESSES } from '../../../wumdrophubsreactshared/_constants/typeConstants';
import _ from 'lodash';
import useStyles from './SelectTo.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {Radio} from '@mui/material';
import {RadioGroup} from '@mui/material';
import { Divider } from '@mui/material';

const customSelectStyles = {
  option: (base, state) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: SMALL_FONT
  }),
  singleValue: (base, state) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: SMALL_FONT
  }),
  input: (base) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: `${SMALL_FONT}px !important`,
    '& input': {
      font: 'inherit',
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: `${SMALL_FONT}px !important`,
    color: 'rgba(0, 0, 0, 0.40)'
  }),
  noOptionsMessage: (base, state) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    color: 'rgba(0, 0, 0, 0.40)',
    fontSize: SMALL_FONT
  }),
  loadingMessage: (base, state) => ({
    ...base,
    fontFamily: FONT_FAMILY + "!important",
    fontSize: SMALL_FONT
  }),
  menu: (base, state) => ({
    ...base,
    position: "relative"
  }),
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class SelectTo extends Component {

  constructor(props) {
    super(props);
    let multi = false;
    if (!isEmpty(this.props.multiSelect)) {
      multi = this.props.multiSelect;
    }

    let value = "";
    if (!isEmpty(this.props.value)) {
      value = this.props.value;
    }

    let exclude = "false";
    if (!isEmpty(this.props.exclude)) {
      exclude = this.props.exclude;
    }

    let dKey = "key";
    let dValue = "value";
    if (!isEmpty(this.props.displayKey)) {
      dKey = this.props.displayKey;
    }

    if (!isEmpty(this.props.displayValue)) {
      dValue = this.props.displayValue;
    }

    this.state = {
      inputValue: '',
      data: [],
      noOptions: "Enter search term...",
      isMultiSelect: multi,
      value: value,
      displayKey: dKey,
      displayValue: dValue,
      exclude: exclude
    }
  }

  componentWillUnmount() {
    source.cancel('Select to unmounted');
  }

  handleInputChange = (newValue) => {
    //const inputValue = newValue.replace(/\W/g, '');
    const { minimumSearchCharacters } = this.props;
    let min = isEmpty(minimumSearchCharacters) ? MINIMUM_SEARCH_CHARACTERS : minimumSearchCharacters;
    if (newValue.length === 0 || isEmpty(newValue)) {
      this.setState({ noOptions: "Enter search term..." });
    } else if (newValue.length < min) {
      this.setState({ noOptions: "Minimum of " + min + " characters for a search..." });
      return "";
    }
    this.setState({ newValue });
    return newValue;
  };

  handleChange = (selected) => {
    let filter = {};
    filter.field = this.props.fieldName;

    if (!isEmpty(selected) && this.state.isMultiSelect) {
      // here we setup our multi-filter object 
      let multiFilter = { field: filter.field, value: [] };

      //then we add the new multi selected fields to the filters
      selected.forEach(value => {
        multiFilter.value.push(value.key);
      });

      this.props.onSelect(multiFilter, selected, true);
    }
    else if (!isEmpty(selected) && this.props.apiEntity === ADDRESSES && !isEmpty(selected[this.state.displayKey])) {
      //this is for creating a new address -> custom address specific logic
      this.createEntity(selected).then((res) => {
        filter.value = !isEmpty(res.data) && !isEmpty(res.data.id) ? res.data.id : 0;
        this.props.onSelect(filter, selected);
      });

    }
    else {
      // normal single select filter
      filter.value = !isEmpty(selected) ? selected[this.state.displayKey] : null;
      this.props.onSelect(filter, selected);
    }
  }

  createEntity = (entity) => {
    return axios.post(API_BASE_URL + "/" + this.props.apiEntity, entity)
      .then((res) => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  UNSAFE_componentWillReceiveProps(nextValue) {
    this.setState({ data: nextValue.values })
    if (isEmpty(nextValue.values)) {
      this.setState({ noOptions: "No matching results." })
    }
    if (!isEmpty(nextValue.multiSelect) && nextValue.multiSelect !== this.props.multiSelect) {
      this.setState({ isMultiSelect: nextValue.multiSelect });
    }
  }

  loadOptions = (inputValue, callback) => {
    const { minimumSearchCharacters } = this.props;
    let min = isEmpty(minimumSearchCharacters) ? MINIMUM_SEARCH_CHARACTERS : minimumSearchCharacters;
    if (!isEmpty(inputValue) && inputValue.length < min) {
      callback("");
    }
    else {
      return this.getSelectData(inputValue, callback);
    }
  };

  getSelectData(inputValue, callback) {
    let filter = "";
    if (!isEmpty(this.props.filter)) {
      filter = "&" + this.props.filter.key + "=" + this.props.filter.value;
    }
    return axios.get(API_BASE_URL + "/" + this.props.apiEntity + "?select=true&search=" + inputValue + filter)
      .then((res) => {
        if (isEmpty(res.data)) {
          this.setState({ noOptions: "No matching results." });
        }
        callback(res.data);
      })
      .catch(err => {
        this.setState({ noOptions: "Not found! No matching results." });
        callback("");
      });
  }

  handleExcludeChange = (event) => {
    this.setState({ exclude: event.target.value });
    if (this.props.onExcludeChange && this.props.isExcludableFieldName)
      this.props.onExcludeChange({ field: this.props.isExcludableFieldName, value: event.target.value })
  };

  render() {
    const { classes, label, placeholder, disabled, isExcludable } = this.props;
    const { noOptions, value, displayKey, displayValue, exclude } = this.state;

    let isDisabled = false;
    if (!isEmpty(disabled) && disabled === true) {
      isDisabled = true;
    }

    return (
      <div className={classes.selectContainer}>
        {label && <Typography className={classes.labelText}>{label}:</Typography>}

        {isExcludable &&
          <div>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="exclude" name="exclude" value={exclude} onChange={this.handleExcludeChange}>
                <FormControlLabel value="false" control={<Radio color="default" classes={{ checked: classes.radioChecked }} />} label="Include" classes={{ root: classes.radioLabelRoot, label: classes.radioLabelSmall }} />
                <FormControlLabel value="true" control={<Radio color="default" classes={{ checked: classes.radioChecked }} />} label="Exclude" classes={{ root: classes.radioLabelRoot, label: classes.radioLabelSmall }} />
              </RadioGroup>
            </FormControl>

            <Divider />
          </div>
        }

        <AsyncSelect
          styles={customSelectStyles}
          cacheOptions
          defaultValue={value}
          loadOptions={_.debounce(this.loadOptions, 500)}
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          defaultOptions
          placeholder={!isEmpty(placeholder) ? placeholder : "Search..."}
          isMulti={this.state.isMultiSelect}
          noOptionsMessage={() => noOptions}
          getOptionLabel={(values) => values[displayValue]}
          getOptionValue={(values) => values[displayKey]}
          isClearable={true}
          isDisabled={isDisabled}
        />
      </div>
    );
  }
}

SelectTo.propTypes = {
  apiEntity: PropTypes.string.isRequired
};

export default withStylesHook(useStyles)(SelectTo);