import { makeStyles } from '@mui/styles';
import { WHITE_COLOR, PRIMARY_COLOR, ADMIN_CONTAINER_HEIGHT } from '../../wumdrophubsreactshared/_constants/styleConstants';

const useStyles = makeStyles((theme) => ({        
    root: {
        flexGrow: 1
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        width: '100%'
    },
    content: {
        flexGrow: 1,
        marginTop: '48px',
        position: 'relative',
        height: ADMIN_CONTAINER_HEIGHT,
        overflow: 'hidden'
    },
    contentScroll: {
        flexGrow: 1,
        marginTop: '48px',
        position: 'relative',
        height: ADMIN_CONTAINER_HEIGHT,
        overflowY: 'scroll',
        overflowX:"hidden"
    },
    links: {
        display: 'inherit',
        flex: 1,
        lineHeight: '18px'
    },
    link: {
        color: WHITE_COLOR,
        '&:hover': {
            color: WHITE_COLOR,
            opacity: 0.75
        },
        fontSize: 15,
        fontWeight: 600,
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        }
    },
    crumbText: {
        color: WHITE_COLOR,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        }
    },
    button: {
        '&:hover': {
            color: PRIMARY_COLOR
        },
        '&:focus': {
            outline: 'none'
        }
    },
    exportButton: {
        color: '#ffffff'
    }
}));

export default useStyles;