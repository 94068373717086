//str = used to get the hue parameter of the returned color
//s = saturation of the returned color (number between 0 and 100)
//l = lightness of the returned color (number between 0 and 100)
const stringToHslColor = (str, s, l) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
  }

export default stringToHslColor;
