import React from "react";
import CountryPicker from "./CountryPicker";
import PhoneNumberMask from "./PhoneNumberMask";
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { feedback } from "assets/jss/styles";

const PhoneNumberPicker = ({ values, errors, touched, setFieldValue, handleBlur, handleChange, contactCountryFieldName, phoneNumberFieldName, disabled }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <CountryPicker
                name={contactCountryFieldName}
                onChange={(selected) => setFieldValue(contactCountryFieldName, selected.key)}
                onBlur={handleBlur}
            />
            <TextField
                variant="standard"
                error={errors[phoneNumberFieldName] && touched[phoneNumberFieldName]}
                placeholder="(080) 000-0000"
                type="tel"
                fullWidth={true}
                className={classes.phoneControl}
                InputProps={{
                    inputComponent: PhoneNumberMask,
                    classes: {
                        notchedOutline: classes.notchedOutline
                    },
                    name: phoneNumberFieldName,
                    value: values[phoneNumberFieldName],
                    onChange: handleChange,
                    onBlur: handleBlur,
                    disabled: disabled
                }}
            />
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row"
    },
    feedback: {
        ...feedback,
        marginRight: "10px"
    },
    phoneControl: {
        marginTop: '6px !important',
        marginLeft: "-200px",
        padding: "0px",
        width: `calc(100% - 40px)`,
        flex: "1"
    },
    errorsContainer: {
        display: "flex"
    }
});

export default PhoneNumberPicker;