import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {
    BLUE_COLOR,
    PRIMARY_COLOR,
    TERTIARY_COLOR,
    SMALL_FONT, LIGHT_GREY_COLOR,
    BLACK_COLOR,
    SLATE_COLOR,
    LARGE_FONT,
    FONT_FAMILY_HEADER,
    FONT_FAMILY,
    TINY_FONT

} from "wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from "@mui/material";

export default theme => ({
  deliveryStepHeader: {
    fontFamily: `${FONT_FAMILY_HEADER} !important`,
    fontWeight: "200 !important",
    fontSize: `${SMALL_FONT}px !important`,
    paddingBottom: "0px !important",
    color: `${PRIMARY_COLOR} !important`,
    fontStyle: "italic !important",
    marginBottom: "9px !important",
  },
  stepHeader: {
    fontFamily: `${FONT_FAMILY_HEADER} !important`,
    fontWeight: "bold !important",
    paddingBottom: "20px !important",
    paddingLeft: "6px !important",
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  stepTitle: {
    color: `${TERTIARY_COLOR} !important`,
  },
  price: {
    color: `${BLACK_COLOR} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    fontWeight: "bold !important",
  },
  expiryText: {
    color: `${TERTIARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
  },
  timeIcon: {
    color: `${lighten(BLACK_COLOR, 0.1)} !important`,
    fontSize: `${LARGE_FONT}px !important`,
    marginRight: "2px !important",
    marginLeft: "2px !important",
    marginBottom: "-3px !important",
  },
  timeHours: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`,
  },
  timeHoursLarge: {
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${SMALL_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`,
  },
  timeDetailsHours: {
    minWidth: "220px !important",
    color: `${PRIMARY_COLOR} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontFamily: `${FONT_FAMILY} !important`,
  },
  deliveryRemarks: {
    fontFamily: `${FONT_FAMILY} !important`,
    fontSize: `${TINY_FONT}px !important`,
  },
  slaTitle: {
    fontWeight: "600 !important",
    color: `${SLATE_COLOR} !important`,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "30px !important",
    },
  },
  dateText: {
    fontSize: `${SMALL_FONT}px !important`,
    color: `${lighten(BLACK_COLOR, 0.4)} !important`,
    marginTop: "2px !important",
  },
  displayAddress: {
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    fontSize: "0.85em !important",
    fontWeight: "600 !important",
    margin: "5px 0px 0px 5px !important",
  },
  displayAddressWrap: {
    fontSize: `${SMALL_FONT}px !important`,
    fontWeight: "600 !important",
    paddingRight: "12px !important",
  },
  displayDetailsAddressWrap: {
    fontSize: `${TINY_FONT}px !important`,
    fontWeight: "700 !important",
  },
  inputIconsColor: {
    color: `${BLACK_COLOR} !important`,
    fontSize: "17px !important",
    margin: "5px 5px -5px 0px !important",
  },
  infoIcons: {
    color: `${BLACK_COLOR} !important`,
    fontSize: "17px !important",
  },
  marginTop15: {
    marginTop: "15px !important",
  },
  textTotal: {
    color: `${BLACK_COLOR} !important`,
    fontFamily: `${FONT_FAMILY} !important`,
    fontSize: `${TINY_FONT}px !important`,
    fontWeight: "100 !important",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "15px !important",
    },
  },
  textValue: {
    float: "right !important",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "15px !important",
      float: "right !important",
    },
  },
  textPadding: {
    paddingLeft: "0px !important",
  },
  hideElement: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  deliveryTitle: {
    fontWeight: "bold !important",
    padding: "17px 0px 12px 9px !important",
  },
  deliverySubTitle: {
    color: `${TERTIARY_COLOR} !important`,
    fontWeight: "600 !important",
  },
  stepSubTitle: {
    fontSize: `${SMALL_FONT}px !important`,
  },
  gridItemPadding: {
    paddingRight: "45px !important",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px !important",
    },
  },
  setGridWidthAsIs: {},
  maxGridWidth: {
    maxWidth: "100% !important",
    flexBasis: "100% !important",
  },
  feedback: {
    ...feedback
  },
  gridItem: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  gridItemNoPadding: {
    padding: "0px !important",
  },
  group: {
    flexDirection: "row !important",
    marginLeft: "3px !important",
    marginBottom: "5px !important",
  },
  formControl: {
    margin: "0px 0px 10px 0px !important",
    position: "relative !important",
    padding: "0px !important",
  },
  formControlNoMargin: {
    margin: "0px !important",
    position: "relative !important",
    padding: "0px !important",
  },
  addressName: {
    width: "calc(100% - 110px) !important",
  },
  addressUnit: {
    marginLeft: "10px !important",
    width: "100px !important",
  },
  switch: {},
  button: {
    padding: "5px !important",
    marginLeft: "-5px !important",
    marginRight: "15px !important",
  },
  buttonChecked: {
    padding: "5px !important",    
    color: `${BLUE_COLOR} !important`,
    marginLeft: "-5px !important",
  },
  buttonOutlined: {
    backgroundColor: "white !important",
    border: "1px solid black !important",
    padding: "8px 14px !important",
    color: "black !important",
    textTransform: "capitalize !important",
    fontSize: "12px !important",
  },
  mainContainer: {
    paddingLeft: "5px !important",
  },
  displayAddressContainer: {
    display: "flex !important",
  },
  heading: {
    fontSize: "0.9em !important",
    fontWeight: "600 !important",
    color: `${PRIMARY_COLOR} !important`,
    marginTop: "5px !important",
  },
  checkboxLabel: {
    fontSize: "14px !important",
    lineHeight: "43px !important",
    paddingLeft: "4px !important",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "19px !important",
      marginTop: "12px !important",
      marginBottom: "12px !important",
    },
  },
  radioLabel: {
    fontSize: `${SMALL_FONT}px !important`,
  },
  labelPlacementStart: {
    marginLeft: "0px !important",
  },
  checkbox: {
    padding: "3px !important",
    marginLeft: "5px !important",
    marginRight: "3px !important",
  },
  inputTextField: {
    fontSize: "12px !important",
    "&::placeholder": {
      fontSize: "1em !important",
    },
  },
  addressDetailsContainer: {
    marginLeft: "-5px !important",
    marginBottom: "5px !important",
    marginTop: "5px !important",
  },
  textFieldNoMargin: {
    marginBottom: "0px !important",
  },
  divider: {
    marginTop: "5px !important",
    marginBottom: "5px !important",
  },
  spacing: {
    height: "5px !important",
  },
  addressTitle: {
    color: `${TERTIARY_COLOR} !important`,
    fontWeight: "bold !important",
    fontSize: `${SMALL_FONT}px !important`,
    lineHeight: "19px !important",
    [theme.breakpoints.up('md')]: {
        marginTop: "5px !important",
        lineHeight: "20px !important",
    },
},
myDetails: {
    border: "1px solid !important",
    borderColor: `${LIGHT_GREY_COLOR} !important`,
    borderRadius: "3px !important",
    padding: "12px !important",
},
detailsBox: {
    marginTop: "7px !important",
    marginBottom: "12px !important",
},
radioText: {
    float: "left !important",
    lineHeight: "28px !important",
    paddingRight: "10px !important",
    fontSize: `${SMALL_FONT}px !important`,
    fontWeight: "400 !important",
    [theme.breakpoints.down('xs')]: {
        paddingRight: "6px !important",
    },
},

});