import React, { Component } from 'react';
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import axios from 'axios';
import { API_BASE_URL } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import { FONT_FAMILY, SMALL_FONT } from '../../../wumdrophubsreactshared/_constants/styleConstants';
import { MINIMUM_SEARCH_CHARACTERS } from '../../../wumdrophubsreactshared/_constants/selectConstants';
import withStylesHook from './../../../utils/withStylesHook';
import _ from 'lodash';
import useStyles from './StorePicker.css';

let customSelectStyles = {
    container: (base) => ({
        ...base,
        flex: 1, // Adjust the flex value as needed
      }),
    control: (base, state) => ({
        ...base,
        fontFamily: FONT_FAMILY + "!important",
        borderRadius: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: '1px solid hsl(0,0%,80%) !important',
        boxShadow: '0 !important'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    placeholder: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY + "!important",
        fontSize: `${SMALL_FONT}px !important`,
        color: 'rgba(0, 0, 0, 0.40)',
        marginLeft: 23,
    }),
    input: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY + "!important",
        fontSize: `${SMALL_FONT}px !important`,
        alignItems: 'center',
        display: 'flex',
        ':before': {
            content: '" "',
            marginLeft: -3,
            height: 25,
            width: 25
        },
    }),
    menu: base => ({
        ...base,
        fontFamily: FONT_FAMILY + "!important",
        zIndex: "2"
    }),
    option: (base) => ({
        ...base,
        paddingLeft: 25,
        fontFamily: FONT_FAMILY + "!important",
        textAlign: 'left',
        justContent: 'left'
    }),
    noOptionsMessage: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY + "!important",
        color: 'rgba(0, 0, 0, 0.40)'
    }),
    loadingMessage: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY + "!important",
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft: 23,
        fontFamily: FONT_FAMILY + "!important",
        fontSize: SMALL_FONT
    })
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();


class StorePicker extends Component {

    constructor(props) {
        super(props);
        let multi = false;
        if (!isEmpty(this.props.multiSelect)) {
            multi = this.props.multiSelect;
        }

        let value = "";
        if (!isEmpty(this.props.value)) {
            value = this.props.value;
        }

        let dKey = "key";
        let dValue = "value";
        if (!isEmpty(this.props.displayKey)) {
            dKey = this.props.displayKey;
        }

        if (!isEmpty(this.props.displayValue)) {
            dValue = this.props.displayValue;
        }

        let placeholder = "Search...";
        if (!isEmpty(this.props.placeholder)) {
            placeholder = this.props.placeholder;
        }


        this.state = {
            inputValue: '',
            data: [],
            noOptions: "Enter search term...",
            isMultiSelect: multi,
            value: value,
            displayKey: dKey,
            displayValue: dValue,
            placeholder: placeholder,
            menuIsOpen: !isEmpty(this.props.menuIsOpen) ? this.props.menuIsOpen : false
        }
        this.nextSelector = this.props.nextSelector;
    }

    componentWillUnmount() {
        source.cancel('Select to unmounted');
    }

    handleInputChange = (newValue) => {
        //const inputValue = newValue.replace(/\W/g, '');
        if (newValue.length === 0 || isEmpty(newValue)) {
            this.setState({ noOptions: "Enter search term..." });
        } else if (newValue.length < MINIMUM_SEARCH_CHARACTERS) {
            this.setState({ noOptions: "Minimum of " + MINIMUM_SEARCH_CHARACTERS + " characters for a search..." });
            return "";
        }
        this.setState({ newValue });
        return newValue;
    };

    handleChange = (selected) => {
        this.setState({ menuIsOpen: false }, function(){
            let filter = {};
            filter.field = this.props.fieldName;
            filter.value = !isEmpty(selected) ? selected[this.state.displayKey] : null;

            this.setState({ value: selected, tempValue: null }, function(){
                this.props.onSelect(filter, selected).then(() => {
                    this.closeAndBlur();
                    if(!isEmpty(this.props.nextSelector))
                    {
                        if(!isEmpty(this.props.nextSelector.current))
                        {
                            this.props.nextSelector.current.openAndFocus();
                        }
                    }
                });
            });
        });
    }

    createEntity = (entity) => {
        return axios.post(API_BASE_URL + "/" + this.props.apiEntity, entity)
            .then((res) => {
                return res;
            })
            .catch(err => {
                return err;
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.values !== prevProps.values) {
            this.setState({ data: this.props.values });
            if (isEmpty(this.props.values)) {
                this.setState({ noOptions: "No matching results." });
            }
        }
    
        if (this.props.multiSelect !== prevProps.multiSelect && !isEmpty(this.props.multiSelect)) {
            this.setState({ isMultiSelect: this.props.multiSelect });
        }
    }    

    openAndFocus = () => {
        const { value } = this.state;

        if (isEmpty(value)) {
            this.setState({ menuIsOpen: true }, function () {
                this.select.focus();
            });
        }
    };
  
    closeAndBlur()
    {
      this.setState({ menuIsOpen: false }, function(){
          this.select.blur();
      });
    };

    loadOptions = (inputValue, callback) => {
        if (!isEmpty(inputValue) && inputValue.length < MINIMUM_SEARCH_CHARACTERS) {
            callback("");
        }
        else {
            return this.getSelectData(inputValue, callback);
        }
    };

    getSelectData(inputValue, callback) {
        let filter = "";
        let isActive=true;
        if (!isEmpty(this.props.filter)) {
            filter = "&" + this.props.filter.key + "=" + this.props.filter.value;
        }
        return axios.get(API_BASE_URL + "/" + this.props.apiEntity + "?select=true&search=" + inputValue + filter +"&isActive="+ isActive)
            .then((res) => {
                if (isEmpty(res.data)) {
                    this.setState({ noOptions: "No matching results." });
                }
                callback(res.data);
            })
            .catch(err => {
                this.setState({ noOptions: "Not found! No matching results." });
                callback("");
            });
    }

    onFocus(e)
    {
        const { value } = this.state;

        if(!isEmpty(value))
        {
            this.setState({ value: null, tempValue: value}, function(){
                this.setState({ menuIsOpen: true});
            });
        }
        else
        {
            this.setState({ menuIsOpen: true});
        }
    }

    onBlur(e)
    {
        const { value, tempValue } = this.state;
        const { handleBlur } = this.props;

        if(isEmpty(value) && !isEmpty(tempValue))
        {
            this.setState({ value: tempValue, tempValue: null}, function(){
                this.setState({ menuIsOpen: false });
            });
        }
        else
        {
            this.setState({ menuIsOpen: false });
        }

        handleBlur(e);
    }

    render() {
        const { classes, icon } = this.props;
        const { noOptions, value, displayKey, displayValue, placeholder, menuIsOpen } = this.state;

        return (
            <div style={{position:"relative"}} className={classes.selectContainer}>
            <span style={{position:"absolute",left:"-1px",zIndex:"1"}} >
            {icon}
            </span>
                <AsyncSelect
                    ref={ref => {
                        this.select = ref;
                    }}
                    styles={customSelectStyles}
                    cacheOptions
                    menuIsOpen={menuIsOpen}
                    value={value}
                    loadOptions={_.debounce(this.loadOptions, 500)}
                    onInputChange={_.debounce(this.handleInputChange, 500)}
                    onChange={this.handleChange}
                    onBlur={this.onBlur.bind(this)}
                    onFocus={this.onFocus.bind(this)}
                    defaultOptions
                    placeholder={placeholder}
                    isMulti={this.state.isMultiSelect}
                    noOptionsMessage={() => noOptions}
                    getOptionLabel={(values) => values[displayValue]}
                    getOptionValue={(values) => values[displayKey]}
                    isClearable={true}
                />
                <section className={classes.underline} />
            </div>
        );
    }
}

StorePicker.propTypes = {
    apiEntity: PropTypes.string.isRequired
};

export default withStylesHook(useStyles)(StorePicker);