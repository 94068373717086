import { feedback } from "assets/jss/material-kit-pro-react.jsx";
import {
    BLACK_COLOR,
    SMALL_FONT,
    PRIMARY_COLOR,
    SLATE_COLOR,
    TERTIARY_COLOR,
    COMPLEMENTARY_2_COLOR,
    COMPLEMENTARY_5_COLOR,
    LARGE_FONT, LIGHTGREY_COLOR
} from "wumdrophubsreactshared/_constants/styleConstants";
import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    feedback: {
        ...feedback,
        marginTop: "10px !important"
    }, 
    root: {
        backgroundColor: `${theme.palette.background.paper} !important`
    },
    radioContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important'
        }
    },
    radioButton: {
        padding: "0 !important"
    },
    priceAlignment: {
        [theme.breakpoints.down('sm')]: {
            float: 'right !important' 
        }
    },
    helpButton: {
        padding: "3px !important",
        float: "right !important",
        marginTop: "-5px !important"
    },
    helpIcon: {
        width: "0.95em !important",
        height: "0.95em !important"
    },
    listItem: {
        color: `${SLATE_COLOR} !important`,
        border: `1px solid ${LIGHTGREY_COLOR} !important`,
        borderRadius: "4px !important",
        padding: "15px !important",
        marginBottom: "10px !important",
        "&:hover": {
            background: `${lighten(COMPLEMENTARY_2_COLOR, 0.85)} !important`,
        }
    },
    selectedText: {
        fontWeight: "bold !important",
        color: `${lighten(BLACK_COLOR, 0.15)} !important`
    },
    listItemText: {
        color: "inherit !important"
    },
    detailsHeading: {
        color: `${COMPLEMENTARY_5_COLOR} !important`,
        fontWeight: "bold !important",
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        fontSize: `${SMALL_FONT}px !important`,
        [theme.breakpoints.down('sm')]: {}
    },
    progress: {
        width: "100% !important",
        marginTop: "50px !important",
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    slaTitle: {
        fontWeight: "600 !important",
        color: `${SLATE_COLOR} !important`,
        [theme.breakpoints.down('sm')]: {
            lineHeight: "30px !important"
        }
    },
    titleAlignment: {
        paddingTop: "7px !important",
    },
    slaSubtext: {
        fontWeight: "400 !important",
        fontSize: "15px !important",
        color: `${SLATE_COLOR} !important`
    },
    dot: {
        fontWeight: "600 !important"
    },
    timeWindowText: {
        fontSize: `${SMALL_FONT}px !important`,
        color: `${lighten(BLACK_COLOR, 0.4)} !important`
    },
    timeWindowSection: {
        [theme.breakpoints.up('md')]: {
            marginTop: "8px !important",
            marginBottom: "6px !important"
        }
    },
    checkMark: {
        float: 'right !important',
        display: 'inline-block !important',
        [theme.breakpoints.up('md')]: {
            display: 'none !important'
        },
        color: `${PRIMARY_COLOR} !important`,
        marginTop: "7px !important"
    },
    slaDescription: {
        display: 'inline-block !important'
    },
    icon: {
        color: `${lighten(BLACK_COLOR, 0.4)} !important`,
        fontSize: `${LARGE_FONT}px !important`,
        marginRight: "2px !important",
        marginBottom: "-4px !important"
    },
    timeIcon: {
        color: `${lighten(BLACK_COLOR, 0.1)} !important`,
        fontSize: `${LARGE_FONT}px !important`,
        marginRight: "2px !important",
        marginLeft: "2px !important",
        marginBottom: "-3px !important"
    },
    timeHours: {
        color: `${PRIMARY_COLOR} !important`
    },
    marginZero: {
        marginBottom: "2px !important",
    },
    dateText: {
        fontSize: `${SMALL_FONT}px !important`,
        color: `${lighten(BLACK_COLOR, 0.4)} !important`,
        marginTop: "2px !important"
    },
    deliveryDate: {
        color: `${COMPLEMENTARY_5_COLOR} !important`,
        fontWeight: "bold !important"
    },
    changeOrderText: {
        fontSize: `${SMALL_FONT}px !important`,
        float: 'right !important',
        position: 'absolute !important',
        bottom: "75px !important"
    },
    changeOrderLink: {
        color: `${TERTIARY_COLOR} !important`,
        '&:hover': {
            cursor: 'pointer !important'
        }
    },
    timePickerContainer: {
        marginBottom: "10px !important"
    },
    doubleCharge: {
        color: `${PRIMARY_COLOR} !important`,
        fontSize: "11px !important",
        marginTop: "-9px !important",
        fontWeight: 'bold !important',
        textAlign: 'right !important',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left !important',
        }
    },
    highRisk: {
        color: `${PRIMARY_COLOR} !important`,
        fontSize: "11px !important",
        marginTop: "-9px !important",
        fontWeight: 'bold !important',
        textAlign: 'right !important',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left !important',
        }
    }
}));
